import axios from "axios";
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  MouseEventHandler,
  ChangeEvent,
} from "react";
import { Header } from "../../../components/shared/HeaderFooter/Header";
import { StepNavigation } from "../../../components/shared/StepNavigationExpEdit";
import { Footer } from "../../../components/shared/HeaderFooter/Footer";
import { useParams, useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import {
  GenerateAudio,
  getExperienceData,
  UpdateStepNodes,
} from "../../../services/experienceService";
import {
  AnnotationDetails,
  AudioTextTextData,
  Choice,
  DisplayTemplate,
  RolePlayStepByStepTextData,
  Experience,
  ExperienceResponseData,
  findExperienceByExperienceID,
  findMediaFileByDisplayTemplateID,
  findNextNode,
  findNodeByID,
  getAudioTextTextData,
  getLinkedNodeData,
  getMediaFilePath,
  getRolePlayChoiceTextData,
  getRolePlayStepByStepTextData,
  getStartNodeForExperience,
  RolePlaySectionObject,
  SOTypeID,
  LinkedNodeData,
} from "../../../types/experienceTypes";
import { Stack } from "../../../types/stack";
import "video-react/dist/video-react.css";
import { Player, BigPlayButton } from "video-react";
import { AudioPlayer } from "../../../components/shared/AudioPlayer/AudioPlayer";
import SlidePanel from "../../../components/shared/SidePanel/SidePanelEditexperience";
import winningicon from "../../../logos/12699838_Smart guy getting award 1.svg";
import { Organization } from "../../../types/projectTypes";
import { listProjectsAndOrgs } from "../../../services/projectService";
import debounce from "lodash/debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Canvas from "../../CanvasComponent/CanvasComponent";
import eyes from "../../../logos/ico_visible 1.svg";
import closeicon from "../../../logos/ico_close_.svg";
import save from "../../../logos/ico_save.svg";
import "./StepNavigation.css";
import { uploadImage } from "../../../services/experienceService";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store'; // Adjust the path as necessary

import { Edit3dNodes } from '../Edit3DNodeExperience/Edit3DNodeExperience'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { setCurrentNodeValue, setDropdownValues } from "../../../redux/slices/counterSlice";
import { useDispatch } from "react-redux";
import { values } from "lodash";


interface NodeRenderDetails {
  title: string;
  text: string;
  image?: string;
  audio?: string;
  video?: string;
  PageNo: number;
  options?: AnnotationDetails[];
}

interface LocalUpdates {
  [pageNo: number]: NodeRenderDetails;
}

interface Step {
  title: string;
  text: string;
  image?: string;
  PageNo: number;
  options?: string;
  CorrectAnswer?: string;
}

interface Quiz {
  question: string;
  options: string[];
}

interface ContentState {
  text: string;
  images: { imgSrc: string; altText: string }[];
  quizzes: Quiz[];
}

interface pathFragment {
  node: RolePlaySectionObject;
  ExperienceID: number;
}
interface AudioData {
  text: string;
  TrainingSectionID: string | undefined;
  VoiceTypeID: string;
  audioName: string;
}
const pathStack: Stack<pathFragment> = new Stack<pathFragment>();
const linkStack: Stack<pathFragment> = new Stack<pathFragment>();

export const EditExperienceComponent = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [content, setContent] = useState<ContentState>({
    text: "",
    images: [],
    quizzes: [],
  });
  const [audio, setAudio] = useState<boolean>(false);
  const [player] = useState(() => new AudioPlayer());
  const [audioUrl, setAudioUrl] = useState(
    "https://altouraonyx.azureedge.net/altouradevcontainer/blank.mp3"
  );
  const [Experiences, setExperiences] = useState<Experience[]>([]);
  const [mainExperience, setMainExperience] = useState<Experience | undefined>(
    undefined
  );
  const [currentExperience, setCurrentExperience] = useState<
    Experience | undefined
  >(undefined);
  const [currentNode, setCurrentNode] = useState<
    RolePlaySectionObject | undefined
  >(undefined);
  const [newcurrentNode, setNewCurrentNode] = useState<any | undefined>(
    undefined
  );
  const [audioFilePath, setAudioFilePath] = useState<string | null>(null);
  let projectResponse;

  const dp = localStorage.getItem("displayPicture");
  // console.log("dp")
  // console.log(dp)

  const defalutNodeRenderDetails = {
    title: "",
    text: "",
    image: "",
    audio: "https://altouraonyx.azureedge.net/altouradevcontainer/blank.mp3",
    video: "",
    PageNo: 0,
    options: [],
  };
  const [nodeRenderDetails, setNodeRenderDetails] = useState<NodeRenderDetails>(
    defalutNodeRenderDetails
  );
  const [roleplay, setRoleplay] = useState<
    RolePlaySectionObject[] | undefined
  >();
  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [completed, setCompleted] = useState(false);
  const [isPanelVisible, setIsPanelVisible] = useState(true); // Initially visible

  const { experienceID } = useParams();
  const experienceIDNumber = Number(experienceID) || -1; // Assuming the query param is '?myParam=value'

  const [nodeUpdates, setNodeUpdates] = useState({});
  const [updatecurrentNode, setUpdateCurrentNode] = useState<string>();
  const [selectedRadioOption, setSelectedRadioOption] = useState<number>();

  const [nodes, setNodes] = useState<NodeRenderDetails[]>([]); // State to hold all nodes
  const [roleplaysbs, setRoleplaysbs] = useState<
    RolePlayStepByStepTextData | undefined
  >();
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const [editableTitle, setEditableTitle] = useState("");
  const [editableText, setEditableText] = useState("");
  const [steps, setSteps] = useState<Step[]>([]);
  const [localUpdates, setLocalUpdates] = useState<LocalUpdates>({});
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(nodeRenderDetails.text);
  const [textheight, setTextHeight] = useState("auto");
  const divRef = useRef<HTMLDivElement>(null);
  const [viewMode, setViewMode] = useState("2D");
  const id = localStorage.getItem("newexpid");
  const [trainingSectionID, setTrainingSectionID] = useState<LinkedNodeData>();
  const [selectedRadioAns, setSelectedRadioAns] = useState<number | null>(null);
  const [isAudioInputVisible, setIsAudioInputVisible] = useState(false);
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [textToConvert, setTextToConvert] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [audioSrc, setAudioSrc] = useState<string>("");
  const audioPlayerRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [projres, setProjres] = useState<any>([]);
  const [totalNodes, settotalNodes] = useState<any>([])

  const projectSourceIDVlue = useSelector((state: RootState) => state.counter.dropdown);
  const nodeValue = useSelector((state: RootState) => state.counter.selectedNodeConnection);

  const dispatch = useDispatch()

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleUploadClick = () => {
    document.getElementById("fileUpload")!.click();
    setDropdownVisible(false);
  };
  const currentNodeValue = useSelector((state: RootState) => state.counter.selectedNodeId)
  const handleDeleteClick = () => {
    // Handle the delete action here
    nodeRenderDetails.image = "";
    setUploadedImage(null);
    setDropdownVisible(false);
  };


  const handleAudioToggle = () => {
    setIsAudioInputVisible(!isAudioInputVisible);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setAudioFile(event.target.files[0]);
    }
  };

  const handleGenerateAudio = async () => {
    const audioData: AudioData = {
      text: textToConvert,
      TrainingSectionID: trainingSectionID?.TrainingSectionID.toString(),
      VoiceTypeID: "8",
      audioName: "Node 397",
    };
    const response = await GenerateAudio(audioData);
    setAudioFilePath(response);

    setAudioSrc("https://portaldev.altoura.com/" + response.data);
    // Implement the generate audio functionality here
  };

  const handlePlayAudio = () => {
    if (audioFile) {
      const audio = new Audio(URL.createObjectURL(audioFile));
      audio.play();
    } else if (audioFilePath) {
      const audio = new Audio("https://portaldev.altoura.com/" + audioFilePath);
      setAudioSrc(audioFilePath);

      audio.play();
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
  };
  const handleTextToAudio = () => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(textToConvert);
    synth.speak(utterance);
  };

  const handleBlur = () => {
    setIsEditing(false);
    // Optionally, call a function to save the text
  };

  useEffect(() => {
    if (isEditing && divRef.current) {
      setTextHeight(`${divRef.current.clientHeight - 10}px`);
    }
  }, [isEditing]);

  const applyLocalUpdates = (nodeDetails: any) => {
    // Check if there are any local updates for the current node based on PageNo
    if (localUpdates[nodeDetails.PageNo]) {
      return { ...nodeDetails, ...localUpdates[nodeDetails.PageNo] };
    }
    return nodeDetails;
  };

  useEffect(() => {
    if (currentNode) {
      let updatedNodeDetails = {
        title: nodeRenderDetails.title,
        text: nodeRenderDetails.text,
        image: nodeRenderDetails.image,
        audio: nodeRenderDetails.audio,
        video: nodeRenderDetails.video,
        PageNo: nodeRenderDetails.PageNo,
        options: nodeRenderDetails.options,
      };

      // Apply any local updates to the node details
      updatedNodeDetails = applyLocalUpdates(updatedNodeDetails);
      setNodeRenderDetails(updatedNodeDetails);
      setEditableTitle(updatedNodeDetails.title); // Ensure editable title is set
      setEditableText(updatedNodeDetails.text); // Ensure editable text is set
    }
  }, [currentNode, localUpdates]); // Include localUpdates in the dependency array

  const handleNodeChange = (nodeData: any) => {
    const updatedNodeDetails = applyLocalUpdates({
      title: nodeData.title,
      text: nodeData.text,
      image: nodeData.image,
      audio: nodeData.audio,
      video: nodeData.video,
      PageNo: nodeData.PageNo,
      options: nodeData.options,
    });

    setNodeRenderDetails(updatedNodeDetails);
  };

  const toggleTitleEdit = () => {
    setIsTitleEditable(!isTitleEditable);
  };

  useEffect(() => {
    setEditableTitle(nodeRenderDetails.title);
  }, [nodeRenderDetails.title]);

  const toggleTextEdit = () => {
    setIsTextEditable(!isTextEditable);
    if (!isTextEditable) {
      // If we're about to turn on editing
      setEditableText(nodeRenderDetails.text);
    }
    if (!isEditing && divRef.current) {
      // Set the height of the textarea to match the div before editing
      setTextHeight(`${divRef.current.clientHeight}px`);
    }
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted

    const fetchExperiences = async () => {
      try {
        const response = await getExperienceData(experienceIDNumber);

        if (isMounted && response.code === 200) {
          const responseData: ExperienceResponseData = response.data;
          const mainTsid = responseData.responseData.MAIN_TSID;
          const mainExperienceData = findExperienceByExperienceID(
            responseData.responseData.Experiences,
            Number(mainTsid)
          );

          setExperiences(responseData.responseData.Experiences);
          setMainExperience(mainExperienceData);
          setCurrentExperience(mainExperienceData);
          setRoleplay(mainExperienceData?.rolePlaySectionObjects);

          if (mainExperienceData) {
            const currentNodeData = getStartNodeForExperience(mainExperienceData);
            setCurrentNode(currentNodeData);
          }

          let experienceData = mainExperienceData?.rolePlaySectionObjects;
          let arrayOfNode: string[] = [];
          experienceData?.map((item) => {
            arrayOfNode.push(item.SectionObjectID.toString())
            // console.log(item.SectionObjectID)
          })
          console.log(arrayOfNode)
          settotalNodes(arrayOfNode)
        } else {
          console.error("Failed to fetch experiences:", response.message);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching experiences:", error);
        }
      }
    };

    fetchExperiences();

    return () => {
      isMounted = false; // Cleanup function to prevent state updates after unmount
    };
  }, [experienceIDNumber]);

  const nodeDataSetArrayRef = useRef<NodeRenderDetails[]>([]);

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted

    if (currentNode && isMounted) {
      let nodeDataToSet: NodeRenderDetails = {
        title: nodeRenderDetails.title,
        text: nodeRenderDetails.text,
        image: nodeRenderDetails.image,
        audio: nodeRenderDetails.audio,
        video: nodeRenderDetails.video,
        PageNo: nodeRenderDetails.PageNo,
        options: nodeRenderDetails.options,
      };

      nodeDataToSet = applyLocalUpdates(nodeDataToSet);

      const isEmptyNode = (node: NodeRenderDetails) => {
        return (
          !node.title &&
          !node.text &&
          !node.image &&
          !node.video &&
          (!node.audio || node.audio.includes("blank.mp3")) &&
          (!node.options || node.options.length === 0)
        );
      };

      const isDuplicate = (newNode: NodeRenderDetails) => {
        return nodeDataSetArrayRef.current.some(
          (node) => node.PageNo === newNode.PageNo
        );
      };

      if (!isEmptyNode(nodeDataToSet) && !isDuplicate(nodeDataToSet)) {
        nodeDataSetArrayRef.current.push(nodeDataToSet);
      } else if (!isEmptyNode(nodeDataToSet)) {
        nodeDataSetArrayRef.current = nodeDataSetArrayRef.current.map((node) =>
          node.PageNo === nodeDataToSet.PageNo ? nodeDataToSet : node
        );
      }

      if (
        currentNode.SOTypeID === SOTypeID.StartNode &&
        currentExperience?.rolePlaySectionObjects
      ) {
        const nextNode: RolePlaySectionObject | null = findNextNode(
          currentExperience?.rolePlaySectionObjects,
          currentNode
        );
        if (nextNode) {
          setCurrentNode(nextNode);
        }
      } else if (currentNode.SOTypeID === SOTypeID.StepNode) {
        let nodeDataToSet: NodeRenderDetails = defalutNodeRenderDetails;

        let rolePlayStepByStepMediaFile = findMediaFileByDisplayTemplateID(
          currentNode.mediaFiles,
          DisplayTemplate.RolePlayStepByStep
        );
        if (rolePlayStepByStepMediaFile) {
          let rolePlayStepByStepTextData = getRolePlayStepByStepTextData(
            rolePlayStepByStepMediaFile
          );
          setRoleplaysbs(rolePlayStepByStepTextData);
          nodeDataToSet.title = rolePlayStepByStepTextData.Title;
        }

        let audioTextMediaFile = findMediaFileByDisplayTemplateID(
          currentNode.mediaFiles,
          DisplayTemplate.AudioText
        );
        if (audioTextMediaFile) {
          let audioTextTextData = getAudioTextTextData(audioTextMediaFile);
          nodeDataToSet.text = audioTextTextData.DisplayText;
          nodeDataToSet.audio =
            "https://altouraonyx.azureedge.net" +
            audioTextMediaFile.MediaFilePath;
        }

        let rolePlayVideoMediaFile = findMediaFileByDisplayTemplateID(
          currentNode.mediaFiles,
          DisplayTemplate.RolePlayVideo
        );
        if (rolePlayVideoMediaFile) {
          let MediaFilePath = getMediaFilePath(rolePlayVideoMediaFile);
          nodeDataToSet.video = MediaFilePath;
        }

        let rolePlayDisplayImageMediaFile = findMediaFileByDisplayTemplateID(
          currentNode.mediaFiles,
          DisplayTemplate.RolePlayDisplayImage
        );
        if (nodeDataToSet.video === "" && rolePlayDisplayImageMediaFile) {
          let MediaFilePath = getMediaFilePath(rolePlayDisplayImageMediaFile);
          nodeDataToSet.image = MediaFilePath;
        }

        if (!isEmptyNode(nodeDataToSet) && !isDuplicate(nodeDataToSet)) {
          nodeDataSetArrayRef.current.push(nodeDataToSet);
        } else if (!isEmptyNode(nodeDataToSet)) {
          nodeDataSetArrayRef.current = nodeDataSetArrayRef.current.map(
            (node) => (node.PageNo === nodeDataToSet.PageNo ? nodeDataToSet : node)
          );
        }

        if (isMounted) {
          setNodeRenderDetails(nodeDataToSet);
          localStorage.setItem(
            "noderenderdetails",
            JSON.stringify(nodeDataToSet)
          );
        }
      } else if (currentNode.SOTypeID === SOTypeID.LinkNode) {
        const nextExperienceMediafileData = findMediaFileByDisplayTemplateID(
          currentNode.mediaFiles,
          DisplayTemplate.LinkedNode
        );

        if (nextExperienceMediafileData && currentExperience) {
          linkStack.push({
            node: currentNode,
            ExperienceID: currentExperience?.ExperienceID,
          });

          const nextExperienceData = getLinkedNodeData(
            nextExperienceMediafileData
          );
          const ExperienceData = findExperienceByExperienceID(
            Experiences,
            Number(nextExperienceData.TrainingSectionID)
          );
          setTrainingSectionID(nextExperienceData);

          setCurrentExperience(ExperienceData);
          if (ExperienceData) {
            const currentNodeData = getStartNodeForExperience(ExperienceData);
            setCurrentNode(currentNodeData);
          }
        }
      } else if (currentNode.SOTypeID === SOTypeID.ChoiceNode) {
        let nodeDataToSet: NodeRenderDetails = defalutNodeRenderDetails;

        let rolePlayChoiceTextDataMediaFile = findMediaFileByDisplayTemplateID(
          currentNode.mediaFiles,
          DisplayTemplate.RolePlayChoice
        );
        if (rolePlayChoiceTextDataMediaFile) {
          let rolePlayChoiceTextData = getRolePlayChoiceTextData(
            rolePlayChoiceTextDataMediaFile
          );
          nodeDataToSet.title = rolePlayChoiceTextData.HeaderText;
          nodeDataToSet.text = rolePlayChoiceTextData.QuestionText;
          nodeDataToSet.options = rolePlayChoiceTextDataMediaFile.Annotations;
        }

        if (!isEmptyNode(nodeDataToSet) && !isDuplicate(nodeDataToSet)) {
          nodeDataSetArrayRef.current.push(nodeDataToSet);
        } else if (!isEmptyNode(nodeDataToSet)) {
          nodeDataSetArrayRef.current = nodeDataSetArrayRef.current.map(
            (node) => (node.PageNo === nodeDataToSet.PageNo ? nodeDataToSet : node)
          );
        }

        if (isMounted) {
          setNodeRenderDetails(nodeDataToSet);
          setEditableTitle(nodeDataToSet.title);
          setEditableText(nodeDataToSet.text);
        }
      }
    }

    return () => {
      isMounted = false; // Cleanup function to prevent state updates after unmount
    };
  }, [currentNode, currentExperience, Experiences]);



  useEffect(() => {
    const processAllNodes = () => {
      let allNodesData: NodeRenderDetails[] = [];

      Experiences.forEach((experience) => {
        experience.rolePlaySectionObjects.forEach((node) => {
          let nodeDataToSet: NodeRenderDetails = {
            title: "",
            text: "",
            image: "",
            audio:
              "https://altouraonyx.azureedge.net/altouradevcontainer/blank.mp3",
            video: "",
            PageNo: node.SectionObjectID,
            options: [],
          };

          nodeDataToSet = applyLocalUpdates(nodeDataToSet);

          const isEmptyNode = (node: NodeRenderDetails) => {
            return (
              !node.title &&
              !node.text &&
              !node.image &&
              !node.video &&
              (!node.audio || node.audio.includes("blank.mp3")) &&
              (!node.options || node.options.length === 0)
            );
          };

          const isDuplicate = (newNode: NodeRenderDetails) => {
            return allNodesData.some((node) => node.PageNo === newNode.PageNo);
          };

          if (node.SOTypeID === SOTypeID.StepNode) {
            let rolePlayStepByStepMediaFile = findMediaFileByDisplayTemplateID(
              node.mediaFiles,
              DisplayTemplate.RolePlayStepByStep
            );
            if (rolePlayStepByStepMediaFile) {
              let rolePlayStepByStepTextData = getRolePlayStepByStepTextData(
                rolePlayStepByStepMediaFile
              );
              nodeDataToSet.title = rolePlayStepByStepTextData.Title;
            }

            let audioTextMediaFile = findMediaFileByDisplayTemplateID(
              node.mediaFiles,
              DisplayTemplate.AudioText
            );
            if (audioTextMediaFile) {
              let audioTextTextData = getAudioTextTextData(audioTextMediaFile);
              nodeDataToSet.text = audioTextTextData.DisplayText;
              nodeDataToSet.audio =
                "https://altouraonyx.azureedge.net" +
                audioTextMediaFile.MediaFilePath;
            }

            let rolePlayVideoMediaFile = findMediaFileByDisplayTemplateID(
              node.mediaFiles,
              DisplayTemplate.RolePlayVideo
            );
            if (rolePlayVideoMediaFile) {
              let MediaFilePath = getMediaFilePath(rolePlayVideoMediaFile);
              nodeDataToSet.video = MediaFilePath;
            }

            let rolePlayDisplayImageMediaFile =
              findMediaFileByDisplayTemplateID(
                node.mediaFiles,
                DisplayTemplate.RolePlayDisplayImage
              );
            if (nodeDataToSet.video === "" && rolePlayDisplayImageMediaFile) {
              let MediaFilePath = getMediaFilePath(
                rolePlayDisplayImageMediaFile
              );
              nodeDataToSet.image = MediaFilePath;
            }

            if (!isEmptyNode(nodeDataToSet) && !isDuplicate(nodeDataToSet)) {
              allNodesData.push(nodeDataToSet);
            } else if (!isEmptyNode(nodeDataToSet)) {
              allNodesData = allNodesData.map((node) =>
                node.PageNo === nodeDataToSet.PageNo ? nodeDataToSet : node
              );
            }
          } else if (node.SOTypeID === SOTypeID.LinkNode) {
            const nextExperienceMediafileData =
              findMediaFileByDisplayTemplateID(
                node.mediaFiles,
                DisplayTemplate.LinkedNode
              );

            if (nextExperienceMediafileData) {
              const nextExperienceData = getLinkedNodeData(
                nextExperienceMediafileData
              );
              const ExperienceData = findExperienceByExperienceID(
                Experiences,
                Number(nextExperienceData.TrainingSectionID)
              );

              if (ExperienceData) {
                const currentNodeData =
                  getStartNodeForExperience(ExperienceData);
                nodeDataToSet = {
                  ...nodeDataToSet,
                  title: nodeRenderDetails.title || "", // Replace with the correct property if different
                  text: nodeRenderDetails.text || "", // Replace with the correct property if different
                  PageNo:
                    currentNodeData?.SectionObjectID || node.SectionObjectID,
                };
                allNodesData.push(nodeDataToSet);
              }
            }
          } else if (node.SOTypeID === SOTypeID.ChoiceNode) {
            let rolePlayChoiceTextDataMediaFile =
              findMediaFileByDisplayTemplateID(
                node.mediaFiles,
                DisplayTemplate.RolePlayChoice
              );
            if (rolePlayChoiceTextDataMediaFile) {
              let rolePlayChoiceTextData = getRolePlayChoiceTextData(
                rolePlayChoiceTextDataMediaFile
              );
              nodeDataToSet.title = editableTitle;
              nodeDataToSet.text = editableText;
              nodeDataToSet.options =
                rolePlayChoiceTextDataMediaFile.Annotations;
            }
            //editableText
            if (!isEmptyNode(nodeDataToSet) && !isDuplicate(nodeDataToSet)) {
              allNodesData.push(nodeDataToSet);
            } else if (!isEmptyNode(nodeDataToSet)) {
              allNodesData = allNodesData.map((node) =>
                node.PageNo === nodeDataToSet.PageNo ? nodeDataToSet : node
              );
            }
          }
        });
      });

      nodeDataSetArrayRef.current = allNodesData;
      setNodes(allNodesData); // Update the nodes state with all nodes data
    };

    processAllNodes();
  }, [Experiences]);

  useEffect(() => {
    if (nodeRenderDetails.audio) {
      setAudioUrl(nodeRenderDetails.audio);
    } else {
      setAudioUrl(
        "https://altouraonyx.azureedge.net/altouradevcontainer/blank.mp3"
      );
    }
  }, [nodeRenderDetails]);

  const handlePlay = async (enable: boolean = false) => {
    return true;
  };

  const saveChange = () => {
    throw new Error('Data to save')
  }

  const onNextClick = async () => {
    totalNodes.map((values: any, index: number) => {
      console.log('Next Node Click')
      console.log(values, index)
      if (currentNodeValue == values) {
        console.log(index, totalNodes.length)
        if ((totalNodes.length - 1) != index) {
          dispatch(setCurrentNodeValue(totalNodes[index + 1]))
        }
      }
    })
    console.log(totalNodes)
    console.log(roleplay)
    console.log(currentNode)
    console.log(currentNode?.mediaFiles[1]?.TextContent)
    if (currentNode?.mediaFiles?.[0] && currentNode?.mediaFiles?.[1]) {
      let updateNode = currentNode.mediaFiles[0].TextContent;
      currentNode.mediaFiles = currentNode.mediaFiles.slice(0, 2)
      currentNode.mediaFiles[0].TextContent = `{"DisplayText":"${editableText}"}`;
      currentNode.mediaFiles[1].TextContent = `{"Title":"${editableTitle}"}`;
    }
    let stepNodeJson;
    if (currentNode) {
      stepNodeJson = {
        "position": currentNode.Location,
        "SectionObjectID": currentNode.SectionObjectID,
        "SectionObjectName": currentNode.SectionObjectName,
        "NodeWorldLocation": "{\"OrientationBehavior\":\"\",\"GameObject\":\"\"}",
        "MediaFiles": [
          {
            "MediaFileName": "Text Audio",
            "MediaFileDesc": "Voice Text",
            "MediaFilePath": "",
            "isUpdated": false,
            "MediaFileID": 602860,
            "TextDataID": 464792,
            "DisplayTemplateID": 56,
            "isAudioUpdated": false,
            "TextContent": `{\"DisplayText\":\"${editableText}\"}`,
            "isTextUpdated": true
          }
        ]
      }
    }



    console.log("totalNodes[0]", totalNodes, typeof totalNodes[0], currentNode, typeof currentNode)

    let response = await UpdateStepNodes(projectSourceIDVlue.projectID, totalNodes[0], currentNode, 'aibot')
    console.log(response)
    nodeValue?.map((data) => {
      console.log(data.from)
      if (data.from === currentNodeValue) {

        let Node = data?.to;
        if (Node != undefined || Node != null) {
          dispatch(setCurrentNodeValue(Node))
        }
      }
    })
    if (currentExperience && currentNode) {
      // Save the current edits before moving to the next node
      const updatedDetails = {
        ...nodeRenderDetails,
        title: editableTitle,
        text: editableText,
      };
      setLocalUpdates((prev) => ({
        ...prev,
        [nodeRenderDetails.PageNo]: updatedDetails, // Use the page number from nodeRenderDetails
      }));

      // Update the current nodeRenderDetails state as well
      setNodeRenderDetails(updatedDetails);

      let nextNode: RolePlaySectionObject | null = null;

      if (currentNode.SOTypeID === SOTypeID.StepNode) {
        nextNode = findNextNode(
          currentExperience.rolePlaySectionObjects,
          currentNode
        );
        if (nextNode == null && !linkStack.isEmpty()) {
          const linkNode: pathFragment | undefined = linkStack.pop();
          if (linkNode) {
            let ExperienceOfLinkNode = findExperienceByExperienceID(
              Experiences,
              linkNode.ExperienceID
            );
            setCurrentExperience(ExperienceOfLinkNode);
            if (ExperienceOfLinkNode)
              nextNode = findNextNode(
                ExperienceOfLinkNode.rolePlaySectionObjects,
                linkNode.node
              );
          }
        }
      }
      console.log(nextNode)
      if (nextNode) {
        pathStack.push({
          node: currentNode,
          ExperienceID: currentExperience.ExperienceID,
        });
        setCurrentNode(nextNode);
      }

      if (currentNode.DisplayOrder === 0 && linkStack.isEmpty()) {
        setCompleted(true);
      }
    }
  };


  const debouncedOnNextClick = useCallback(
    debounce(
      () => onNextClick(),
      300 // Delay in ms
    ),
    [onNextClick]
  ); // Dependency array

  const onBackClick = () => {
    if (currentExperience && currentNode && pathStack.size() != 0) {
      const nextNodeData: pathFragment | undefined = pathStack.pop();
      if (nextNodeData != undefined) {
        const nextNode = nextNodeData.node;
        setCurrentExperience(
          findExperienceByExperienceID(Experiences, nextNodeData.ExperienceID)
        );

        if (nextNode) {
          setCurrentNode(nextNode);
        }
      }
    }
  };

  const debouncedOnBackClick = useCallback(
    debounce(
      () => onBackClick(),
      300 // Delay in ms
    ),
    [onBackClick]
  ); // Dependency array

  const onAnswerSelect = (SectionObjectLinkID: number) => {
    if (currentExperience && currentNode) {
      const nextNode = findNodeByID(
        currentExperience.rolePlaySectionObjects,
        SectionObjectLinkID
      );
      if (nextNode) {
        pathStack.push({
          node: currentNode,
          ExperienceID: currentExperience.ExperienceID,
        });
        setCurrentNode(nextNode);
        setSelectedRadioOption(SectionObjectLinkID);
        setSelectedRadioAns(SectionObjectLinkID);
      }
    }
  };
  const handleComponentClick = useCallback(
    (id: string) => {

      if (mainExperience) {
        const selectedNode = mainExperience.rolePlaySectionObjects.find(
          (node) => node.SectionObjectID === parseInt(id, 10)
        );
        if (selectedNode) {
          setCurrentStep(parseInt(id, 10));
          setCurrentNode(selectedNode);
          if (currentExperience) {
            pathStack.push({
              node: selectedNode,
              ExperienceID: currentExperience.ExperienceID,
            });
          }
        }
      }
    },
    [mainExperience, currentExperience]
  );

  const updateCurrentSlide = (newStepIndex: any) => {
    setCurrentStep(newStepIndex);
  };

  const onPrevious = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const debouncedOnPrevious = useCallback(
    debounce(
      () => onPrevious(),
      300 // Delay in ms
    ),
    [onPrevious]
  ); // Dependency array

  const onAnswerSubmit = (stepIndex: number) => {
    // Save the answer
  };

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight + 100,
      });
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions(); // Initialize dimensions

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const onRetry = () => {
    setCurrentStep(0); // Reset to the first step
    setUserAnswers({}); // Clear previous answers
    setCompleted(false); // Mark the experience as not completed
  };

  const [selectedOption, setSelectedOption] = useState<{
    [key: number]: string;
  }>({});
  const [list, setList] = useState<Organization[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await listProjectsAndOrgs(
          parseInt(localStorage.getItem("userID") || "-1")
        );
        setProjres(projectResponse);
        if (
          projectResponse.orgsList.length === 0 ||
          projectResponse.data.length === 0
        ) {
          throw new Error("Empty data array received from API");
        }
        setList(projectResponse.orgsList);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const nextNode = nodeValue
    totalNodes.map((values: any) => {
      console.log(values, "Data")
    })

  }, [nodeValue])

  const [isTextEditable, setIsTextEditable] = useState(false);

  // Sync state whenever the `nodeRenderDetails.text` changes.
  useEffect(() => {
    setEditableText(nodeRenderDetails.text);
  }, [nodeRenderDetails.text]);

  const createNewNode = (type: string) => {
    const previousNodes = [...nodes]; // Copy the existing nodes

    // Create a new node with default values
    const newNode: NodeRenderDetails = {
      title: "Add a new Title",
      text: "Add a new Text",
      image: "",
      audio: "",
      video: "",
      PageNo: previousNodes.length + 1,
      options: [],
    };

    // Add the new node to the list of nodes
    previousNodes.push(newNode);

    // Update the state with the new list of nodes
    setNodes(previousNodes);

    // Set the new node as the current node
    setNewCurrentNode(newNode);
    setNodeRenderDetails(newNode);
  };

  useEffect(() => {
    if (currentNode) {
      setNodes((prevNodes) => [...prevNodes, nodeRenderDetails]);
    }
  }, [nodeRenderDetails, currentNode]);

  const handleMediaUpload = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = async () => {
      const result = reader.result;
      if (typeof result === "string") {
        try {
          // Generate SAS URL
          const response = await axios.get(
            `https://portaldev.altoura.com/upload/generateStorageSignaureForSlides?fileName=${file.name}&trainingSectionID=${trainingSectionID}`,
            {
              params: {
                fileName: file.name,
                token: localStorage.getItem("token"),
              },
            }
          );

          const sasUrl = response.data.sasUrl;

          // Upload the file using the SAS URL
          await axios.put(sasUrl, file, {
            headers: {
              "x-ms-blob-type": "BlockBlob",
              "Content-Type": file.type,
            },
          });

          // Determine if the file is an image or video and update state accordingly
          const isImage = file.type.startsWith("image/");
          const isVideo = file.type.startsWith("video/");

          if (isImage) {
            setNodeRenderDetails((prevDetails) => ({
              ...prevDetails,
              image: sasUrl.split("?")[0], // Set the image URL without the SAS token
              video: "", // Clear video URL if any
            }));
          } else if (isVideo) {
            setNodeRenderDetails((prevDetails) => ({
              ...prevDetails,
              video: sasUrl.split("?")[0], // Set the video URL without the SAS token
              image: "", // Clear image URL if any
            }));
          }
        } catch (error) {
          console.error("Error uploading file", error);
        }
      }
    };
    reader.readAsDataURL(file);
  };

  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [uploadedVideo, setUploadedVideo] = useState<string | null>(null);

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];

      try {
        const response = await uploadImage(selectedFile);

        // Check if the response contains the relativePath
        if (response?.data?.relativePath) {
          const relativePath = response.data.relativePath;
          setUploadedImage(relativePath); // Set the relative path for the image
          console.log("upimg");
          console.log(uploadedImage)
          setUploadedVideo(null); // Clear video if an image is uploaded
          console.log("Image uploaded successfully. Relative Path:", relativePath);
        } else {
          console.error("Relative path not found in the response:", response);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } else {
      console.error("No file selected.");
    }
  };




  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && typeof e.target.result === "string") {
          setUploadedVideo(e.target.result);
          setUploadedImage(null); // Clear image if a video is uploaded
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  const [textContents, setTextContents] = useState<string[]>([]);
  const [displayTexts, setDisplayTexts] = useState<string[]>([]);

  useEffect(() => {
    const newTextContents: string[] = [];
    Experiences.forEach((option) => {
      option.rolePlaySectionObjects.forEach((sectionObject) => {
        sectionObject.mediaFiles.forEach((mediaFile) => {
          if (mediaFile.TextContent !== null) {
            newTextContents.push(mediaFile.TextContent);
          }
        });
      });
    });
    setTextContents(newTextContents);
  }, [Experiences]);

  useEffect(() => {
    const newDisplayTexts = textContents.map((textContent) => {
      try {
        const parsed = JSON.parse(textContent);
        return parsed.DisplayText || "";
      } catch (error) {
        console.error("Failed to parse JSON", error);
        return "";
      }
    });
    setDisplayTexts(newDisplayTexts);
  }, [textContents]);

  const handleDisplayTextChange = (index: any, newText: any) => {
    // Update displayTexts state
    const newDisplayTexts = [...displayTexts];
    newDisplayTexts[index] = newText;
    setDisplayTexts(newDisplayTexts);

    // Update the original Experiences
    const newExperiences = Experiences.map((option) => {
      return {
        ...option,
        rolePlaySectionObjects: option.rolePlaySectionObjects.map(
          (sectionObject) => {
            return {
              ...sectionObject,
              mediaFiles: sectionObject.mediaFiles.map((mediaFile) => {
                if (mediaFile.TextContent !== null) {
                  const parsed = JSON.parse(mediaFile.TextContent);
                  if (parsed.DisplayText === displayTexts[index]) {
                    parsed.DisplayText = newText;
                    return {
                      ...mediaFile,
                      TextContent: JSON.stringify(parsed),
                    };
                  }
                }
                return mediaFile;
              }),
            };
          }
        ),
      };
    });

    setExperiences(newExperiences);
  };
  const isFirstStep = currentNode ? currentNode.DisplayOrder === 0 : false;
  const isLastStep = currentNode ? currentNode.DisplayOrder === -1 : false;

  const newStepNodeArray = Array.isArray(roleplay)
    ? roleplay.map((step, index) => ({
      NodeType: "StepNode",
      SectionObjectName: step.SectionObjectName,
      DisplayTextMediaFileID: "",
      DisplayTextDataID: "",
      DisplayText: nodeRenderDetails.text,
      Title: nodeRenderDetails.title,
      ImageUrl: nodeRenderDetails.image,
      ImageMediaFileID: "",
      VideoUrl: nodeRenderDetails.video,
      VideoMediaFileID: "",
      NodeWorldLocation: {
        OrientationBehavior: "",
        GameObject: "",
      },
      poisitonX: 500,
      poisitonY: 250,
      TargetObjectMediaFileID: "",
      TargetObjectTextDataID: 178579,
      TargetObject: roleplaysbs?.TargetObject,
      TransformMatrix: {
        position: {
          X: 1,
          Y: 2,
          Z: 3,
        },
        rotation: {
          X: 1,
          Y: 2,
          Z: 3,
        },
        scale: {
          X: 1,
          Y: 1,
          Z: 1,
        },
      },
      EventType: roleplaysbs?.EventType,
      ColliderName: roleplaysbs?.ColliderName,
      ColliderTypeID: roleplaysbs?.ColliderTypeID,
      SnapToPosition: roleplaysbs?.SnapToPosition,
      PanelMediaFileID: "",
      PanelTextDataID: "",
      PanelObject: "PanelObject",
      PanelObjectTransformmatrix: {
        position: {
          X: 1,
          Y: 2,
          Z: 3,
        },
        rotation: {
          X: 1,
          Y: 2,
          Z: 3,
        },
        scale: {
          X: 1,
          Y: 1,
          Z: 1,
        },
      },
      TrainingObject: "TrainingObject",
      Constraints: ["FreezePositionX", "FreezePositionY", "FreezePositionZ"],
      AnimationMediaFileID: "",
      AnimationTextDataID: "",
      InstructionAnimationObject: "InstructionAnimationObject",
      IAOHandDisplay: 1,
      JSONMediaFileID: "",
      JSONTextDataID: "",
      JSONData: '{"test":"test"}',
      Objects: [
        {
          MediaFileID: "",
          TextDataID: "",
          ObjectID: "",
          TextContent: {
            transformmatrix: {
              position: {
                X: 1,
                Y: 2,
                Z: 3,
              },
              rotation: {
                X: 4,
                Y: 5,
                Z: 6,
              },
              scale: {
                X: 7,
                Y: 8,
                Z: 9,
              },
            },
            isbillboard: false,
            RotationW: "11",
            Ref3DObject: "asdasddddddd",
          },
        },
      ],
      UIControls: [
        {
          MediaFileID: -1,
          TextDataID: -1,
          TextContent: {
            UIControlType: 1,
            UIControlName: "Text1",
            ViewMode: 1,
            IsStatic: false,
            UIControlIconColor: "#00aabb",
            UIControlTextPanelColor: "#00aabb",
            Constraints: [],
            WorldOrientation: 0,
            InitialSpawnGameObject: "",
            InitialSpawnPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            UIControlText: "",
            UIControlIcon: "",
            UIControlVisitedIcon: "",
            ExpandedPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            OnExpandAnimation: "",
            OnExpandActivateObjects: [],
            OnExpandDeactivateObjects: [],
            OnCloseAnimation: "",
            OnCloseActivateObjects: [],
            OnCloseDeactivateObjects: [],
            MustInteract: false,
            IsNetworked: false,
            TitleText: "",
            BodyText: "",
          },
        },
        {
          MediaFileID: -1,
          TextDataID: -1,
          TextContent: {
            UIControlType: 2,
            UIControlName: "Image1",
            ViewMode: 1,
            IsStatic: false,
            UIControlIconColor: "#00aabb",
            UIControlTextPanelColor: "#00aabb",
            Constraints: [],
            WorldOrientation: 0,
            InitialSpawnGameObject: "",
            InitialSpawnPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            UIControlText: "",
            UIControlIcon: "",
            UIControlVisitedIcon: "",
            ExpandedPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            OnExpandAnimation: "",
            OnExpandActivateObjects: [],
            OnExpandDeactivateObjects: [],
            OnCloseAnimation: "",
            OnCloseActivateObjects: [],
            OnCloseDeactivateObjects: [],
            MustInteract: false,
            IsNetworked: false,
            TitleText: "",
            BodyText: "",
            ImageUrl: "",
          },
        },
        {
          MediaFileID: -1,
          TextDataID: -1,
          TextContent: {
            UIControlType: 3,
            UIControlName: "Video1",
            ViewMode: 1,
            IsStatic: false,
            UIControlIconColor: "#00aabb",
            UIControlTextPanelColor: "#00aabb",
            Constraints: [],
            WorldOrientation: 0,
            InitialSpawnGameObject: "",
            InitialSpawnPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            UIControlText: "",
            UIControlIcon: "",
            UIControlVisitedIcon: "",
            ExpandedPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            OnExpandAnimation: "",
            OnExpandActivateObjects: [],
            OnExpandDeactivateObjects: [],
            OnCloseAnimation: "",
            OnCloseActivateObjects: [],
            OnCloseDeactivateObjects: [],
            MustInteract: false,
            IsNetworked: false,
            TitleText: "",
            VideoUrl: "",
          },
        },
        {
          MediaFileID: -1,
          TextDataID: -1,
          TextContent: {
            UIControlType: 4,
            UIControlName: "Teleport1",
            ViewMode: 1,
            IsStatic: false,
            UIControlIconColor: "#00aabb",
            UIControlTextPanelColor: "#00aabb",
            Constraints: [],
            WorldOrientation: 0,
            InitialSpawnGameObject: "",
            InitialSpawnPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            UIControlText: "",
            UIControlIcon: "",
            UIControlVisitedIcon: "",
            ExpandedPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            OnExpandAnimation: "",
            OnExpandActivateObjects: [],
            OnExpandDeactivateObjects: [],
            OnCloseAnimation: "",
            OnCloseActivateObjects: [],
            OnCloseDeactivateObjects: [],
            MustInteract: "false",
            IsNetworked: "false",
            TeleportGameObject: "",
            TeleportActivateObjects: [],
            TeleportDeactivateObjects: [],
          },
        },
        {
          MediaFileID: -1,
          TextDataID: -1,
          TextContent: {
            UIControlType: 7,
            UIControlName: "BrowserLink1",
            ViewMode: 1,
            IsStatic: false,
            UIControlIconColor: "#00aabb",
            UIControlTextPanelColor: "#00aabb",
            Constraints: [],
            WorldOrientation: 0,
            InitialSpawnGameObject: "",
            InitialSpawnPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            UIControlText: "",
            UIControlIcon: "",
            UIControlVisitedIcon: "",
            ExpandedPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            OnExpandAnimation: "",
            OnExpandActivateObjects: [],
            OnExpandDeactivateObjects: [],
            OnCloseAnimation: "",
            OnCloseActivateObjects: [],
            OnCloseDeactivateObjects: [],
            MustInteract: false,
            IsNetworked: false,
            BrowserLink: "",
          },
        },
        {
          MediaFileID: -1,
          TextDataID: -1,
          TextContent: {
            UIControlType: 8,
            UIControlName: "Animation1",
            ViewMode: 1,
            IsStatic: false,
            UIControlIconColor: "#00aabb",
            UIControlTextPanelColor: "#00aabb",
            Constraints: [],
            WorldOrientation: 0,
            InitialSpawnGameObject: "",
            InitialSpawnPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            InitialSpawnScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            UIControlText: "",
            UIControlIcon: "",
            UIControlVisitedIcon: "",
            ExpandedPositionOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedRotationOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            ExpandedScaleOffset: {
              x: 0,
              y: 0,
              z: 0,
            },
            OnExpandAnimation: "",
            OnExpandActivateObjects: [],
            OnExpandDeactivateObjects: [],
            OnCloseAnimation: "",
            OnCloseActivateObjects: [],
            OnCloseDeactivateObjects: [],
            MustInteract: false,
            IsNetworked: false,
            OnPressAnimation: "",
            OnPressActivateObjects: [],
            OnPressDeactiveObjects: [],
          },
        },
      ],
    }))
    : [];
  useEffect(() => {
    const updateExperienceNode = (updatedNode: NodeRenderDetails) => {
      setExperiences((prevExperiences) => {
        return prevExperiences.map((exp) => {
          if (exp.ExperienceID === currentExperience?.ExperienceID) {
            return {
              ...exp,
              rolePlaySectionObjects: exp.rolePlaySectionObjects.map((node) => {
                if (node.SectionObjectID === currentNode?.SectionObjectID) {
                  return {
                    ...node,
                    title: updatedNode.title,
                    text: updatedNode.text,
                  };
                }
                return node;
              }),
            };
          }
          return exp;
        });
      });
    };

    if (currentNode && nodeRenderDetails.PageNo) {
      updateExperienceNode(nodeRenderDetails);
    }
  }, [nodeRenderDetails, currentNode]);

  const handleSaveChanges = useCallback(async () => {
    try {

      // console.log(projectSourceIDVlue,
      //   currentNode?.SectionObjectID)
      // const response = await UpdateStepNodes(
      //   projectSourceIDVlue.projectID,
      //   currentNode?.SectionObjectID,
      //   newStepNodeArray,
      // );
      // if (response.code === 200) {
      // } else {
      //   console.error("Failed to update experience nodes:", response.message);
      // }
    } catch (error) {
      console.error("Error updating experience nodes:", error);
    }
  }, [Experiences, experienceIDNumber]);

  useEffect(() => {
    handleSaveChanges();
    console.log("window.location.href", window.location.href)
    if (window.location.href.includes('3d')) {
      setViewMode('3D');
    } else {
      setViewMode('2D');
    }
  }, [handleSaveChanges]);


  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = event.target.value;
    setEditableTitle(newTitle);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    setEditableText(newText);
  };

  const handleTitleSave = () => {
    const updatedDetails = {
      ...nodeRenderDetails,
      title: editableTitle.trim(),
    };

    setNodeRenderDetails(updatedDetails); // Update nodeRenderDetails
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.PageNo === updatedDetails.PageNo ? updatedDetails : node
      )
    );
    setIsTitleEditable(false); // Exit edit mode
  };




  const handleTextSave = () => {
    const updatedDetails = {
      ...nodeRenderDetails,
      text: editableText.trim(),
    };

    setNodeRenderDetails(updatedDetails); // Update nodeRenderDetails
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.PageNo === updatedDetails.PageNo ? updatedDetails : node
      )
    );
    setIsTextEditable(false); // Exit edit mode
  };

  useEffect(() => {
    setEditableTitle(nodeRenderDetails.title);
    setEditableText(nodeRenderDetails.text);
  }, [nodeRenderDetails.title, nodeRenderDetails.text]);

  const handleFullscreenToggle = () => {
    if (!document.fullscreenElement) {
      document.documentElement
        .requestFullscreen()
        .then(() => setIsFullscreen(true))
        .catch((err) => console.log(err));
    } else if (document.exitFullscreen) {
      document
        .exitFullscreen()
        .then(() => setIsFullscreen(false))
        .catch((err) => console.log(err));
    }
  };

  const createNewNodeRenderDetails = (type: string, id: string) => {
    const newRenderDetails: NodeRenderDetails = {
      title: "",
      text: "",
      image: "",
      audio: "",
      video: "",
      PageNo: parseInt(id, 10),
      options: [],
    };

    setNodes((prevNodes) => [...prevNodes, newRenderDetails]);
    setNodeRenderDetails(newRenderDetails);
  };
  useEffect(() => {
    console.log(currentNodeValue, Experiences)
    let viewModeFromLocal = localStorage.getItem('currentNodeDetails')
    console.log(viewModeFromLocal)
    
    if (viewModeFromLocal) {
      setViewMode(viewModeFromLocal)
    }
    console.log(viewMode)
    console.log()
    // console.log(Experiences)
    if (currentNodeValue !== null && currentNodeValue !== undefined && currentNodeValue !== 0 && Experiences.length != 0) {
      Experiences[0].rolePlaySectionObjects.map((item) => {
        console.log(item.SectionObjectID, currentNodeValue)
        let description = item.mediaFiles[0]?.TextContent
        if (item.SectionObjectID == currentNodeValue) {
          console.log(item, "data")
          const newNode: NodeRenderDetails = {
            title: item.TrainingSectionName,
            text: description || '',
            image: "",
            audio: "",
            video: "",
            PageNo: 1,
            options: [],
          };
          setCurrentNode(item)
          setNodes((prevNodes) => [...prevNodes, newNode]);
          setNodeRenderDetails(newNode);
        }
      })
      console.log('Selected Node ID has changed:', currentNodeValue);

    }
    
  }, [currentNodeValue]);


  // console.log(nodeValue)

  const handleViewToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMode = event.target.checked ? "3D" : "2D";
    console.log('projectSourceIDVlue', projectSourceIDVlue)
    console.log('includes')
    if (window.location.href.includes('experience')) {
      if (newMode === "3D") {
        navigate(`/project/${projectSourceIDVlue.projectID}/projectSection/${projectSourceIDVlue.projectSectionID}/space/${projectSourceIDVlue.spaceID}/`); // Replace '/3dview' with your actual 3D view route
        localStorage.setItem('currentNodeDetails', "3D")
      } else {
        navigate(`/experience/${projectSourceIDVlue.id}/project/${projectSourceIDVlue.projectID}/projectSection/${projectSourceIDVlue.projectSectionID}/space/${projectSourceIDVlue.spaceID}`); // Replace '/3dview' with your actual 3D view route
        localStorage.setItem('currentNodeDetails', "2D")
      }
    }
    setViewMode(newMode);
  };

  const handleDropAudio = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      setAudioFile(e.dataTransfer.files[0]);
    }
  };

  const handleDragOverAudio = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!projectSourceIDVlue.projectSectionID) {
      console.log(projectSourceIDVlue)
      console.log(localStorage.getItem('experienceData'))
      let data = localStorage.getItem('experienceData')
      if (data) {
        let paramValue = JSON.parse(data)
        console.log(paramValue)
        let option = paramValue.option
        let id = paramValue.id
        let spaceID = paramValue.spaceID
        let projectSectionID = paramValue.projectSectionID
        let projectID = paramValue.projectID
        if (option && id && spaceID && projectSectionID && projectID) {
          dispatch(
            setDropdownValues({ option, id, spaceID, projectSectionID, projectID })
          );
        }
        if (!window.location.href.includes('experience')) {
          navigate(`/experience/${id}/project/${projectID}/projectSection/${projectSectionID}/space/${spaceID}`); // Replace '/3dview' with your actual 3D view route

        }
      }
    }
  }, [])

  if (completed) {
    return (
      <>
        <Header
          data={mainExperience?.ExperienceName ?? ""}
          list={list}
          onOrgChange={() => { }}
          isDropdownDisabled={true}
          projectResponse={projres}
          displaypic={dp}
        />
        <div className="overflow-hidden flex justify-center items-center min-h-screen bg-gray-100 ">
          <div className="overflow-hidden mt-[8vh] h-[742px] max-w-[672px] relative bg-white rounded-xl shadow w-full px-4 py-8 mt-2">
            <div className="confetti-wrapper absolute top-0 left-0 w-full h-[800px] overflow-hidden z-0 pointer-events-none">
              <Confetti width={dimensions.width} height={dimensions.height} />
            </div>
            <div className="flex flex-col justify-center items-center gap-6 z-0 relative">
              <div className="relative flex flex-col justify-center items-center p-4">
                <img
                  src={winningicon}
                  alt=""
                  className="w-[407px] h-[359px] mt-[4vh]"
                />
              </div>

              <div className="text-center text-zinc-800 text-[24px] mt-[-5vh]">
                Congratulations!!
              </div>
              <div className="w-[95%] z-0 bg-white text-gray-500 text-[53px] md:text-sm leading-relaxed px-2 md:text-left text-center">
                You have successfully completed the experience{" "}
                <b>
                  <span className="text-zinc-800">
                    {mainExperience?.ExperienceName ?? ""}.{" "}
                  </span>{" "}
                </b>{" "}
                <br /> Click continue to go to Altoura home or click retry to
                try the experience again.
              </div>

              <div className="flex flex-col justify-center items-center gap-4 w-full px-4 sm:px-0">
                <button
                  className="w-full sm:w-[95%] h-[40px] px-6 py-3 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-md flex justify-center items-center text-white text-base font-semibold"
                  style={{ fontFamily: "'Selawk'" }}
                  onClick={() => navigate("/")}
                >
                  Continue
                </button>

                <button
                  className="w-full sm:w-[95%] h-[40px] px-6 py-3 bg-white text-blue-500 border border-blue-500 rounded-md flex justify-center items-center text-base font-semibold hover:bg-[#f2f9fe] active:bg-[#e0f2ff]"
                  style={{ fontFamily: "'Selawk'" }}
                  onClick={onRetry}
                >
                  Retry
                </button>
              </div>
            </div>
          </div>

        </div>
      </>
    );
  }

  return (
    <div style={{ height: '100vh !important' }}>
      <Header data={""} list={list} onOrgChange={() => { }} isDropdownDisabled={true} projectResponse={projres} displaypic={dp} />
      <div className="flex flex-row justify-start items-start w-full" >
        <div>

        </div>

        {isPanelVisible && mainExperience && (
          <Canvas
            mainexp={mainExperience}
            onComponentClick={handleComponentClick}
            createNewNodeRenderDetails={createNewNodeRenderDetails}
          />
        )}

        <div className="flex flex-col md:flex-row justify-center items-center w-full">
          <div className="font-selwak flex flex-col items-center justify-center ">
            <div className="fixed right-3 top-14 z-50 sm:right-3.5 sm:top-20"></div>

            <div className="flex justify-center w-full">
              <div className="flex flex-col lg:flex-row mx-auto max-w-5xl">
                <div className="w-full">
                  <div className="flex justify-center w-full">
                    <div className="flex flex-col lg:flex-row mx-auto max-w-5xl">
                      <div className="w-full">
                        <div className="w-[870px] pt-[10vh] mb-[-120vh] ml-7  justify-center items-center gap-2 inline-flex">
                          <div className="grow shrink basis-0  justify-start items-center gap-4 flex">
                            <div className="justify-start items-center gap-2.5 flex">
                              <div className="text-center text-stone-900 text-2xl font-normal font-['Selawk'] leading-[42.72px] truncate">
                                {mainExperience?.ExperienceName}
                              </div>
                            </div>
                          </div>
                          <div className="justify-start items-center gap-1 flex ">
                            <div
                              className="pl-2 pr-4 py-2 rounded  justify-center items-center gap-1 flex cursor-pointer cursor-pointer hover:bg-[#f1f3f5] active:bg-[#e8eaed]"
                              onClick={handleFullscreenToggle}
                            >
                              <div className="w-6 mt-[2px] relative">
                                <svg
                                  width="32"
                                  height="30"
                                  viewBox="0 0 32 32"
                                  fill="black"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_31_21)">
                                    <path
                                      d="M15.975 10.6188C14.6125 10.6188 9.88123 10.9313 6.25623 14.9313L7.18123 15.7688C8.84998 13.925 10.8125 12.9563 12.4687 12.4375C11.5125 13.3875 10.9187 14.7 10.9187 16.15C10.9187 19.0313 13.2625 21.375 16.1437 21.375C19.025 21.375 21.3687 19.0313 21.3687 16.15C21.3687 14.7875 20.8375 13.5438 19.9812 12.6125C21.5375 13.1688 23.2937 14.1375 24.8062 15.825L25.7375 14.9938C22.1125 10.9375 17.3375 10.6188 15.9625 10.6188H15.975ZM16.1562 20.125C13.9625 20.125 12.1812 18.3438 12.1812 16.15C12.1812 13.9563 13.9625 12.175 16.1562 12.175C18.35 12.175 20.1312 13.9563 20.1312 16.15C20.1312 18.3438 18.35 20.125 16.1562 20.125Z"
                                      fill="black"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_31_21">
                                      <rect width="32" height="32" fill="black" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="text-center text-stone-900 text-base font-normal font-['Selawk'] leading-normal pointer">
                                Preview
                              </div>
                            </div>
                            <div className="pl-2 pr-4 py-2 rounded  justify-center items-center gap-1 flex pointer-cursor cursor-pointer hover:bg-[#f1f3f5] active:bg-[#e8eaed]" onClick={saveChange}>
                              <div className="w-6 h-6 relative">
                                <img src={save} alt=""></img>
                              </div>
                              <div className="text-center text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                                Save
                              </div>
                            </div>
                            <div className="pl-2 pr-4 py-2 rounded  justify-center items-center gap-1 flex cursor-pointer hover:bg-[#f1f3f5] active:bg-[#e8eaed]">
                              <div className="w-6 h-6 justify-center items-center flex">
                                <div className="w-6 h-6 relative">
                                  <img src={closeicon} alt=""></img>
                                </div>
                              </div>
                              <div className="text-center text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                                Close
                              </div>
                            </div>
                            <div className="pl-2 pr-4 py-2 rounded justify-center items-center gap-1 flex">
                              <span>{viewMode}</span>
                              <label className="toggle-switch">
                                <input
                                  type="checkbox"
                                  checked={viewMode === "3D"}
                                  onChange={handleViewToggle}
                                />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {viewMode === "2D" ? (<StepNavigation
                      onPrevious={() => debouncedOnBackClick()}
                      onNext={() => debouncedOnNextClick()}
                      isFirstStep={isFirstStep}
                      isLastStep={isLastStep}
                      title={
                        <div onClick={toggleTitleEdit} className="w-full cursor-pointer">
                          {isTitleEditable ? (
                            <input
                              type="text"
                              value={editableTitle}
                              onChange={handleTitleChange}
                              onBlur={handleTitleSave} // Save on blur
                              autoFocus
                              className="input input-bordered custom-input bg-[#f2f9fe] h-[70px] rounded flex-grow flex flex-col justify-center items-center text-center text-zinc-800 text-sm sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-normal leading-normal transition-height duration-300"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") handleTitleSave();
                              }}
                            />
                          ) : (
                            <span className="block h-[70px] flex items-center justify-center">
                              {nodeRenderDetails.title || "Click here to add title"}
                            </span>
                          )}
                        </div>

                      }
                    />) : (
                      <div></div>
                    )}

                  </div>



                </div>
              </div>
            </div>
            <div className="flex justify-center w-full ">
              {viewMode === "3D" ? (
                <div className="flex flex-col lg:flex-row mx-auto max-w-4xl ">
                  {/* Render 3D-specific content */}
                  <div className="relative flex justify-center items-center overflow-hidden rounded-lg bg-white w-full h-[82vh]">
                    <Edit3dNodes
                      roleplay={roleplay}
                      currentNodeDetails={currentNode}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col lg:flex-row mx-auto max-w-4xl">
                  {/* Render 2D-specific content */}
                  <div className="flex justify-center w-full">
                    <div className="flex flex-col lg:flex-row mx-auto max-w-4xl">
                    {nodeRenderDetails.video === "" &&
  (uploadedImage || nodeRenderDetails.image) ? (
  <div
    className="relative mr-5 flex justify-center items-center overflow-hidden rounded-lg border-b border-transparent px-8 gap-2 bg-white max-w-full lg:w-[880px] min-h-[90vh] md:min-h-[63vh] mb-4 ml-4 lg:max-h-[50vh] h-full md:order-2"
    style={{
      boxShadow:
        "0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)",
      boxSizing: "border-box",
    }}
  >
    {(uploadedImage && uploadedImage.includes(".mp4")) ||
    (nodeRenderDetails.image && nodeRenderDetails.image.includes(".mp4")) ? (
      <video
      src={
        uploadedImage
          ? `https://fbxloader.blob.core.windows.net${uploadedImage.split('.mp4')[0]}.mp4`
          : nodeRenderDetails?.image?.split('.mp4')[0] + '.mp4'
      }
      controls
      className="object-contain"
      style={{
        maxHeight: "90%",
        maxWidth: "100%",
      }}
    ></video>
    ) : (
      <img
        src={
          uploadedImage
            ? `https://fbxloader.blob.core.windows.net${uploadedImage}`
            : nodeRenderDetails.image &&
              nodeRenderDetails.image.startsWith(
                "https://fbxloader.blob.core.windows.net"
              )
            ? nodeRenderDetails.image
            : "https://altouraonyx.azureedge.net" + nodeRenderDetails.image
        }
        alt="Uploaded file"
        className="object-contain"
        style={{
          maxHeight: "90%",
          maxWidth: "100%",
        }}
      />
    )}

    <input
      type="file"
      id="fileUpload"
      style={{ display: "none" }}
      onChange={handleImageUpload}
    />
    <button
      className="absolute top-2 right-2 p-2 bg-white rounded-full hover:bg-gray-200"
      onClick={toggleDropdown}
    >
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_678)">
          <path
            d="M8.1875 19.9L19.4 8.6875L23.8125 13.1L12.5937 24.3188L8.23125 24.3"
            stroke="gray"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M8.2375 24.2937L8.1875 19.9"
            stroke="gray"
            strokeWidth="2"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_678">
            <rect
              width="32"
              height="32"
              fill="gray"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </button>
    {dropdownVisible && (
      <div className="absolute text-left top-10 right-0 bg-white border rounded shadow-lg">
        <button
          className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
          onClick={handleUploadClick}
        >
          Upload Media
        </button>
        <button
          className="block text-left px-4 py-2 w-full text-gray-800 hover:bg-gray-200"
          onClick={handleDeleteClick}
        >
          Delete
        </button>
      </div>
    )}
  </div>
) : nodeRenderDetails.video || uploadedVideo ? (
                        <div
                          className="relative mr-5 flex flex-col overflow-hidden rounded-lg border-b border-transparent px-8 gap-2 bg-white max-w-full lg:w-[910px] min-h-[90vh] md:min-h-[63vh] mb-4 ml-2 lg:max-h-[50vh] h-full md:order-2 justify-center items-center"
                          style={{
                            boxShadow:
                              "0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)",
                            boxSizing: "border-box",
                          }}
                        >
                          <Player
                            playsInline
                            src={uploadedVideo || nodeRenderDetails.video}
                          >
                            <BigPlayButton position="center" />
                          </Player>
                          <input
                            type="file"
                            id="fileUpload"
                            style={{ display: "none" }}
                            onChange={handleVideoUpload}
                          />
                          <button
                            className="absolute top-2 right-2 p-2 bg-white rounded-full  hover:bg-gray-200"
                            onClick={() =>
                              document.getElementById("fileUpload")!.click()
                            }
                          >
                            <svg
                              width="32"
                              height="33"
                              viewBox="0 0 32 33"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_1_678)">
                                <path
                                  d="M8.1875 19.9L19.4 8.6875L23.8125 13.1L12.5937 24.3188L8.23125 24.3"
                                  stroke="gray"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M8.2375 24.2937L8.1875 19.9"
                                  stroke="gray"
                                  stroke-width="2"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1_678">
                                  <rect
                                    width="32"
                                    height="32"
                                    fill="gray"
                                    transform="translate(0 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <div className="flex font-light text-zinc-500 rounded bg-white items-center justify-center p-4 min-h-[90vh] md:min-h-[63vh] lg:max-h-[50vh] ml-5 shadow cursor-pointer">
                          <label
                            htmlFor="file-upload"
                            className="flex font-light flex-col items-center justify-center w-full"
                          >
                            <div className="text-2xl">+</div>
                            <br />
                            <span className="text-sm text-center font-light">
                              {/* {currentNodeValue} */}
                            </span>
                            <input
                              id="file-upload"
                              type="file"
                              accept="image/*,video/*"
                              onChange={(event) => {
                                const files = event.target.files;
                                if (files && files[0]) {
                                  const file = files[0];
                                  if (file.type.startsWith("image/")) {
                                    handleImageUpload(event);
                                  } else if (file.type.startsWith("video/")) {
                                    handleVideoUpload(event);
                                  }
                                }
                              }}
                              className="hidden"
                            />
                          </label>
                        </div>
                      )}
                      <div
                        className="relative mr-5 flex flex-col overflow-hidden rounded-lg border-b border-transparent px-8 gap-2 bg-white max-w-full lg:w-[910px] min-h-[90vh] md:min-h-[63vh] mb-4 ml-4 lg:max-h-[50vh] h-full md:order-2"
                        style={{
                          boxShadow:
                            "0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)",
                          boxSizing: "border-box",
                        }}
                      >
                        {nodeRenderDetails.options &&
                          nodeRenderDetails.options.length > 0 ? (
                          <>
                            <div className="p-8 pl-0 flex flex-col text-zinc-500 text-lg font-normal font-['Selawk'] leading-relaxed">
                              {nodeRenderDetails.text}
                            </div>
                            <div className="flex flex-col justify-start items-start gap-4 w-full">
                              <React.Fragment>
                                <div className="flex flex-col text-zinc-500 text-lg font-normal font-['Selawk'] leading-relaxed items-start justify-start">
                                  Choose one of the following options.
                                </div>
                                <div className="flex flex-col justify-start items-center gap-3 w-full">
                                  {nodeRenderDetails.options.map(
                                    (option, optionIndex) => (
                                      <label
                                        key={optionIndex}
                                        className={`w-full h-auto sm:h-14 p-4 bg-white rounded-lg border justify-start items-center gap-4 inline-flex cursor-pointer ${selectedRadioAns ===
                                          option.SectionObjectLinkID
                                          ? "border-[#4295e7]"
                                          : "border"
                                          } hover:bg-[rgba(66,149,231,0.1)]`}
                                      >
                                        <input
                                          type="radio"
                                          name={`quiz_${optionIndex}`}
                                          value={option.TextContent}
                                          onChange={() =>
                                            onAnswerSelect(option.SectionObjectLinkID)
                                          }
                                          className="opacity-0"
                                        />
                                        <div
                                          className={`w-6 h-6 rounded-full border flex justify-center items-center ${selectedRadioAns ===
                                            option.SectionObjectLinkID
                                            ? "border-[#4295e7]"
                                            : "border-zinc-800"
                                            }`}
                                        >
                                          <div
                                            className={`w-4 h-4 rounded-full ${selectedRadioAns ===
                                              option.SectionObjectLinkID
                                              ? "bg-[#4295e7]"
                                              : ""
                                              }`}
                                          ></div>
                                        </div>
                                        <div className="grow shrink basis-0 self-stretch text-zinc-800 text-lg font-normal font-['Selawk'] leading-relaxed truncate">
                                          {option.TextContent}
                                        </div>
                                      </label>
                                    )
                                  )}
                                </div>
                              </React.Fragment>
                            </div>
                            {selectedOption[currentStep] && (
                              <div className="font-light font-['Selawk'] h-full text-zinc-500 mt-5 flex justify-end items-center w-full">
                                <div
                                  className="w-[164px] h-[45px] px-8 py-4 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-md flex justify-center items-center gap-2.5 cursor-pointer"
                                  onClick={() => onAnswerSubmit(currentStep)}
                                >
                                  <div className="text-center text-white text-lg font-semibold font-['Selawik'] leading-relaxed">
                                    Submit
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="relative">
                            {isEditing ? (
                              <textarea
                                className="rounded font-light font-['Selawk'] h-full text-[#1f1f1f] text-m font-normal leading-relaxed custom-inputedit-text"
                                value={editableText}
                                onChange={handleTextChange}
                                onBlur={handleTextSave}
                                autoFocus
                              />
                            ) : (
                              <div
                                className="rounded font-light font-['Selawk'] h-full text-[#1f1f1f] text-m font-normal leading-relaxed custom-inputedit-text"
                                onClick={toggleTextEdit}
                              >
                                {nodeRenderDetails.text || "Click here to add text"}
                              </div>
                            )}
                          </div>

                        )}
                        <div className="absolute bottom-4 right-4 flex items-center">
                          <svg
                            onClick={handleAudioToggle}
                            width="28px"
                            height="28px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_15_174)">
                              <rect width="24" height="24" fill="white" />
                              <path
                                d="M3 16V8H6L11 4V20L6 16H3Z"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13 9C13 9 15 9.5 15 12C15 14.5 13 15 13 15"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15 7C15 7 18 7.83333 18 12C18 16.1667 15 17 15 17"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M17 5C17 5 21 6.16667 21 12C21 17.8333 17 19 17 19"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_15_174">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          {isAudioInputVisible && (
                            <div className="flex flex-col items-start bg-white p-4 rounded-lg shadow-lg absolute right-0 bottom-10">
                              <input
                                type="file"
                                accept="audio/*"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                onDrop={handleDropAudio}
                                onDragOver={handleDragOverAudio}
                                className="hidden"
                              />
                              <button
                                onClick={() => fileInputRef.current?.click()}
                                className="mb-2 px-14 py-2 bg-[#4295e7] text-xs text-white rounded hover:bg-[#1581eb] whitespace-nowrap"
                              >
                                Drop Audio File
                              </button>



                              <button
                                onClick={handlePlayAudio}
                                className="mb-2 px-4 py-2 bg-[#4295e7] text-xs text-white rounded hover:bg-[#1581eb] whitespace-nowrap"
                              >
                                Play Audio
                              </button>

                              <div className="flex flex-col w-full">
                                <input
                                  type="text"
                                  value={textToConvert}
                                  onChange={(e) => setTextToConvert(e.target.value)}
                                  placeholder="Enter text to convert to audio"
                                  className="mb-2 w-[40vh] h-[20vh] border rounded"
                                />
                                <button
                                  onClick={handleGenerateAudio}
                                  className="mb-2 px-14 py-2 bg-[#4295e7] text-xs text-white rounded hover:bg-[#1581eb] whitespace-nowrap"
                                >
                                  Generate Audio
                                </button>
                                <button
                                  onClick={handleTextToAudio}
                                  className="px-4 py-2 bg-[#4295e7] text-xs text-white rounded hover:bg-[#1581eb] whitespace-nowrap"
                                >
                                  Convert Text to Speech
                                </button>
                              </div>
                            </div>
                          )}
                          {audioSrc && (
                            <audio controls ref={audioPlayerRef} className="absolute right-0 bottom-16">
                              <source src={audioSrc} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>
          <Footer />
        </div>

      </div>
    </div>
  );
};