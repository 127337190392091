import React, { DragEventHandler, MouseEventHandler, useState, useEffect } from "react";
import { ObjectData } from "../../../../types/objectTypes";
import { FaChevronUp, FaChevronDown, FaSearch } from "react-icons/fa";
import { useSceneControl } from "../../Providers/SceneContextProvider";
import { HashLoader } from "react-spinners";
import { getSketchFab, getSketchFabDownloadLink } from "../../../../services/sketchFab";
import { useDispatch } from "react-redux";
import { setsketchFabGLBLinks, setSnackBarMessage, setSnackBarStatus } from "../../../../redux/slices/counterSlice";

interface HorizontalScrollableListProps {
  objects: ObjectData[];
  sketchFab?: Boolean;
  sketchFabObject?: ObjectData[];

}


export const HorizontalScrollableList: React.FC<HorizontalScrollableListProps> = ({ objects, sketchFab, sketchFabObject }) => {
  const dispatch = useDispatch()
  const sceneProvider = useSceneControl();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeButton, setActiveButton] = useState('');
  const [tabData, setTabData] = useState<ObjectData[] | undefined>()

  const [fileUrl, setFileUrl] = useState('');
  const [isFileDownloaded, setIsFileDownloaded] = useState(false);

  useEffect(() => {
    // Simulating loading for demonstration
    const timer = setTimeout(() => setLoading(false), 2000);
    if (objects) {
      setTabData(objects)
    }
    return () => clearTimeout(timer);

  }, []);

  const getSketchFabObjectQuery = async (value: any) => {
    if (activeButton === 'Sketch Fab Objects') {
      console.log('SketchFab API', value)
      if (value.length >= 3) {
        const response = await getSketchFab(value);
        setTabData(response.results)
      } else {
        setTabData(sketchFabObject)
      }

    }
  }

  const toggleAccordion = (label: string) => {
    setIsOpen(true)
    if (label === activeButton) {
      setActiveButton('')
      setIsOpen(!isOpen);
    } else {
      setIsSearchVisible(!isSearchVisible);
      setActiveButton(label);
    }

    if (label === 'Sketch Fab Objects') {
      setTabData(sketchFabObject)
    } else {
      setTabData(objects)
    }

  };

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    const clickedObject = (event.currentTarget as any).dataset.object;
    console.log(clickedObject);
  };

  const handleDragStart: DragEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    if (activeButton === 'Sketch Fab Objects') {
      console.log(event)
    } else {
      const draggedObject = (event.currentTarget as any).dataset.object;
      console.log("Drag started: ", draggedObject);
    }

  };

  const handleDragEnd: DragEventHandler<HTMLDivElement> = async (event) => {
    event.stopPropagation();
    if (activeButton === 'Sketch Fab Objects') {
      console.log(event)
      const draggedObject = (event.currentTarget as any).dataset.object;
      let data = JSON.parse(draggedObject)
      console.log(draggedObject, typeof draggedObject, JSON.parse(draggedObject), data['ObjectID'])

      let response = await getSketchFabDownloadLink(data['ObjectID'])
      if(response.url != undefined){
        dispatch(setsketchFabGLBLinks([response.url]))
      }else{
        dispatch(setSnackBarStatus(true))
        dispatch(setSnackBarMessage('Selected Object is only available for SketchFab subscribed users'))
      }
      // downloadFile(response.url)
      

    } else {
      const draggedObject = (event.currentTarget as any).dataset.object;
      sceneProvider.onObjectData.current(draggedObject);
      console.log("Drag ended: ", draggedObject);
    }
  };
  let filteredObjects;
  if (tabData) {

    filteredObjects = tabData.filter(object =>
      object.ObjectName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  return (
    <div className="absolute bottom-0 right-0 w-full bg-white shadow-md">


      <div className="relative group flex flex-grow items-center">
        <div style={{ display: 'flex', justifyContent: 'end', padding: '10px' }}>
          {["Objects", "Sketch Fab Objects"].map((label, index) => (
            <button
              key={index}
              onClick={() => toggleAccordion(label)} // Highlight button on click
              className={`flex items-center px-3 py-1 text-sm font-medium rounded-md border 
            ${activeButton === label ? "bg-blue-500 text-white" : "bg-white text-black hover:bg-gray-200"}`}
            >
              {activeButton === label ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
              {label}
            </button>
          ))}
        </div>
        {
          isOpen && (
            <div className="flex justify-center items-center" style={{ display: 'flex', justifyContent: 'end', padding: '10px' }}>
              <FaSearch
                className="cursor-pointer mr-2"
                onClick={() => setIsSearchVisible(!isSearchVisible)}
              />
              Search
              {isSearchVisible && (
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    getSketchFabObjectQuery(e.target.value);
                  }}
                  placeholder="Search tabData..."
                  className="p-2 transition-width duration-300 h-7 ease-in-out ml-4 rounded border border-gray-300"
                  onBlur={() => setIsSearchVisible(false)}
                  onFocus={() => setIsSearchVisible(true)}
                />
              )}
            </div>
          )
        }
      </div>

      {isOpen && (
        <div>
          <div className="relative flex items-center p-3">

            <div>
              {isOpen && (
                <div >

                  {loading ? (
                    <div className="flex justify-center items-center p-3">
                      <div className="loader">
                        <HashLoader size={40} />
                        Loading...
                      </div>
                    </div>
                  ) : filteredObjects != undefined && filteredObjects.length === 0 ? (
                    <div className="flex justify-center items-center p-3">
                      <p>No tabData found</p>
                    </div>
                  ) : (
                    <div className="flex overflow-x-auto p-3 whitespace-nowrap">
                      {filteredObjects != undefined && filteredObjects.map((object, index) => (
                        <div
                          key={index}
                          className="flex-none mr-1 p-1 bg-gray-200 rounded text-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                          data-object={JSON.stringify(object)}
                          onClick={handleClick}
                          draggable
                          onDragStart={handleDragStart}
                          onDragEnd={handleDragEnd}
                        >
                          {!object.ObjectThumbnail.startsWith('/altouradevcontainer') ? (
                            <img
                              src={object.ObjectThumbnail ? `${object.ObjectThumbnail}` : `https://altouraonyx.azureedge.net/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png`}
                              alt={object.ObjectName}
                              className="w-20 h-20 object-cover rounded mb-2"
                            />
                          ) : (
                            <img
                              src={object.ObjectThumbnail ? `https://altouraonyx.azureedge.net${object.ObjectThumbnail}` : `https://altouraonyx.azureedge.net/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png`}
                              alt={object.ObjectName}
                              className="w-20 h-20 object-cover rounded mb-2"
                            />
                          )}
                          <div className="text-sm font-bold"> {object.ObjectName.length > 10 ? `${object.ObjectName.slice(0, 10)}...` : object.ObjectName}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

          </div>

        </div>
      )}
    </div>
  );
};

export default HorizontalScrollableList;
