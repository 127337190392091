import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardComponent } from "../../../../components/shared/Card/CardComponent";
import { getExperienceList } from "../../../../services/experienceService";
import localStorageService from "../../../../services/localStorageService"; // Update the path accordingly
import { ExperienceData } from "../../../../types/expTypes";
import emptysvg from "../../../../logos/EmptyIllustration.svg";
import blackbackbutton from "../../../../logos/ico_left_arrow_black.svg";
import add from "../../../../logos/ico_add.svg";
import search from "../../../../logos/ico_search.svg";
import down from "../../../../logos/ico_down.svg";
import closesearch from "../../../../logos/ico_close.svg";
import content from "../../../../logos/Content.svg";
import CreateExpPopUp from "./CreateExpPoupUp/CreateExpPopUp";
import { HashLoader } from "react-spinners";
import altourablue from "../../../../logos/altourablue.svg";
import CardskeletonLoader from "../../../../components/shared/Card/CardSkeleton";

import { useDispatch } from 'react-redux';
import { setDropdownValues } from '../../../../redux/slices/counterSlice'; // Adjust path as needed

interface Experience {
  ExperienceId: number;
  ExperienceName: string;
  spaceID: string;
  productID: number;
  sectionObjectName: string;
  ProjectID: number;
  projectSectionID: number;
}

export const ExperienceListView: React.FC = () => {
  const [cards, setCards] = useState<Experience[]>([]);
  const [filteredCards, setFilteredCards] = useState<Experience[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [id, setId] = useState<Number>();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [spaceID, setSpaceID] = useState<string>("")
  const [expID, setExpID] = useState<string>("")
  const [secobjID, setSecobjID] = useState<Number | null>(0);

  const { ProjectID } = useParams();
  const projectIDNumber = Number(ProjectID) || -1;
  const orgName = localStorageService.getItem("ProjectName");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };
  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getExperienceList(projectIDNumber);
        const dataToProcess = result.data;

        if (!dataToProcess) {
          throw new Error("No data received from API");
        }

        const transformedData = dataToProcess.map((item: ExperienceData) => ({
          ExperienceId: item.TrainingSectionID,
          ExperienceName: item.TrainingSectionName,
          spaceID: item.SectionObjectID,
          productID: item.ProductID,
          sectionObjectName: item.SectionObjectName,
          ProjectID: item.ProjectID,
          projectSectionID: item.ProjectSectionID,

        }));

        setCards(transformedData);

        setFilteredCards(transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load experiences.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectIDNumber]);

  const [fontSize, setFontSize] = useState("text-xl md:text-[28px]");

  useEffect(() => {
    if (orgName.length > 20) {
      setFontSize("text-base md:text-lg");
    } else if (orgName.length > 10) {
      setFontSize("text-lg md:text-xl");
    } else {
      setFontSize("text-xl md:text-[28px]");
    }
  }, [orgName]);

  const handleCardClick = (id: number, spaceID: string) => {
    setId(id);


    navigate(`/space/${spaceID}/experience/${id}`);
  };

  const handleSearchIconClick = () => {
    setIsSearchActive(true);
    setIsFocused(true);
    setSearchQuery("");
  };
  useEffect(() => {
    if (isSearchActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchActive]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = cards.filter((card) =>
      card.ExperienceName.toLowerCase().includes(query)
    );
    setFilteredCards(filtered);
  };

  const handleCloseSearch = () => {
    setIsSearchActive(false);
    setSearchQuery("");
    setFilteredCards(cards);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (option: string) => {
    setIsDropdownOpen(false);
  };

  const handleDropdownClick = (
    event: React.MouseEvent<Element, MouseEvent>,
    option: string,
    id: number,
    spaceID: string,
    projectSectionID: number,
    projectID: number
  ): void => {
    event.stopPropagation();

    // Dispatch action to store dropdown values in Redux store
    dispatch(
      setDropdownValues({ option, id, spaceID, projectSectionID, projectID })

    );
    let data = {
      "option" : option,
      "id" : id,
      "spaceID" :  spaceID,
      "projectSectionID" : projectSectionID,
      "projectID" : projectID
    }
    localStorage.setItem('experienceData', JSON.stringify(data))

    // Navigation logic
    if (option === '3d view') {
      navigate(
        `/project/${projectID}/projectSection/${projectSectionID}/space/${spaceID}/experience/${id}/3d`
      );
    } else if (option === 'edit') {
      navigate(`/experience/${id}/project/${projectID}/projectSection/${projectSectionID}/space/${spaceID}`);
    } else if (option === 'Edit Space') {
      navigate(
        `/project/${projectID}/projectSection/${projectSectionID}/space/${spaceID}/3d/Edit`
      );
    } else if (option === 'Edit 3d Space') {
      navigate(
        `/experience/${id}/project/${projectID}/projectSection/${projectSectionID}/space/${spaceID}`
      );
    }
  };


  if (loading) {
    return (
      <>
        <div className="bg-stone-50 flex flex-col items-center mt-[-1vh]">
          <div className="fixed pt-3 left-0 right-0 bg-stone-50 flex flex-col items-center w-full z-20">
            <div
              className="mr-[4vh] w-full max-w-[1082px] text-stone-900 text-lg md:text-xl font-normal leading-6 md:leading-9 flex items-center cursor-pointer"
              onClick={handleBackClick}
            >
              <img
                className="mr-2 bg-stone-50 hover:bg-[#f0f2f5] active:bg-[#e8eaed] rounded p-2"
                src={blackbackbutton}
                alt="Back"
              />
              Back to Projects
            </div>
            <div className="bg-stone-50 w-full mt-1 max-w-[1130px] h-auto md:h-[50px] px-4 justify-between items-center flex flex-col md:flex-row">
              <div className="flex justify-start items-center gap-4 flex-wrap md:flex-nowrap">
                <div
                  className={`text-center text-stone-900 text-[28px] font-normal leading-normal md:leading-[49.84px]  truncate max-w-full md:max-w-[200px] overflow-hidden`}
                >
                  {orgName}
                </div>
                <div
                  className={`text-center text-stone-900 font-normal leading-normal md:leading-[49.84px] ${fontSize}`}
                >
                </div>

              </div>
              {!isSearchActive && (
                <div className="relative ml-auto flex">
                  <div
                    className={`w-[185px] h-10 p-2 rounded border ${isDropdownOpen ? "border-[#4295e7]" : "border-grey-50"
                      } justify-center items-center gap-1 inline-flex cursor-pointer hover:bg-gray-100 click:bg-gray-500 `}
                    onClick={handleDropdownToggle}
                  >
                    <div className="w-6 h-6 justify-center items-center flex">
                      <div className="w-6 h-6 relative">
                        <img src={add} alt="Add" />
                      </div>
                    </div>
                    <div className="text-center text-stone-900 text-base text-sm font-normal font-['Selawk'] leading-normal">
                      New Experience
                    </div>
                    <div className="w-6 h-6 justify-center items-center flex">
                      <div
                        className={`w-6 h-6 relative ${isDropdownOpen ? "transform rotate-180" : ""
                          }`}
                      >
                        <img src={down} alt="Arrow" />
                      </div>
                    </div>
                  </div>

                  {isDropdownOpen && (
                    <div className="absolute top-full mt-2 w-[183px] bg-white rounded shadow-lg z-10">
                      <div className="py-2 bg-white rounded shadow border flex-col justify-start items-start inline-flex">
                        <div
                          className="self-stretch px-4 py-2 justify-start items-center gap-2.5 inline-flex cursor-pointer hover:bg-gray-100 active:bg-gray-200 "
                          onClick={() => navigate(`/CreateWithProdigy`)}
                        >
                          <div className="grow shrink basis-0 text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                            Generate with Prodigy
                          </div>
                        </div>
                        <div
                          className="self-stretch px-4 py-2 justify-start items-center gap-2.5 inline-flex cursor-pointer hover:bg-gray-100 active:bg-gray-200 "
                          onClick={openModal}
                        >
                          <div className="grow shrink basis-0 text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                            Create Experience
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className="w-[177px] h-10 px-2 py-[8.50px] rounded justify-start items-center gap-2.5 inline-flex"
                    onClick={() => navigate("/CreateWithExperience")}
                  >
                    <div className="text-center text-stone-900 p-[1.1vh] rounded cursor-pointer text-base text-sm font-normal font-['Selawk'] leading-normal hover:bg-[#f0f2f5] active:bg-[#e8eaed]">
                      Prodigy Managed Files
                    </div>
                  </div>
                </div>
              )}
              <div className="flex justify-start items-center p-2 rounded-full  hover:bg-[#f0f2f5] active:bg-[#e8eaed] cursor-pointer gap-2 mt-4 md:mt-0">
                <div className="w-5 h-5 flex justify-center items-center">
                  <div className="w-5 h-5 relative">
                    {isSearchActive ? (
                      <div className="flex items-center">
                        <div
                          className={`w-[250px] ml-[-25vh] mb-2 mt-[-1vh] h-10 pl-3 pr-2 py-2 bg-white rounded border ${isFocused ? "border-blue-500" : "border-gray-300"} flex-col justify-center items-center inline-flex`}
                        >
                          <div className="self-stretch justify-start items-center gap-1 inline-flex">
                            <input
                              type="text"
                              className="grow shrink basis-0 focus:outline-none text-black placeholder-gray-400 text-sm font-normal font-['Selawk'] leading-tight"
                              value={searchQuery}
                              onChange={handleSearchChange}
                              placeholder="Search Project"
                              onFocus={() => setIsFocused(true)}
                              onBlur={() => setIsFocused(false)}
                              ref={inputRef}
                            />
                            <div className="p-1 rounded-3xl justify-start items-start flex">
                              <div
                                className="w-4 h-4 justify-center items-center flex"
                                onClick={handleCloseSearch}
                              >
                                <img src={closesearch} alt="Close" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <img
                        src={search}
                        alt="Search"
                        onClick={handleSearchIconClick}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="flex flex-col items-center mt-[14vh] mb-10 w-full">
          <div className="flex flex-col items-center w-full max-w-[1200px] mx-auto">
            <div className="w-full p-2 bg-black-50 rounded-lg justify-between items-center gap-1 flex">
              <div className="text-black text-sm font-normal ml-10 justify-start items-center font-['Selawk'] leading-tight">
                Experience Name
              </div>
              <div className="w-full md:w-[364px] h-9 flex flex-col md:flex-row justify-end md:justify-between items-center gap-4">
                <div className="w-full text-right md:text-left ml-[2vh] bg-stone-50 rounded-lg flex justify-end md:justify-start items-center gap-1">
                  <div className="text-gray-700 text-sm font-normal font-['Selawk'] leading-tight">
                    Last Updated On
                  </div>
                </div>
                <div className="w-full text-right md:text-left p-2 bg-stone-50 md:mr-[11vh] rounded-lg flex justify-end md:justify-start items-center gap-1">
                  <div className="text-gray-700 text-sm font-normal font-['Selawk'] leading-tight">
                    Last Updated By
                  </div>
                </div>
              </div>
            </div>

            <CardskeletonLoader count={10} />
          </div>
        </div>

        <CreateExpPopUp
          isVisible={isModalVisible}
          onClose={closeModal}
          projName={orgName}
          cards={cards}

        />
      </>


    );
  }

  if (error) {
    return (
      <>
        <div className="bg-stone-50 flex flex-col items-center justify-center mt-[-1vh] z-[10]">
          <div className="fixed top-0 mt-[9vh] left-0 right-0 bg-stone-50 flex flex-col items-center w-full z-5">
            <div
              className="mr-[4vh] w-full  rounded max-w-[1082px] text-stone-900 text-lg md:text-xl font-normal leading-6 md:leading-9 flex items-center cursor-pointer"
              onClick={handleBackClick}
            >
              <img
                className="mr-2 bg-stone-50 rounded hover:bg-[#f0f2f5] active:bg-[#e8eaed] rounded p-2"
                src={blackbackbutton}
                alt="Back"
              />
              Back to Projects
            </div>
            <div className="bg-stone-50 w-full mt-5 max-w-[1130px] h-auto md:h-[50px] px-4 justify-between items-center flex flex-col md:flex-row">
              <div className="flex justify-start items-center gap-4 flex-wrap md:flex-nowrap">
                <div className="text-center text-stone-900 text-xl md:text-[28px] font-normal leading-normal md:leading-[49.84px]">
                  {orgName}
                </div>
                <div className="text-center text-stone-900 text-xl md:text-[28px] font-normal leading-normal md:leading-[49.84px]">
                  /
                </div>
                <div className="text-center text-stone-900 text-xl md:text-[28px] font-normal leading-normal md:leading-[49.84px]">
                  Experiences
                </div>
              </div>
              {!isSearchActive && (
                <div className="relative ml-[45vh] flex">
                  <div
                    className={`w-[185px] h-10 p-2 rounded border ${isDropdownOpen ? "border-grey-500" : "border-grey-50"
                      } justify-center items-center gap-1 inline-flex cursor-pointer hover:bg-gray-100 click:bg-gray-500 active:border-blue-500`}
                    onClick={handleDropdownToggle}
                  >
                    <div className="w-6 h-6 justify-center items-center flex">
                      <div className="w-6 h-6 relative">
                        <img src={add} alt="Add" />
                      </div>
                    </div>
                    <div className="text-center text-stone-900 text-base text-sm font-normal font-['Selawk'] leading-normal ">
                      New Experience
                    </div>
                    <div className="w-6 h-6 justify-center items-center flex">
                      <div className="w-6 h-6 relative">
                        <img src={down} alt="Down" />
                      </div>
                    </div>
                  </div>

                  {isDropdownOpen && (
                    <div className="absolute top-full mt-2 w-[183px] bg-white rounded shadow-lg z-10">
                      <div className="py-2 bg-white rounded shadow border flex-col justify-start items-start inline-flex">
                        <div
                          className="self-stretch px-4 py-2 justify-start items-center gap-2.5 inline-flex cursor-pointer hover:bg-gray-100 active:bg-gray-200 "
                          onClick={() => navigate("/CreateWithProdigy")}
                        >
                          <div className="grow shrink basis-0 text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                            Generate with Prodigy
                          </div>
                        </div>
                        <div
                          className="self-stretch px-4 py-2 justify-start items-center gap-2.5 inline-flex cursor-pointer hover:bg-gray-100 active:bg-gray-200 "
                          onClick={openModal}
                        >
                          <div className="grow shrink basis-0 text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                            Create Experience
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className="w-[177px] h-10 px-2 py-[8.50px] rounded justify-start items-center gap-2.5 inline-flex"
                    onClick={() => navigate("/CreateWithExperience")}
                  >
                    <div className="text-center text-stone-900 text-base text-sm font-normal font-['Selawk'] leading-normal p-2 rounded-full  hover:bg-[#f0f2f5] active:bg-[#e8eaed] cursor-pointer">
                      Prodigy Managed Files
                    </div>
                  </div>
                </div>
              )}
              <div className="flex justify-start items-center gap-2 mt-4 md:mt-0">
                <div className="w-5 h-5 flex justify-center items-center">
                  <div className="w-5 h-5 relative">
                    {isSearchActive ? (
                      <div className="flex items-center">
                        <div
                          className={`w-[250px]  ml-[-25vh] mb-2  h-10 pl-3 pr-2 py-2 bg-white rounded border ${isFocused ? "border-blue-500" : "border-gray-300"
                            } flex-col justify-center items-center inline-flex `}
                        >
                          <div className="self-stretch justify-start items-center gap-1 inline-flex">
                            <input
                              type="text"
                              className="grow shrink basis-0 focus:outline-none text-gray-500 text-sm font-normal font-['Selawk'] leading-tight"
                              value={searchQuery}
                              onChange={handleSearchChange}
                              placeholder="Search Project"
                              onFocus={() => setIsFocused(true)}
                              onBlur={() => setIsFocused(false)}
                              ref={inputRef}
                            />
                            <div className="p-1 rounded-3xl justify-start items-start flex">
                              <div
                                className="w-4 h-4 justify-center items-center flex"
                                onClick={handleCloseSearch}
                              >
                                <img src={closesearch} alt="Close" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <img
                        src={search}
                        alt="Search"
                        onClick={handleSearchIconClick}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center h-[80vh]">
            <img src={content} alt="Content" className="w-full max-w-[411.97px] h-auto" />
          </div>
        </div>
        <CreateExpPopUp isVisible={isModalVisible} onClose={closeModal} cards={cards} />
      </>
    );
  }

  if (cards.length === 0) {
    return (
      <>
        <div className="bg-stone-50 flex flex-col items-center justify-center mt-[-1vh]">
          <div className="fixed top-0 mt-[9vh] left-0 right-0 bg-stone-50 flex flex-col items-center w-full z-9">
            <div
              className="mr-[4vh] w-full max-w-screen-lg text-stone-900 text-base md:text-lg font-normal leading-5 md:leading-8 flex items-center cursor-pointer"
              onClick={handleBackClick}
            >
              <img
                className="mr-2 bg-stone-50 hover:bg-[#f0f2f5] active:bg-[#e8eaed] rounded p-2"
                src={blackbackbutton}
                alt="Back"
              />
              Back to Projects
            </div>
            <div className="bg-stone-50 w-full max-w-screen-lg h-auto md:h-[50px] px-4 justify-between items-center flex flex-col md:flex-row">
              <div className="flex justify-start items-center gap-4 flex-wrap md:flex-nowrap">
                <div className="text-center text-stone-900 text-[28px]">
                  {orgName}
                </div>
                <div className="text-center text-stone-900 text-base md:text-lg font-normal leading-normal md:leading-[44px]"></div>
                <div className="text-center text-stone-900 text-base md:text-lg font-normal leading-normal md:leading-[44px]"></div>
              </div>
            </div>
          </div>
          <div className="bg-stone-50 flex justify-center mt-[10vh] items-center h-[60vh] lg:h-[75vh] px-4 sm:px-6 lg:px-8 overflow-hidden">
            <div className="flex flex-col justify-center items-center gap-6 w-full max-w-screen-lg mx-auto">
              <div className="flex flex-col justify-start items-center gap-4">
                <div className="w-full max-w-[200px] h-auto mt-4">
                  <img src={emptysvg} alt="Empty" />
                </div>
                <div className="flex flex-col justify-start items-center mt-2">
                  <div className="text-center text-neutral-800 text-xs sm:text-sm lg:text-lg font-thin leading-9">
                    No experiences created yet <br />
                  </div>
                </div>
                <div className="mt-1">
                  <span className="text-xs sm:text-sm text-[#697483]">
                    Use Prodigy to generate experience from a pdf <br />
                    or, create experience manually to get started.
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start gap-4">
                <div
                  className="w-full max-w-xs bg-[#4295e7] px-4 py-2 h-[4vh] rounded-md justify-center items-center inline-flex hover:bg-[#1581eb] active:bg-[#4295e7]"
                >
                  <button
                    className="text-center w-[20vh] text-white text-xs font-normal leading-relaxed hover:bg-[#1581eb] active:bg-[#4295e7]"
                    onClick={() => navigate("/CreateWithProdigy")}
                  >
                    Generate with Prodigy
                  </button>
                </div>
                <div
                  className="w-full max-w-xs px-4 py-2 h-[4vh] bg-white rounded-md mt-[-1vh] border border-blue-500 justify-center items-center inline-flex hover:bg-[#f2f9fe] active:bg-[#e0f2ff]"
                >
                  <button
                    className="text-center text-blue-500 text-xs font-normal leading-relaxed font-['Selawk']"
                    onClick={openModal}
                  >
                    Create Experience
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <CreateExpPopUp isVisible={isModalVisible} onClose={closeModal} cards={cards} />
      </>
    );
  }
  if (filteredCards.length === 0) {
    return (
      <>
        <div className="bg-stone-50 flex flex-col items-center justify-center mt-[-1vh]">
          <div className="fixed top-0 mt-[9vh] left-0 right-0 bg-stone-50 flex flex-col items-center w-full z-9">
            <div
              className="mr-[4vh] w-full max-w-[1082px]  text-stone-900 text-lg md:text-xl font-normal leading-6 md:leading-9 flex items-center cursor-pointer"
              onClick={handleBackClick}
            >
              <img
                className="mr-2 bg-stone-50 hover:bg-[#f0f2f5] active:bg-[#e8eaed]  p-2 rounded"
                src={blackbackbutton}
                alt="Back"
              />
              Back to Projects
            </div>
            <div className="bg-stone-50 w-full mt-5 max-w-[1130px] h-auto md:h-[50px] px-4 justify-between items-center flex flex-col md:flex-row">
              <div className="flex justify-start items-center gap-4 flex-wrap md:flex-nowrap">
                <div className="text-center text-stone-900 text-xl md:text-[28px] font-normal leading-normal md:leading-[49.84px]">
                  {orgName}
                </div>

              </div>
              {!isSearchActive && (
                <div className="relative ml-[45vh] flex">
                  <div
                    className={`w-[185px] h-10 p-2 rounded border ${isDropdownOpen ? "border-[#4295e7]" : "border-grey-50"
                      } justify-center items-center gap-1 inline-flex cursor-pointer hover:bg-gray-100 click:bg-gray-500 `}
                    onClick={handleDropdownToggle}
                  >
                    <div className="w-6 h-6 justify-center items-center flex">
                      <div className="w-6 h-6 relative">
                        <img src={add} alt="Add" />
                      </div>
                    </div>
                    <div className="text-center text-stone-900 text-base text-sm font-normal font-['Selawk'] leading-normal">
                      New Experience
                    </div>
                    <div className="w-6 h-6 justify-center items-center flex">
                      <div
                        className={`w-6 h-6 relative ${isDropdownOpen ? "transform rotate-180" : ""
                          }`}
                      >
                        <img src={down} alt="Arrow" />
                      </div>
                    </div>
                  </div>

                  {isDropdownOpen && (
                    <div className="absolute top-full mt-2 w-[183px] bg-white rounded shadow-lg z-10">
                      <div className="py-2 bg-white rounded shadow border flex-col justify-start items-start inline-flex">
                        <div
                          className="self-stretch px-4 py-2 justify-start items-center gap-2.5 inline-flex cursor-pointer hover:bg-gray-100 active:bg-gray-200 "
                          onClick={() => navigate("/CreateWithProdigy")}
                        >
                          <div className="grow shrink basis-0 text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                            Generate with Prodigy
                          </div>
                        </div>
                        <div
                          className="self-stretch px-4 py-2 justify-start items-center gap-2.5 inline-flex cursor-pointer hover:bg-gray-100 active:bg-gray-200 "
                          onClick={openModal}
                        >
                          <div className="grow shrink basis-0 text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                            Create Experience
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="w-[177px] h-10 px-4 py-[8.50px] rounded justify-start items-center gap-2.5 inline-flex p-2 rounded-full  hover:bg-[#f0f2f5] active:bg-[#e8eaed] cursor-pointer">
                    <div
                      className="text-center text-stone-900 text-base text-sm font-normal font-['Selawk'] leading-normal"
                      onClick={() => navigate("/CreateWithExperience")}
                    >
                      Prodigy Managed Files
                    </div>
                  </div>
                </div>
              )}
              <div className="flex justify-start items-center gap-2 mt-4 md:mt-0">
                <div className="w-5 h-5 flex justify-center items-center">
                  <div className="w-5 h-5 relative">
                    {isSearchActive ? (
                      <div className="flex items-center">
                        <div className="w-[250px]  ml-[-25vh] mb-2  h-10 pl-3 pr-2 py-2 bg-white rounded border border-blue-500 flex-col justify-center items-center inline-flex">
                          <div className="self-stretch justify-start items-center gap-1 inline-flex">
                            <input
                              type="text"
                              className="grow shrink basis-0 focus:outline-none text-gray-500 text-sm font-normal font-['Selawk'] leading-tight"
                              value={searchQuery}
                              onChange={handleSearchChange}
                              placeholder="Search Project"
                              ref={inputRef}
                            />
                            <div className="p-1 rounded-3xl justify-start items-start flex">
                              <div
                                className="w-4 h-4 justify-center items-center flex"
                                onClick={handleCloseSearch}
                              >
                                <img src={closesearch} alt="Close" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <img
                        src={search}
                        alt="Search"
                        onClick={handleSearchIconClick}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-4 h-[70vh] mt-[10vh]">
            <div className="w-full max-w-[200vh] h-auto mt-4">
              <img
                src={content}
                className="w-full max-w-[411.97px] h-auto"
                alt="Not Found"
              />
            </div>
            <div className="flex flex-col justify-start items-center mt-2">
              <div className="text-center text-stone-900 text-xl font-normal font-['Selawk'] leading-[29px]">
                No results found for "{searchQuery}"
              </div>
            </div>
          </div>
        </div>
        <CreateExpPopUp isVisible={isModalVisible} onClose={closeModal} cards={cards} />
      </>
    );
  }
  return (
    <>
      <div className="bg-stone-50 flex flex-col items-center mt-[-1vh]">
        <div className="fixed pt-3 left-0 right-0 bg-stone-50 flex flex-col items-center w-full z-20">
          <div
            className="mr-[4vh] w-full max-w-[1082px] text-stone-900 text-lg md:text-xl font-normal leading-6 md:leading-9 flex items-center cursor-pointer"
            onClick={handleBackClick}
          >
            <img
              className="mr-2 bg-stone-50 hover:bg-[#f0f2f5] active:bg-[#e8eaed] rounded p-2"
              src={blackbackbutton}
              alt="Back"
            />
            Back to Projects
          </div>
          <div className="bg-stone-50 w-full mt-1 max-w-[1130px] h-auto md:h-[50px] px-4 justify-between items-center flex flex-col md:flex-row">
            <div className="flex justify-start items-center gap-4 flex-wrap md:flex-nowrap">
              <div
                className={`text-center text-stone-900 text-[28px] font-normal leading-normal md:leading-[49.84px]  truncate max-w-full md:max-w-[200px] overflow-hidden`}
              >
                {orgName}
              </div>
              <div
                className={`text-center text-stone-900 font-normal leading-normal md:leading-[49.84px] ${fontSize}`}
              >
              </div>

            </div>
            {!isSearchActive && (
              <div className="relative ml-auto flex">
                <div
                  className={`w-[185px] h-10 p-2 rounded border ${isDropdownOpen ? "border-[#4295e7]" : "border-grey-50"
                    } justify-center items-center gap-1 inline-flex cursor-pointer hover:bg-gray-100 click:bg-gray-500 `}
                  onClick={handleDropdownToggle}
                >
                  <div className="w-6 h-6 justify-center items-center flex">
                    <div className="w-6 h-6 relative">
                      <img src={add} alt="Add" />
                    </div>
                  </div>
                  <div className="text-center text-stone-900 text-base text-sm font-normal font-['Selawk'] leading-normal">
                    New Experience
                  </div>
                  <div className="w-6 h-6 justify-center items-center flex">
                    <div
                      className={`w-6 h-6 relative ${isDropdownOpen ? "transform rotate-180" : ""
                        }`}
                    >
                      <img src={down} alt="Arrow" />
                    </div>
                  </div>
                </div>

                {isDropdownOpen && (
                  <div className="absolute top-full mt-2 w-[183px] bg-white rounded shadow-lg z-10">
                    <div className="py-2 bg-white rounded shadow border flex-col justify-start items-start inline-flex">
                      <div
                        className="self-stretch px-4 py-2 justify-start items-center gap-2.5 inline-flex cursor-pointer hover:bg-gray-100 active:bg-gray-200 "
                        onClick={() => navigate(`/CreateWithProdigy/${spaceID}`)}
                      >
                        <div className="grow shrink basis-0 text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                          Generate with Prodigy
                        </div>
                      </div>
                      <div
                        className="self-stretch px-4 py-2 justify-start items-center gap-2.5 inline-flex cursor-pointer hover:bg-gray-100 active:bg-gray-200 "
                        onClick={openModal}
                      >
                        <div className="grow shrink basis-0 text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                          Create Experience
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className="w-[177px] h-10 px-2 py-[8.50px] rounded justify-start items-center gap-2.5 inline-flex"
                  onClick={() => navigate("/CreateWithExperience")}
                >
                  <div className="text-center text-stone-900 p-[1.1vh] rounded cursor-pointer text-base text-sm font-normal font-['Selawk'] leading-normal hover:bg-[#f0f2f5] active:bg-[#e8eaed]">
                    Prodigy Managed Files
                  </div>
                </div>
              </div>
            )}
            <div className="flex justify-start items-center p-2 rounded-full  hover:bg-[#f0f2f5] active:bg-[#e8eaed] cursor-pointer gap-2 mt-4 md:mt-0">
              <div className="w-5 h-5 flex justify-center items-center">
                <div className="w-5 h-5 relative">
                  {isSearchActive ? (
                    <div className="flex items-center">
                      <div
                        className={`w-[250px] ml-[-25vh] mb-2 mt-[-1vh] h-10 pl-3 pr-2 py-2 bg-white rounded border ${isFocused ? "border-blue-500" : "border-gray-300"} flex-col justify-center items-center inline-flex`}
                      >
                        <div className="self-stretch justify-start items-center gap-1 inline-flex">
                          <input
                            type="text"
                            className="grow shrink basis-0 focus:outline-none text-black placeholder-gray-400 text-sm font-normal font-['Selawk'] leading-tight"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search Project"
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            ref={inputRef}
                          />
                          <div className="p-1 rounded-3xl justify-start items-start flex">
                            <div
                              className="w-4 h-4 justify-center items-center flex"
                              onClick={handleCloseSearch}
                            >
                              <img src={closesearch} alt="Close" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={search}
                      alt="Search"
                      onClick={handleSearchIconClick}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="flex flex-col items-center mt-[14vh] mb-10 w-full">
        <div className="flex flex-col items-center w-full max-w-[1200px] mx-auto">
          <div className="w-full p-2 bg-black-50 rounded-lg justify-between items-center gap-1 flex">
            <div className="text-black text-sm font-normal ml-10 justify-start items-center font-['Selawk'] leading-tight">
              Experience Name
            </div>
            <div className="w-full md:w-[364px] h-9 flex flex-col md:flex-row justify-end md:justify-between items-center gap-4">
              <div className="w-full text-right md:text-left ml-[2vh] bg-stone-50 rounded-lg flex justify-end md:justify-start items-center gap-1">
                <div className="text-gray-700 text-sm font-normal font-['Selawk'] leading-tight">
                  Last Updated On
                </div>
              </div>
              <div className="w-full text-right md:text-left p-2 bg-stone-50 md:mr-[12vh] rounded-lg flex justify-end md:justify-start items-center gap-1">
                <div className="text-gray-700 text-sm font-normal font-['Selawk'] leading-tight">
                  Last Updated By
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-wrap  ${filteredCards.length < 5 ? "justify-center" : "justify-center"
              } gap-0 sm:gap-2 md:gap-4 w-full`}
          >
            {filteredCards.map((card, index) => (
              <div
                onClick={() => handleCardClick(card.ExperienceId, card.spaceID)}
                key={`${card.ExperienceId}-${index}`}
                className="mt-[-0.5vh]"
              >
                <CardComponent
                  data={card}
                  experienceId={card.ExperienceId}
                  spaceID={card.spaceID}
                  projectSectionID={card.projectSectionID}
                  projectID={projectIDNumber}
                  handleDropdownClick={handleDropdownClick}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <CreateExpPopUp
        isVisible={isModalVisible}
        onClose={closeModal}
        projName={orgName}
        cards={cards}

      />
    </>
  );
};
