import apiClient from "./apiClient";
import { ProjectsApiResponse } from "../types/projectTypes";

export const listProjectsAndOrgs = async (userId: number) => {
  try {
    const response = await apiClient.get(
      `/project/getProjectsForUser/${userId}/v3?platformid=3`
    );
    const projectResponse: ProjectsApiResponse = response.data;
    return projectResponse;
  } catch (error) {
    throw error;
  }
};

export const getSpaceBuilderData = async (
  projectId: number | null,
  projectSectionId: number | null
) => {
  try {
    const response = await apiClient.get(
      `/project/getAssetDataForProject3DSpace/${projectId}/${projectSectionId}/v1`
    );
    // You can handle the response further, e.g., storing the user token
    console.log("hello", response);

    return response.data;
  } catch (error) {
    console.error(
      "Error during fetchAllDataByExperienceIDfetchAllDataByExperienceIDfetchAllDataByExperienceID-up:",
      error
    );
    throw error;
  }
};

export const fetchSpaceObjectDetails = async (url: string) => {
  try {
    const response = await apiClient.get(url);
    // You can handle the response further, e.g., storing the user token
    console.log("hello", response);

    return response.data;
  } catch (error) {
    console.error(
      "Error during fetchAllDataByExperienceIDfetchAllDataByExperienceIDfetchAllDataByExperienceID-up:",
      error
    );
    throw error;
  }
};
