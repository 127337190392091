import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';

interface ProjectSourceIDValue {
  projectID: number | null;
  projectSectionID: number | null;
  spaceID: string | null;
}

interface Position {
  x: number;
  y: number;
};

interface ComponentType {
  id: string | '';
  position: Position | '';
  size: {
    width: number;
    height: number;
  }
  type: string;
  defaultleftconnections?: 1
  connections?: number;
};

interface CounterState {
  count: number;
  dropdown: {
    option: string | null;
    id: number | null;
    spaceID: string | null;
    projectSectionID: number | null;
    projectID: number | null;
  };
  projectSourceIDValue: ProjectSourceIDValue;
  selectedNodeId: number | null;
  selectedNodeConnection : any[] | undefined ;
  sectionObjectConnectorData: [ComponentType] | null;
  loadingState : boolean | true;
  SnackBarStatus : boolean | true;
  SnackBarMessage : string | "";
  sketchFabGLBLinks : string[];
}

const initialState: CounterState = {
  count: 0,
  dropdown: {
    option: null,
    id: null,
    spaceID: null,
    projectSectionID: null,
    projectID: null,
  },
  projectSourceIDValue: {
    projectID: null,
    projectSectionID: null,
    spaceID: null,
  },
  selectedNodeId: 0,
  selectedNodeConnection : [],
  sectionObjectConnectorData: [
    {
      id: '',
      position: '',
      size: {
        width: 0,
        height: 0
      },
      type: 'Str',
      defaultleftconnections: 1,
      connections: 0
    }
  ],
  loadingState : false,
  SnackBarStatus : false,
  SnackBarMessage : 'Welcome to Altoura',
  sketchFabGLBLinks : []
};


const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.count += 1;
    },
    decrement: (state) => {
      state.count -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.count += action.payload;
    },
    setDropdownValues: (
      state,
      action: PayloadAction<{
        option: string;
        id: number;
        spaceID: string;
        projectSectionID: number;
        projectID: number;
      }>
    ) => {
      state.dropdown = action.payload;
    },
    setProjectSourceIDValue: (
      state,
      action: PayloadAction<ProjectSourceIDValue>
    ) => {
      state.projectSourceIDValue = action.payload;
    },
    setCurrentNodeValue: (
      state,
      action: PayloadAction<number>) => {
      state.selectedNodeId = action.payload
    },
    setsectionObjectConnectorData: (
      state,
      action: PayloadAction<[ComponentType]>) => {
      state.sectionObjectConnectorData = action.payload
    },
    setselectedNodeConnection: (
      state,
      action: PayloadAction<any[]>
    ) => {
      state.selectedNodeConnection = action.payload
    },
    setLoadingState:(
      state,
      action : PayloadAction<boolean>
    ) => {
      console.log('Loading')
      state.loadingState = action.payload
    },
    setSnackBarStatus:(
      state,
      action : PayloadAction<boolean>
    ) => {
      console.log('Loading')
      state.SnackBarStatus = action.payload
    },
    setSnackBarMessage : (
      state,
      action : PayloadAction<string>
    )=>{
      state.SnackBarMessage = action.payload
    },
    setsketchFabGLBLinks: (
      state,
      action: PayloadAction<any[]>
    ) => {
      state.sketchFabGLBLinks = action.payload
    },
  },
});

export const {
  increment,
  decrement,
  incrementByAmount,
  setDropdownValues,
  setProjectSourceIDValue,
  setCurrentNodeValue,
  setSnackBarMessage,
  setSnackBarStatus,
  setsectionObjectConnectorData,
  setselectedNodeConnection,
  setLoadingState,
  setsketchFabGLBLinks
} = counterSlice.actions;
export default counterSlice.reducer;
