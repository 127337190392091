import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Mesh, Raycaster, Vector2, Vector3, AudioListener } from 'three';
import { GridGround } from '../Environment/GridGround';
import TextPanel from '../UIComponents/Panels/TextPanel';
import { Physics } from '@react-three/cannon';
import CameraControls from '../PlayerControls/CameraControls';
import KeyboardControls from '../PlayerControls/KeyboardControls';
import './Scene.css';
import { NodeRenderDetails } from '../../../types/nodeDataTypes';
import DottedBezierLine from '../UIComponents/Spline/DottedSpline';
import Confetti from '../UIComponents/Spline/Confetti';
import ExplosionConfetti from '../UIComponents/Spline/Confetti';
import GLBModelViewer from './Loaders/GLBModelViewer';
import { Html, useProgress } from '@react-three/drei';
import LoaderCube from './Loaders/LoaderCube';
import { AssetData, SpaceObject } from '../../../types/spaceTypes';
import GLBModelViewerSpaceObject from './Loaders/GLBModelViewerSpaceObject';
import HdrEnvironment from './Loaders/HdrEnvironment';
import hdrPath from '../../../assessts/environment/autoshop_01_1k.hdr'
import { HierarchyView, SceneExtractor, SceneNode } from '../UIComponents/SceneTools/SceneHierarchy';
import { GetObjectAndTags } from '../../../services/experienceService';
import { listProjectsAndOrgs } from '../../../services/projectService';
import { Organization } from '../../../types/projectTypes';
import { ObjectData } from '../../../types/objectTypes';
import HorizontalScrollableList from './components/HorizontalScrollbar';
import { SceneContextProvider } from '../Providers/SceneContextProvider';
import ObjectSpawner from './Loaders/ObjectSpawner';
import { CreateObjects } from '../../../services/experienceService';
import { getSketchFab } from '../../../services/sketchFab';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
const GLBLink = '/models/3DModels/20250120070538828porsche_924_carrera_gtr.glb';



const CameraSetup: React.FC = () => {
  const { camera } = useThree();

  useEffect(() => {
    console.log("CameraSetup is called");
    camera.position.set(0, 1.6, 3); // Set the camera height to 1.6 meters
    const listener = new AudioListener();
    camera.add(listener);

  }, []); // Empty dependency array ensures this runs only once

  return null;
};



interface SceneProps {
  spaceobjects: AssetData | undefined;
  roleplay?: {}
  nodeRenderDetails: NodeRenderDetails
}


export const SceneNodeEdit3D: React.FC<SceneProps> = ({ spaceobjects,
  roleplay: roleplay,
  nodeRenderDetails: nodeRenderDetails
}) => {


  const [cubePosition, setCubePosition] = useState(new Vector3(0, 0, 0));
  const [panelPosition, setpanelPosition] = useState(new Vector3(0, 1.6, 0));

  const [isLoading, setIsLoading] = useState(true);
  const [sceneData, setSceneData] = useState<SceneNode | null>(null);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<Organization[]>([]);
  const [error, setError] = useState("");
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const [objects, setobjects] = useState<any>([]);
  const [sketchFabObjects, setsketchFabObjects] = useState<any>([]);
  const [filteredobjects, setFilteredobjects] = useState<ObjectData[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [totalobjects, setTotalobjects] = useState(20);
  const [isFocused, setIsFocused] = useState(false);
  const [sortType, setSortType] = useState<string>("latest");
  const [tagsdata, setTagsData] = useState<any>();
  const [objresponse, setObjresponse] = useState<any>();
  const [searchFilteredobjects, setSearchFilteredobjects] = useState<
    ObjectData[]
  >([]);
  const [sketchfabGLB, setSketchfabGLB] = useState<boolean>(false)


  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const sketchFabObjectsURL = useSelector((state: RootState) => state.counter.sketchFabGLBLinks);
  const GLBLink = "https://sketchfab-prod-media.s3.amazonaws.com/archives/f3557814de544abe8be017405f2eec49/glb/8e98fbf5819e4abebc9ea35a9bdf0d84/2020_bmw_m8_coupe.glb?AWSAccessKeyId=ASIAZ4EAQ242FK2Z2ZK3&Signature=f%2B2gk8GZZcurEIdT55ZzmVt8KQg%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEMf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMSJGMEQCIELQWMVQ%2FYHdRQbsl%2BHvxh0kYk5%2FGodOl1d%2F%2FqjtNNfNAiAL8vbZWf6BQhSH5GU5PsSQ6z4DoAowUfUD0fIzqojSuCq6BQjA%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDY3ODg3NDM3MTg5MiIM%2FCJqr2%2FI6PwSUiFJKo4F3I5%2BLqWqOiNaxK0iVsQi18iSMo4WIWhGoQej8FFf2NaltFJQWHWcsliCyqrBg0jnlWF20%2BLExANmU382p4rJAOmtsOsQIm1FDjVnJ3f7ZOHY9o7yIZXVUeikOPeEH8B0xDs%2Fp003dkXZKX4WB7IFPkgSi3GIDhNJyY4c%2B%2BEarDzH8hXa%2BGq5%2BGjyGT2KzbX2TJIV9eORTaZ5aXXZgA3lzbdtb6BHMR6%2FrhbthDZNIhYpi8BAJWG%2FUqfGZWN13fWAtllfSn6udxLNDkfaMHota2U3%2FfpmBnp8m3Aeab%2FN7vrRVD4SaksV8S%2FVo24RQpHpv5HCBA%2BbGb9eL2YJYXSoK6RpMTrbpxyOPY%2FWUIqf%2BAqbhRoNSTYrrwykLuDCvQ1QQRO%2FNllJQLE7K37jbG7he8RkYKL6faFhHccMA4xtPf4lY3LP%2BBz1HDPLtXdKsVz1B6TmvThWQUTSK3DEM0s3CLqX1XK%2F2GayzjGV29wN%2BvNLx4nWZSnZdyoTl%2FBV2cdJUH8VCK4WTVtvehIPL5zwBOrz9rpkeBrUsKeFTRBRxibF9WdO1Mv%2FCNVKo3rGI9knIMN2pVNba7jGdrfc5LZdhx%2FMK21TRajVlqT1jvIrfq12H5f2ITIZU3WJUs96J5%2FjDM9Z9mNNo8iIcBOdIePTJr0SmX0T%2BmvVBt0Sk2eIzdoXNBibzmh2%2Bi53OCMt%2BcSjGYZ%2FaOecuZdpbDosziSzJCche1CeLFUh6h%2Bj1GsQ18GsfsLexszCpN3746SP%2FgSRTUOUSvWTjqHhBBD8%2BcpNUPpUJodoRZAl9kzd3MZwXr6Lg0VARa%2Fyp3KETS0qzJP%2ByLSKtuhiEiaY4l2O1CyNZh6NDvmW7dqjE%2FfyMdinMK7wvrwGOrIBP%2F8KB3eH%2FqC5u0NczMsjXgsokM5Uzr0cZw4sVB3XbWIxTrY9n6H%2BmIyaVufO7M0hQPXQ3ieYm09TocX00G%2BfRMPBFaapKAWgYrx%2FBTtapDgOq%2F0r5r8%2FJMKRfqElMwZLOJPuHN35Y7QKd85p5p8cNWDe3x%2By0hQgrZOm59R2vrnZQtoorahSwJrxU5VTu9Omnun4MrxzmwusSK3kH1eT75lAfSQlB2GPnzBUVaFR9JXZNA%3D%3D&Expires=1737476660"




  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      console.log(nodeRenderDetails)
      try {
        const userId = parseInt(localStorage.getItem("userID") || "-1");

        const projectResponse = await listProjectsAndOrgs(userId);
        console.log("projectResponse");
        console.log(projectResponse);
        const response = await getSketchFab('car');
        setsketchFabObjects(response.results)

        if (projectResponse.orgsList.length === 0 || projectResponse.data.length === 0) {
          throw new Error("Empty data array received from API");
        }

        setList(projectResponse.orgsList);
        const projectIDs = projectResponse.data.flatMap((project) =>
          project.projectSections.map((section) => section.ProjectID)
        );

        if (projectIDs.length === 0) {
          throw new Error("No project section IDs found");
        }

        const storedOrgId = localStorage.getItem("selectedOrgId");
        if (storedOrgId) {
          const orgId = parseInt(storedOrgId, 10);
          setSelectedOrgId(orgId);

          const filteredProjectsData = projectResponse.data.filter(
            (project) => project.OrganizationID === orgId
          );

          if (filteredProjectsData.length === 0) {
            throw new Error("No projects found for the selected organization");
          }

          const projectID = filteredProjectsData[0].projectSections[0].ProjectID;

          const ObjsResponse = await GetObjectAndTags(projectID);
          setobjects(ObjsResponse.data.objects);
          setTagsData(ObjsResponse.data.tags);
          setObjresponse(ObjsResponse.data);
          console.log(ObjsResponse.data.tags);

          setFilteredobjects(ObjsResponse.data.objects);
          setSearchFilteredobjects(ObjsResponse.data.objects);
        } else {
          const projectID = projectIDs[0];
          const ObjsResponse = await GetObjectAndTags(projectID);
          setobjects(ObjsResponse.data.objects);
          setTagsData(ObjsResponse.data.tags);
          setObjresponse(ObjsResponse.data);
          console.log(ObjsResponse.data.tags);

          setFilteredobjects(ObjsResponse.data.objects);
          setSearchFilteredobjects(ObjsResponse.data.objects);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load projects.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoading, setList, setobjects, setTagsData, setFilteredobjects, setSearchFilteredobjects, setSelectedOrgId, setObjresponse]);

  useEffect(() => {
    console.log(sketchFabObjectsURL)
    if (sketchFabObjectsURL.length != 0) {
      setSketchfabGLB(true);
    } else {
      setSketchfabGLB(false);
    }
  }, [sketchFabObjectsURL])


  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <SceneContextProvider>
        <Canvas onCreated={() => setIsLoading(false)}>
          <Suspense fallback={null}>
            <HdrEnvironment hdrPath={hdrPath} />
          </Suspense>
          <color attach="background" args={["#404040"]} />
          <CameraSetup />
          <directionalLight position={[2.5, 8, 5]} ></directionalLight>
          <ambientLight intensity={0.5} />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
          <pointLight position={[-10, -10, -10]} />
          <CameraControls />
          <KeyboardControls />
          <TextPanel
            nodeRenderDetails={nodeRenderDetails}
            onDragPanel={(position) => {
              setpanelPosition(position);
            }}
          />
          {nodeRenderDetails?.threeDfile && Array.isArray(nodeRenderDetails.threeDfile) && (
            <Suspense fallback={<LoaderCube position={[0, 0, 0]} />}>
              {nodeRenderDetails.threeDfile.map((item, index) => (
                <GLBModelViewer key={index} url={item} />
              ))}
            </Suspense>
          )}
          {/* <GLBModelViewer url={GLBLink} /> */}
          <Suspense fallback={<LoaderCube position={[0, 0, 0]} />}>
            <GLBModelViewer url={GLBLink} />
          </Suspense>
          {sketchFabObjectsURL && Array.isArray(sketchFabObjectsURL) && (
            <Suspense fallback={<LoaderCube position={[0, 0, 0]} />}>
              {sketchFabObjectsURL.map((item, index) => {
                return (
                  <group key={index} scale={[1, 1, 1]}>
                    <GLBModelViewer url={item} />
                  </group>
                );
              })}
            </Suspense>
          )}



          <DottedBezierLine
            start={cubePosition}
            end={panelPosition.add(new Vector3(0, -0.5, 0))}
            lineWidth={2}
            color="white"
            dashed
            dashSize={0.02}
            gapSize={0.02}
          />
          <Suspense fallback={null}>
            <Physics>
              <GridGround />
            </Physics>
          </Suspense>
          {/* <RaycastManager /> */}
          {spaceobjects && (spaceobjects?.spaceObjects.map((spaceObject: SpaceObject) => (<Suspense fallback={<LoaderCube position={[0, 0, 0]} />}><GLBModelViewerSpaceObject SpaceObject={spaceObject} /></Suspense>)))}
          <SceneExtractor onChange={setSceneData} />
          <ObjectSpawner />
        </Canvas>
        <HorizontalScrollableList sketchFabObject={sketchFabObjects} sketchFab={true} objects={objects} />
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
          <HierarchyView data={sceneData} />
        </div>
      </SceneContextProvider>
    </div>
  );
};