import { TransformMatrix } from "./basicTypes";

export interface MediaFile {
    SectionObjectID: number;
    MediaFileID: number;
    MediaFileName: string;
    MediaFileDesc: string;
    ContentTypeID: number;
    MediaFilePath: string;
    MediaFileSize: string;
    DisplayOrder: number | null;
    MediaFilesSourceID: number;
    MediaFilesSourceType: string;
    MediaFilePropertyBag: string;
    MediaFileStatus: number | null;
    CreatedOn: string;
    UpdatedOn: string;
    SourceID: number;
    SourceType: string;
    DisplayTemplateID: DisplayTemplate;
    DisplayTemplateName: string;
    DisplayTemplateCategory: number;
    TextContent: string | null;
    TextDataID: number | null;
    RoleID: number;
    roles: any[];
    Annotations: AnnotationDetails[];
}

export interface Connector {
    ConnectorID: number;
    ConnectorName: string;
    ConnectorFromNode: number;
    ConnectorToNode: number;
    AnnotationID: number | null;
    CreatedOn: string;
    UpdatedOn: string;
    mediaFiles: MediaFile[];
}

export interface RolePlaySectionObject {
    TrainingSectionName: string;
    GSSOMapID: number | null;
    SectionObjectID: number;
    GuidesStepID: number | null;
    GSSOMapCreatedOn: string | null;
    GSSOMapUpdatedOn: string | null;
    SectionObjectName: string;
    SectionObjectSourceID: number;
    SectionObjectSourceType: string;
    SectionObjectStatus: number;
    Thumbnail: string;
    DisplayOrder: number;
    SectionGroupID: number;
    TourViewAngle: number;
    EditorLocation: string;
    WorldLocation: string | null;
    SOTypeID: SOTypeID;
    CreatedOn: string;
    UpdatedOn: string;
    Location: string;
    NodeWorldLocation: string | null;
    mediaFiles: MediaFile[];
}

export interface Experience {
    ExperienceID: number;
    ExperienceName: string;
    VoiceTypeID: number;
    rolePlaySectionObjects: RolePlaySectionObject[];
    connectors: Connector[];
}

export interface ExperienceResponseData {
    responseData: {
        MAIN_TSID: string;
        Experiences: Experience[];
    };
    UpdatedOn: string;
    message: string;
}


    export enum SOTypeID {
        StartNode = 1,
        StepNode = 2,
        ChoiceNode = 3,
        LinkNode = 4,
        Randomizer = 8
    }

export enum DisplayTemplate {
    // DT for images
    // Image = 1,
    // DT for text
    // Text = 2,
    // DT for video
    // Video = 3,
    // DT for Pano
    // Pano = 4,
    // DT for object models
    // Model3D = 5,
    // DT for series of slides
    // Slides = 6,
    // DT for MobileApp Screens
    // MobileAppIPhone6 = 7,
    // DT for MobileApp Screens
    // MobileAppIPhone8 = 8,
    // DT for MobileApp Screens
    // MobileAppIPhoneXR = 9,
    // DT for MobileApp Screens
    // MobileAppAppleWatch = 10,
    // DT for MobileApp Screens
    // MobileAppIPad = 11,
    // DT for MobileApp Screens
    // MobileAppGalaxyS9 = 12,
    // DT for MobileApp Screens
    // MobileAppGalaxyTabA = 13,
    // DT for MobileApp Screens
    // MobileAppGalaxyWatch = 14,
    // Empty DT for Link Annotations
    // LinkEmpty = 15,
    // DT for an InformationPanel.
    RolePlayInformation = 16,
    // DT for dialogpanel contains Header, Title, Text Panel and a Button.
    RolePlayDialogPanel = 17,
    // DT for Choice contains Header, Title and variable number of buttons.
    RolePlayChoice = 18,
    // DT for displayimage will have an image of the actor.
    RolePlayDisplayImage = 19,
    // DT for audio will have an audio file.
    RolePlayAudio = 20,
    // DT for adding hints, contains a header, title and text panel.
    RolePlayHint = 21,
    // DT for TrainingForms that have questions and a checkbox for the answer.
    // TrainingFormCheckBoxes = 22,
    // DT for TrainingForms that have questions and a textbox for the answer.
    // TrainingFormTextInput = 23,
    // DT for text panel that need a button.
    RolePlayTextPanel02 = 24,
    // DT for nodes that require user interactions.
    RolePlayTask = 25,
    // DT to display a node at the desired location in the world.
    RolePlayWorldLocation = 26,
    // DT for animations.
    RolePlayAnimation = 27,
    // DT for ImageGallery Type01.
    // ImageGallery01 = 28,
    // DT for VideoGallery Type01.
    // VideoGallery01 = 29,
    // DT for Contacts Type01.
    // Contacts01 = 30,
    // DT for Hotspots-Type01
    // Flat = 31,
    // DT for Hotspots-Type02
    // FlatUp = 32,
    // DT for Hotspots-Type03
    // FlatTopRight = 33,
    // DT for Hotspots-Type04
    // FlatRight = 34,
    // DT for Hotspots-Type05
    // FlatBottomRight = 35,
    // DT for Hotspots-Type06
    // FlatDown = 36,
    // DT for Hotspots-Type07
    // FlatBottomLeft = 37,
    // DT for Hotspots-Type08
    // FlatLeft = 38,
    // DT for Hotspots-Type09
    // FlatTopLeft = 39,
    // DT for Hotspots-Type10
    // CircleUp = 40,
    // DT for Hotspots-Type11
    // CircleTopRight = 41,
    // DT for Hotspots-Type12
    // CircleRight = 42,
    // DT for Hotspots-Type13
    // CircleDown = 43,
    // DT for Hotspots-Type14
    // CircleBottomLeft = 44,
    // DT for Hotspots-Type15
    // CircleLeft = 45,
    // DT for Hotspots-Type16
    // CircleTopLeft = 46,
    // DT for Hotspots-Type17
    // HotspotAnimate = 47,
    // DT for Hotspots-Type18
    // Reveal = 48,
    // DT for Hotspots-Type19
    // CircleBottomRight = 49,
    // DT for video will have video file.
    RolePlayVideo = 50,
    // DT for pdf will have pdf file.
    RolePlayPDF = 51,
    // DT for step by step.
    RolePlayStepByStep = 52,
    // DT for step by step animation
    SBYSAnimation = 53,
    // DT for connector animation
    ConnectorAnimation = 54,
    // DT for Node World Location
    NodeWorldLocation = 55,
    // DT for Audio enabled text
    AudioText = 56,
    // DT for JSON data
    // JSONData = 57,
    // DT for 3D models
     SBYS3DModel = 58,
    // DT for UI Controls
    // UIControl = 59,
    // DT for Linked Nodes
    LinkedNode = 60,
}

export interface AudioTextTextData {
    DisplayText: string;
}

export interface LinkedNodeData {
    TrainingSectionID:string;
}

export interface RolePlayStepByStepTextData {
    TargetObject: string;
    EventType: number;
    ColliderName: string;
    ColliderTypeID: number;
    SnapToPosition: string;
    SuccessRotation: string;
    Title: string;
    transformmatrix: TransformMatrix;
  }


  export interface Choice {
    AnnotationName: string;
    Choicetext: string;
  }
  
  export interface RolePlayChoiceTextData {
    HeaderText: string;
    QuestionText: string;
    Choices: Choice[];
  }

  export interface AnnotationDetails {
    SectionObjectID: number;
    AnnotationID: number;
    AnnotationName: string;
    SourceMediaFileID: number;
    AnnotationFlagTypeID: number;
    TriggerType: string;
    BehaviorType: string;
    LinkID: number;
    X_Coordinate: number;
    Y_Coordinate: number;
    Z_Coordinate: number;
    EditorSize: number | null;
    GameObject: any | null; // Replace 'any' with a more specific type if possible
    DisplayTemplateID: number;
    AnnotationLabel: string | null;
    CreatedOn: string;
    UpdatedOn: string;
    MediaFileID: number;
    SectionObjectLinkID: number;
    Size: number | null;
    TextContent: string;
  }
  

  


export function findNextNode(rolePlaySectionObjects: RolePlaySectionObject[], currentNode: RolePlaySectionObject) {

    if (!currentNode) {
        console.error('Current object not found');
        return null;
    }

    // Use the DisplayOrder of the current object to find the next object
    const nextNode = rolePlaySectionObjects.find(
        obj => obj.SectionObjectID === currentNode.DisplayOrder
    );

    if (!nextNode) {
        console.error('next object not found');
        return null;
    }

    return nextNode;
}

export function findNodeByID(rolePlaySectionObjects: RolePlaySectionObject[], SectionObjectLinkID:number) {

    // Use the DisplayOrder of the current object to find the next object
    const nextNode = rolePlaySectionObjects.find(
        obj => obj.SectionObjectID === SectionObjectLinkID
    );

    if (!nextNode) {
        console.error('next object not found');
        return null;
    }

    return nextNode;
}

export function findExperienceByExperienceID(Experiences: Experience[], experienceID: number) {
    
    const experience = Experiences.find(
        (exp: Experience) => Number(exp.ExperienceID) === experienceID
    );
       
    return experience;
}


export function findMediaFileByDisplayTemplateID(mediaFiles: MediaFile[], displayTemplateID: DisplayTemplate) {
    // Find the media file that matches the given DisplayTemplateID
    const mediaFile = mediaFiles.find(
        file => file.DisplayTemplateID === displayTemplateID
    );

    return mediaFile;
}

export function findMediaFileByDisplayTemplateIDFor3D(mediaFiles: MediaFile[], displayTemplateID: DisplayTemplate) {
    // Find the media file that matches the given DisplayTemplateID
    const mediaFile = mediaFiles.filter(
        file => file.DisplayTemplateID === displayTemplateID
    );

    return mediaFile;
}



export function getAudioTextTextData(mediaFile: MediaFile) {
    if (!mediaFile)
        return mediaFile
    const AudioTextTextData: AudioTextTextData = JSON.parse(mediaFile.TextContent || "{}");
    return AudioTextTextData;

}

export function getRolePlayStepByStepTextData(mediaFile: MediaFile) {
    if (!mediaFile)
        return mediaFile
    const RolePlayStepByStepTextData: RolePlayStepByStepTextData = JSON.parse(mediaFile.TextContent || "{}");
    return RolePlayStepByStepTextData;
}


export function getLinkedNodeData(mediaFile: MediaFile) {
    if (!mediaFile)
        return mediaFile
    const LinkedNodeData: LinkedNodeData = JSON.parse(mediaFile.MediaFilePath || "{}");
    return LinkedNodeData;

}


export function getStartNodeForExperience(experience:Experience){
    const startNode= experience?.rolePlaySectionObjects.find(
        (section) => section.SOTypeID === SOTypeID.StartNode
      );

     return startNode; 
}

export function getRolePlayChoiceTextData(mediaFile: MediaFile) {
    if (!mediaFile)
        return mediaFile
    const RolePlayChoiceTextData: RolePlayChoiceTextData = JSON.parse(mediaFile.TextContent || "{}");
    return RolePlayChoiceTextData;

}


export function getMediaFilePath(mediaFile: MediaFile) {
    if (!mediaFile)
        return ""
 return mediaFile.MediaFilePath;
}