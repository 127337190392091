import apiClient  from "./apiClient";
import {
  AudioData,
  SpaceData,
  CreateObjectData,
  UpdateObjectData,
} from "../types/apitypes";
import axios from "axios";
export const getExperienceList = async (projectID: number) => {
  try {
    const response = await apiClient.get(
      `/training/getAllExperiencesByProjectID/${projectID}`
    );
    // You can handle the response further, e.g., storing the user token
    console.log("getExperienceList coming", response.data);

    return response.data;
  } catch (error) {
    console.log("getExperienceList not coming", error);
    throw error;
  }
};

export const getExperienceData = async (experienceID: number) => {
  try {
    const response = await apiClient.get(
      `/training/product/${experienceID}/getSectionObjectsConnectors/v4`
    );
    // You can handle the response further, e.g., storing the user token
    console.log("hello", response);

    return response.data;
  } catch (error) {
    console.error(
      "Error during fetchAllDataByExperienceIDfetchAllDataByExperienceIDfetchAllDataByExperienceID-up:",
      error
    );
    throw error;
  }
};

export const getProcedureData = async (orgID: number) => {
  try {
    const response = await apiClient.get(`/prodigy/importListByOrg/${orgID}`);
    // You can handle the response further, e.g., storing the user token
    console.log("Prodigy Import list coming", response.data);

    return response.data;
  } catch (error) {
    console.log("Prodigy Import listttt not coming", error);
    throw error;
  }
};

export const importClientPDF = async (
  OrganizationID: string,
  file: File,
  fileName: string,
  importImage: string,
  prompT: string,
  spaceID:string
) => {
  try {
    // Prepare the form data
    const formData = new FormData();
    formData.append("OrganizationID", OrganizationID);
    formData.append("filename", file, fileName);
    formData.append("importimage", importImage);
    formData.append("spaceID" , spaceID);
    // Post the form data to the server
    const response = await apiClient.post(`/prodigy/importPDFByOrg/V1`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("importClientPDF coming", response.data);
    return response.data;
  } catch (error) {
    console.log("importClientPDF not coming", error);
    throw error;
  }
};

export const stepsfromVideo = async (video: File) => {
  try {
    const formData = new FormData()
    formData.append("video", video)
    // Post the form data to the server
    const response = await apiClient.post(`api/stepFromVideo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("video json steps coming", response.data);
    return response.data;

  }
  catch (error) {
    console.log("stepsfromVideo not coming", error);
    throw error;

  }
}
export const importExcel = async (projectID: number) => {
  try {
    const response = await apiClient.post(`/training/importExcel}`);
    // You can handle the response further, e.g., storing the user token
    console.log("importExcel coming", response.data);

    return response.data;
  } catch (error) {
    console.log("importExcel not coming", error);
    throw error;
  }
};
export const exportExcel = async (projectID: number) => {
  try {
    const response = await apiClient.post(`/training/exportExcel}`);
    // You can handle the response further, e.g., storing the user token
    console.log("exportExcel coming", response.data);

    return response.data;
  } catch (error) {
    console.log("exportExcel not coming", error);
    throw error;
  }
};
export const uploadImage = async (pdfImage: File) => {
  const formData = new FormData();
  formData.append('pdfImage', pdfImage); // Append the image file to FormData with the key 'file'

  try {
    const response = await apiClient.post('/training/uploadPdfImage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Axios will automatically set the Content-Type for multipart/form-data
      },
    });

    // Assuming the response contains the image URL in `imageUrl`
    if (response.data) {
      console.log("Image uploaded successfully:", response.data);
      return response.data; // Return the response data containing the image URL
    } else {
      console.error("Unexpected response format:", response);
      return null; // Return null or handle as necessary
    }
  } catch (error) {
    // Enhanced error handling
    if (error) {
      console.error("Axios error occurred:", error );
    } else {
      console.error("Unknown error occurred:", error);
    }
    throw error; // Re-throw the error to propagate it further
  }
};

export const getStepsfromProcedure = async (projectID: number) => {
  try {
    const response = await apiClient.get(`/training/getStepData/${projectID}`);
    // You can handle the response further, e.g., storing the user token
    console.log("getStepsfromProcedure coming", response.data);

    return response.data;
  } catch (error) {
    console.log("getStepsfromProcedure not coming", error);
    throw error;
  }
};

export const deleteSourceForImports = async (projectID: number) => {
  try {
    const response = await apiClient.delete(
      `/training/deleteSourceForImports/${projectID}`
    );
    // You can handle the response further, e.g., storing the user token
    console.log("Delete this list", response.data);

    return response.data;
  } catch (error) {
    console.log("Not deleting", error);
    throw error;
  }
};

export const CreateProduct = async (
  ProductName: String,
  ProductDesc: String,
  OrgID: number
) => {
  const productDetails = {
    ProductName: ProductName,
    ProductDesc: ProductDesc,
    OrganizationID: OrgID,
  };
  try {
    const response = await apiClient.post(
      `/training/${OrgID}/product/new`,
      productDetails
    );
    // You can handle the response further, e.g., storing the user token
    console.log("Prodigy Import list coming", response.data);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const CreateExperience = async (
  ProjectID: Number,
  orgid: number,
  TrainingSectionName: String,
  TrainingSectionDesc: String
) => {
  const body = {
    OrganizationID: orgid,
    TrainingSectionName: TrainingSectionName,
    TrainingSectionDesc: TrainingSectionDesc,
  };
  try {
    const response = await apiClient.post(
      `/training/product/${ProjectID}/trainingSection/new`,
      body,
      {
        headers: {
          source: "aibot",
        },
      }
    );
    // You can handle the response further, e.g., storing the user token
    console.log("Prodigy Import list coming", response.data);

    return response.data;
  } catch (error) {
    console.log("Prodigy Import listttt not coming", error);
    throw error;
  }
};

export const CreateStepNode = async (
  projectID: number,
  newStepNodeArray: any
) => {
  const body = newStepNodeArray;
  try {
    const response = await apiClient.post(
      `/training/product/${projectID}/rolePlayObject/new?token=${localStorage.getItem('token')}`,
      body,
      {
        headers: {
          source: "aibot",
        },
      }
    );
    // You can handle the response further, e.g., storing the user token
    console.log("CreateStepNode", response.data);

    return response.data;
  } catch (error) {
    console.log("CreateStepNode error", error);
    throw error;
  }
};

export const CreateChoiceNode = async (
  projectID: number,
  newStepNodeArray: any
) => {
  const body = newStepNodeArray;
  try {
    const response = await apiClient.post(
      `/training/product/${projectID}/rolePlayObject/new`,
      body,
      {
        headers: {
          source: "aibot",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("CreateStepNode error", error);
    throw error;
  }
};
export const CreateProjectandSpace = async (CPAS: any) => {
  const body = CPAS;
  try {
    const response = await apiClient.post(
      `/project/createProjectAndSpace`,
      body
    );
    // You can handle the response further, e.g., storing the user token
    console.log("Project and space last api", response.data);

    return response.data;
  } catch (error) {
    console.log();
    throw error;
  }
};

export const LinkStepNodes = async (id: Number, nodes: any) => {
  try {
    const response = await apiClient.post(
      `/training/product/${id}/rolePlayObject/linkNode`,
      nodes,
      {
        headers: {
          source: "aibot",
        },
      }
    );
    // You can handle the response further, e.g., storing the user token
    console.log("linknodes last api", response.data);

    return response.data;
  } catch (error) {
    console.log();
    throw error;
  }
};

export const exportToExperience = async (stepsjson: any) => {
  try {
    const response = await apiClient.post(
      '/api/TrainingJsonToExcel',
      stepsjson,
      {
        headers: {
          'Content-Type': 'application/json', // Specify JSON content type
        },
      }
    );
    console.log('UpdatedStepNodes', response.data);

    return response.data;
  } catch (error) {
    console.error('Error exporting experience:', error);
    throw error;
  }
};


export const UpdateStepNodes = async (
  id: Number | null,
  sectionObjectID: any,
  fulljson: any,
  source?: any
) => {
  try {
    let response
    console.log("source", source)
    if(source === 'aibot'){
      console.log("source", source)

      response = await apiClient.post(
        `/training/product/${id}/rolePlayObject/${fulljson.SectionObjectID}/updateRolePlayObject`,
        fulljson,
        {
          headers: {
            source: "aibot",
          },
        }
      );
    }else{
      response = await apiClient.post(`/training/product/${id}/rolePlayObject/${fulljson.SectionObjectID}/updateRolePlayObject`,fulljson);
    }
    // You can handle the response further, e.g., storing the user token
    console.log("UpdatedStepNodes", response.data);

    return response.data;
  } catch (error) {
    console.log();
    throw error;
  }
};

export const UpdateLocation = async (locationData: any) => {
  try {
    const response = await apiClient.post(
      `/training/sectionObject/updateLocation`,
      { locationData: locationData }
    );
    console.log("updated location", response);
    return response.data;
  } catch (error) {
    console.log();
    throw error;
  }
};

export const DeleteExperience = async (projectID: number) => {
  try {
    const response = await apiClient.delete(
      `/training/section/${projectID}/deleteTrainingSection`
    );
  } catch (error) {
    console.log();
    throw error;
  }
};
export const DeleteProduct = async (productID: number) => {
  try {
    const response = await apiClient.delete(
      `/training/section/${productID}/deleteProduct`
    );
  } catch (error) {
    console.log();
    throw error;
  }
};

export const LinkChoiceNodes = async (id: number | null, nodes: any) => {
  try {
    const response = await apiClient.post(
      `/training/product/${id}/rolePlayObject/linkNode`,
      nodes
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const DelinkStepNodes = async (id: number, nodes: any) => {
  try {
    const response = await apiClient.post(
      `/training/product/${id}/rolePlayObject/deLinkNode`,
      {
        headers: {
          source: "aibot",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const DelinkChoiceNodes = async (id: number, nodes: any) => {
  try {
    const response = await apiClient.delete(
      `/training/product/${id}/rolePlayObject/deLinkNode`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const DeleteAllNode = async (expid: number, nodeID: number) => {
  try {
    const response = await apiClient.delete(
      `/training/section/${expid}/deleteNode/${nodeID}`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Node not deleted!");
  }
};

export const GenerateAudio = async (audio: AudioData) => {
  try {
    const response = await apiClient.post(
      `/congnitiveservice/convertTextToSpeech`,
      audio
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Audio not generated");
  }
};

export const CreateSpace = async (spaceData: SpaceData) => {
  try {
    const response = await apiClient.post(
      `/project/space/create/v1`,
      spaceData
    );
    console.log("response createspace");
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Space not generated");
  }
};

export const GetObjectsDetails = async (objectID: number) => {
  try {
    const response = await apiClient.get(
      `/objects/${objectID}/getObjectDetailsByID`
    );
    console.log("get objs details");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("objects details not fetched");
  }
};
export const GetObjectAndTags = async (projectID: number) => {
  try {
    const response = await apiClient.get(
      `/objects/${projectID}/getobjectandtags`
    );
    console.log("get objs");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("objects not fetched");
  }
};
export const ListObjectsByTags = async (projectID: number) => {
  try {
    const response = await apiClient.get(
      `/objects/${projectID}/listobjectsbytags`
    );
    console.log("get objs list and tags");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("objects list and tags not fetched");
  }
};

export const CreateObjects = async (formData: FormData) => {
  try {
    const response = await apiClient.post(`/objects/createObject/v1`, formData);
    console.log("Create Objs");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("objects not created fetched");
  }
};

export const updateObject = async (updateObjects: FormData) => {
  try {
    const response = await apiClient.post(
      `/objects/updateObject/v1`,
      updateObjects
    );
    console.log("updateObject Objs");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("objects not update fetched");
  }
};

export const SectionIDresponse = async (projectId: number, body: any) => {
  try {
    const response = await apiClient.post(
      `project/${projectId}/section/5/createNewSection`,
      body
    );
    console.log("SectionIDresponse");
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Sectionid ");
  }
};

export const MakingSectionActive = async (projectSectionId: number, body: any) => {
  try {
    const response = await apiClient.post(
      `/project/projectsection/${projectSectionId}/statusToggle`,
      body
    );
    console.log("SectionIDresponse");
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Sectionid ");
  }
};

// get step data for prodigy 
export const GetStepData = async (ExperienceId: number | undefined) => {
  try {
    const response = await apiClient.get(
      `training/getStepData/${ExperienceId}`);
    console.log("SectionIDresponse");
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Sectionid ");
  }
};

// get step data for prodigy 
export const addConnectionDetails = async (traineSectionID: number | undefined | null, body: any) => {
  try {
    const response = await apiClient.post(
      `training/${traineSectionID}/addConnectorDetails`, body);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Sectionid ");
  }
};




export const uploadImageProdigy = async (body: any) => {
  try {
    const response = await apiClient.post(
      `training/uploadPdfImage`, body);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Sectionid ");
  }
};


export async function getCopilotResponseFromTextInput(body:any) {
  try {
    const response = await apiClient.post("https://altourafrontlinecopilotdev.azurewebsites.net/api/getCopilotResponseFromTextInput", body); // Adjust the URL if needed
    console.log("Response:", response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error details:", {
        message: error.message,
        code: error.code,
        config: error.config,
        request: error.request,
        response: error.response ? error.response.data : null,
      });

      if (error.code === "ERR_NETWORK") {
        console.error("Network error: Please check your connection or the server status.");
      } else if (error.response?.status === 401) {
        console.error("Authentication error: Token might be invalid or expired.");
      } else if (error.response?.status === 404) {
        console.error("Endpoint not found: Verify the URL.");
      } else {
        console.error("Server responded with an error:", error.response?.statusText || "Unknown error");
      }
    } else {
      console.error("Unexpected error:", error);
    }
    return null;
  }
}

export const importVideo = async (
  formData:FormData
) => {
  try {
    // Prepare the form data

    // Post the form data to the server
    const response = await apiClient.post(`/prodigy/importVideo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("importVideo coming", response.data);
    return response.data;
  } catch (error) {
    console.log("importVideo not coming", error);
    throw error;
  }
};

export const importList = async (
  spaceID:string
) => {
  try {
    // Prepare the form data

    // Post the form data to the server
    const response = await apiClient.get(`/prodigy/importList/${spaceID}`);
    console.log("importList coming", response.data);
    return response.data;
  } catch (error) {
    console.log("importList not coming", error);
    throw error;
  }
};