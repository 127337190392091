import React, { useState, useRef, useEffect, useCallback } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import localStorageService from "../../../../services/localStorageService";
import { useNavigate } from "react-router-dom";

interface CustomDropdownProps {
  list: any[];
  selectedOrgId: number | string;
  setSelectedOrgId: (id: number | string) => void;
  isDropdownDisabled: boolean;
  onOrgChange?: (id: number) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  list,
  selectedOrgId,
  setSelectedOrgId,
  isDropdownDisabled,
  onOrgChange,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOrgName, setSelectedOrgName] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  // Memoize onOrgChange to avoid unnecessary re-renders
  const memoizedOnOrgChange = useCallback(
    (id: number) => {
      if (onOrgChange) {
        onOrgChange(id);
      }
    },
    [onOrgChange]
  );

  useEffect(() => {
    // Check if the localStorage has values and use them
    const storedOrgId = localStorageService.getItem("selectedOrgId");
    const storedOrgName = localStorageService.getItem("selectedOrgName");
  
    if (storedOrgId && storedOrgName) {
      // If localStorage has stored values, set them
      if (storedOrgId !== selectedOrgId || storedOrgName !== selectedOrgName) {
        setSelectedOrgId(storedOrgId);
        setSelectedOrgName(storedOrgName);
      }
    } else {
      // If no localStorage data, check for an initial value in the list
      const initialOrg = list.find((org) => org.OrganizationID === selectedOrgId);
      if (initialOrg) {
        setSelectedOrgId(initialOrg.OrganizationID);
        setSelectedOrgName(initialOrg.OrganizationName);
      } else if (list.length > 0) {
        // Default to the first item if nothing is selected
        const defaultOrg = list[0];
        setSelectedOrgId(defaultOrg.OrganizationID);
        setSelectedOrgName(defaultOrg.OrganizationName);
        localStorageService.setItem("selectedOrgId", defaultOrg.OrganizationID.toString());
        localStorageService.setItem("selectedOrgName", defaultOrg.OrganizationName);
        memoizedOnOrgChange(defaultOrg.OrganizationID);
      }
    }
  }, [list, selectedOrgId, selectedOrgName, setSelectedOrgId, memoizedOnOrgChange]);
  

  const handleSelect = (id: number | string) => {
    if (id !== selectedOrgId) {
      setSelectedOrgId(id);
      const selectedOrg = list.find((org) => org.OrganizationID === id);
      if (selectedOrg) {
        setSelectedOrgName(selectedOrg.OrganizationName);
        localStorageService.setItem("selectedOrgId", id.toString());
        localStorageService.setItem(
          "selectedOrgName",
          selectedOrg.OrganizationName
        );
        memoizedOnOrgChange(Number(id));
      }
      navigate("/"); // Redirect to home page or a relevant route
      setIsDropdownOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      {isDropdownDisabled ? (
        <div className="text-white mr-3">
          {selectedOrgName || "No Organization Selected"}
        </div>
      ) : (
        <div>
          <button
            type="button"
            className="custom-select bg-[#1F1F1F] text-white border-none p-2 rounded pr-8 mr-3 w-full text-left flex items-center justify-between"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <span>{selectedOrgName || "Select an organization"}</span>
            <span className="pointer-events-none">
              {isDropdownOpen ? (
                <FaChevronUp className="w-3 h-3 text-white" />
              ) : (
                <FaChevronDown className="w-3 h-3 text-white" />
              )}
            </span>
          </button>
          {isDropdownOpen && (
            <ul className="absolute w-[20vh] ml-[-5vh] bg-white text-black mt-1.5 rounded shadow-lg max-h-90 overflow-auto z-10">
              {list.map((org) => (
                <li
                  key={org.OrganizationID}
                  className="p-2 cursor-pointer hover:bg-gray-100 hover:text-black"
                  onClick={() => handleSelect(org.OrganizationID)}
                >
                  {org.OrganizationName}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
