import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';

import "./canvas.css";
import { ComponentType, ConnectionType } from "./types";
import max from "../../logos/ico_maximize.svg";
import search from "../../logos/ico_search.svg";
import min from "../../logos/ico_minimize.svg";
import {
  AnnotationDetails,
  Experience,
  ExperienceResponseData,
  findExperienceByExperienceID,
  getStartNodeForExperience,
  RolePlaySectionObject,
  SOTypeID,
} from "../../types/experienceTypes";
// import choicesvg from "../../logos/Choice.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateChoiceNode,
  CreateStepNode,
  LinkStepNodes,
  UpdateLocation,
  DelinkStepNodes,
  DeleteAllNode,
  getExperienceData,
  addConnectionDetails,
  LinkChoiceNodes
} from "../../services/experienceService";

import { useDispatch } from "react-redux";
import { setCurrentNodeValue, setsectionObjectConnectorData, setselectedNodeConnection } from "../../redux/slices/counterSlice";
import { RootState } from "../../redux/store";
import choiceSvg from "../../images/ico_choice.svg"
import choiceOneSvg from "../../images/ico_copy_link 1.svg"
import { TbOvalVertical } from "react-icons/tb";
import { RxSquare } from "react-icons/rx";
// const navigator = 
import choiceNodeImage from "../../images/icons8-flow-chart-96.png"
import startNodeImage from "../../images/icons8-play-64.png"
import stepNodeImage from "../../images/icons8-web-design-100.png"
import draggingIconImage from "../../images/icons8-drag-100.png"



const content = localStorage.getItem("content");

interface CanvasProps {
  mainexp: Experience;
  onComponentClick: (id: string) => void;
  createNewNodeRenderDetails?: (type: string, id: string) => void;
}

interface MediaFile {
  SectionObjectID: number;
  MediaFileID: number;
  MediaFileName: string;
  ContentTypeID: number;
  MediaFilePath: string | null;
  TextContent?: {
    DisplayText?: string;
    TargetObject?: string;
    ColliderName?: string;
    SnapToPosition?: string;
    PanelObject?: string;
    TrainingObject?: string;
    Constraints?: any[];
    InstructionAnimationObject?: string;
  };
}

interface RolePlaySectionObjectss {
  SectionObjectName: string;
  WorldLocation: {
    OrientationBehavior: string;
    GameObject: string;
  };
  mediaFiles: MediaFile[];
}

interface Node {
  id: string;
  position: { x: number; y: number };
  size: { width: number; height: number };
}


interface ExperienceAPI {
  ExperienceID: number;
  ExperienceName: string;
  rolePlaySectionObjects: RolePlaySectionObjectss[];
}

interface Content {
  responseData: {
    Experiences: ExperienceAPI[];
  };
}
interface NodeRenderDetails {
  title: string;
  text: string;
  image?: string;
  audio?: string;
  video?: string;
  PageNo: number;
  options?: AnnotationDetails[];
}

interface Connection {
  startNotch: HTMLElement;
  endNotch: HTMLElement;
  path: SVGPathElement;
}

const DefaultConnection: React.FC = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ConnectionSlot">
      <g id="ConnectionSlot_2">
        <mask id="path-1-inside-1_1882_11220" fill="white">
          <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 2.4028e-07 6 0L6 6L6 12Z" />
        </mask>
        <path
          d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 2.4028e-07 6 0L6 6L6 12Z"
          fill="#E8EAED"
          stroke="#D6DAE1"
          strokeWidth="1.5"
          mask="url(#path-1-inside-1_1882_11220)"
        />
      </g>
    </g>
  </svg>
);

const Canvas: React.FC<CanvasProps> = ({
  mainexp,
  onComponentClick,
  createNewNodeRenderDetails,
}) => {
  const [nodes, setnodes] = useState<ComponentType[]>([]);
  const [connections, setConnections] = useState<ConnectionType[]>(() => {
    const savedConnections = localStorage.getItem("connections");
    return savedConnections ? JSON.parse(savedConnections) : [];
  });
  const [currentConnection, setCurrentConnection] = useState<{
    from: string | null;
    to: string | null;
  }>({ from: null, to: null });
  const [selectedNode, setSelectedNode] = useState<string | null>(null);
  const [movingConnection, setMovingConnection] = useState<{
    from: string;
    to: string;
  } | null>(null);
  const [movingEndpoint, setMovingEndpoint] = useState<{
    connection: ConnectionType;
    endpoint: "from" | "to";
  } | null>(null);
  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    visible: boolean;
    targetId: string | null;
    type: "component" | "connection" | null;
  }>({ x: 0, y: 0, visible: false, targetId: null, type: null });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [size, setSize] = useState({ width: 100660, height: 1000016 });
  const [history, setHistory] = useState<
    { nodes: ComponentType[]; connections: ConnectionType[] }[]
  >([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [searchInputVisible, setSearchInputVisible] = useState(false);
  const [searchText, setSearchText] = useState(""); // New state for search text
  const [isDragging, setIsDragging] = useState(false);
  const canvasRef = useRef<HTMLDivElement>(null);
  const [Experiences, setExperiences] = useState<Experience[]>([]);
  const [mainExperience, setMainExperience] = useState<Experience | undefined>(
    undefined
  );
  const dispatch = useDispatch();
  const [currentExperience, setCurrentExperience] = useState<
    Experience | undefined
  >(undefined);
  const [currentNode, setCurrentNode] = useState<
    RolePlaySectionObject | undefined
  >(undefined);
  const [newcurrentNode, setNewCurrentNode] = useState<any | undefined>(
    undefined
  );

  const [isDrawing, setIsDrawing] = useState(false);
  // const [isDragging, setIsDragging] = useState(false);
  const [dragElement, setDragElement] = useState<HTMLElement | null>(null);
  const [startNotch, setStartNotch] = useState<HTMLElement | null>(null);
  const [connectionsNode, setConnectionsNode] = useState<Connection[]>([]);
  const [selectedPathNode, setSelectedPathNode] = useState<SVGPathElement | null>(null);
  const [currentConnectionNode, setCurrentConnectionNode] = useState<{ from: string | null; to: string | null }>({ from: null, to: null });

  const divRef = useRef<HTMLDivElement>(null);  // Reference to the div element
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const dottedSvgRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();




  const defalutNodeRenderDetails = {
    title: "",
    text: "",
    image: "",
    audio: "https://altouraonyx.azureedge.net/altouradevcontainer/blank.mp3",
    video: "",
    PageNo: 0,
    options: [],
  };
  const [nodeRenderDetails, setNodeRenderDetails] = useState<NodeRenderDetails>(
    defalutNodeRenderDetails
  );
  const [roleplay, setRoleplay] = useState<
    RolePlaySectionObject[] | undefined
  >();
  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [completed, setCompleted] = useState(false);
  const [isPanelVisible, setIsPanelVisible] = useState(true); // Initially visible
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isMouseUp, setIsMouseUp] = useState(false);
  const [selectedStartNode, setselectedStartNode] = useState('');
  const [selectedEndNode, setselectedEndNode] = useState('');
  const [selectedPath, setSelectedPath] = useState<{
    from: string;
    to: string;
  } | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
  }, [isMouseDown, isMouseUp]);

  const { experienceID } = useParams();
  const experienceIDNumber = Number(experienceID) || -1; // Assuming the query param is '?myParam=value'

  const [newConnections, setNewConnections] = useState<ConnectionType[]>([]);
  const [delinkingConnection, setDelinkingConnection] = useState<{
    connection: ConnectionType;
    endpoint: "from" | "to";
  } | null>(null);
  const [draggedEndpoint, setDraggedEndpoint] = useState<{
    connection: ConnectionType;
    endpoint: "from" | "to";
    position: { x: number; y: number };
  } | null>(null);

  const expIDstring: string | undefined = experienceID;

  interface CanvasProps {
    mainexp: Experience;
  }

  const nodeRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [liveConnection, setLiveConnection] = useState<number[]>([]);

  const fetchExperiences = async () => {
    try {
      const response = await getExperienceData(experienceIDNumber); // Ensure expData is defined and correctly imported

      if (response.code === 200) {
        const responseData: ExperienceResponseData = response.data;
        const mainTsid = responseData.responseData.MAIN_TSID;
        const mainExperienceData = findExperienceByExperienceID(
          responseData.responseData.Experiences,
          Number(mainTsid)
        );

        setExperiences(responseData.responseData.Experiences);
        setMainExperience(mainExperienceData);

        setCurrentExperience(mainExperienceData);
        setRoleplay(mainExperience?.rolePlaySectionObjects);
        if (mainexp?.rolePlaySectionObjects) {
          const parsePosition = (locationString: any, index: number) => {
            let position = {
              x: 10 + (index % 4) * 220,
              y: 100 + Math.floor(index / 4) * 140,
            };
            if (locationString) {
              try {
                const location = JSON.parse(locationString);
                position = {
                  x: isNaN(parseFloat(location.x))
                    ? 50
                    : parseFloat(location.x),
                  y: isNaN(parseFloat(location.y))
                    ? 350
                    : parseFloat(location.y),
                };
              } catch (e) {
                console.error(
                  "Invalid JSON format for Location:",
                  locationString
                );
              }
            }
            return position;
          };

          const getTypeName = (soTypeID: number) => {
            const typeName = SOTypeID[soTypeID];
            return typeName ? typeName.replace("Node", "") : "Unknown type";
          };

          const adjustPosition = (position: any, index: number) => {
            const gap = mainexp.rolePlaySectionObjects.length > 5 ? 50 : 0;
            return {
              x: position.x + (index % 4) * gap,
              y: position.y + Math.floor(index / 4) * gap,
            };
          };
          const initialnodes = mainexp.rolePlaySectionObjects.map(
            (obj, index) => {
              const position = parsePosition(obj.Location, index);
              return {
                id: obj.SectionObjectID.toString(),
                position: position,
                size: { width: 200, height: 100 },
                type: getTypeName(obj.SOTypeID),
              };
            }
          );

          const initialConnections = mainexp.rolePlaySectionObjects
            .slice(1)
            .map((obj, index) => ({
              from: mainexp.rolePlaySectionObjects[
                index
              ].DisplayOrder.toString(),
              to: obj.DisplayOrder.toString(),
            }));

          setnodes(initialnodes);

          setConnections(initialConnections);

          console.log(response.data.responseData.Experiences[0].connectors)
          let connectionDetails = response.data.responseData.Experiences[0].connectors;
          let startNode
          let endNode
          let nodeArray: any[] = []

          connectionDetails.map((items: any) => {
            console.log(typeof items.ConnectorFromNode, items.ConnectorToNode.toString())
            startNode = items.ConnectorFromNode.toString()
            endNode = items.ConnectorToNode.toString()
            // setConnections([...connections, { from: selectedStartNode, to: id }])

            nodeArray.push({ from: startNode, to: endNode })
          })
          // Save initial nodes and connections to localStorage
          console.log(nodeArray)
          dispatch(setselectedNodeConnection(nodeArray))
          setConnections([...connections, ...nodeArray])

        }

        if (mainExperienceData) {
          const currentNodeData =
            getStartNodeForExperience(mainExperienceData);
          setCurrentNode(currentNodeData);
        }
      } else {
        console.error("Failed to fetch experiences:", response.message);
      }
    } catch (error) {
      console.error("Error fetching experiences:", error);
    }
  };

  useEffect(() => {
    fetchExperiences();
  }, []);

  const addComponent = (type: string) => {
    console.log('Add Node')
    console.log(type)
    console.log(content)
    if (type === "step node") {
      AddStepNode(expIDstring);
    }
    if (type === "choice node") {
      // AddChoiceNode(expIDstring);
    }
  };

  const handleDrag = (
    e: MouseEvent, node: ComponentType, nodeElement: HTMLDivElement | null, offSetX: number, offSetY: number, latestPosition: number[]) => {
    if (!nodeElement || !canvasRef.current) return;

    const boundingBox = canvasRef.current.getBoundingClientRect();
    const scrollOffsetX = canvasRef.current.scrollLeft;
    const scrollOffsetY = canvasRef.current.scrollTop;

    const newPosition = {
      x: (e.clientX - boundingBox.left + scrollOffsetX - offSetX) / zoom,
      y: (e.clientY - boundingBox.top + scrollOffsetY - offSetY) / zoom,
    };

    // Ensure the new position is within canvas boundaries
    newPosition.x = Math.max(0, Math.min(size.width - nodeElement.offsetWidth, newPosition.x));
    newPosition.y = Math.max(0, Math.min(size.height - nodeElement.offsetHeight, newPosition.y));

    setnodes((prev) =>
      prev?.map((comp) =>
        comp.id === node.id ? { ...comp, position: newPosition } : comp
      )
    );
  };


  const handleMouseDown = (e: React.MouseEvent, id: string, index: number) => {
    console.log("e", e)
    e.preventDefault();

    if (!nodeRefs.current[index]) return;

    const node = nodes.find((comp) => comp.id === id);

    if (!node) return;

    let boundingBox = nodeRefs.current[index]!.getBoundingClientRect();

    const offSetx = e.clientX - boundingBox.left;
    const offSety = e.clientY - boundingBox.top;

    const currentPositionX = boundingBox.x;
    const currentPositionY = boundingBox.y;

    let latestPosition = [currentPositionX, currentPositionY];
    const handleMouseMove = (e: MouseEvent) => {
      handleDrag(
        e,
        node,
        nodeRefs.current[index],
        offSetx,
        offSety,
        latestPosition
      );
    };

    const handleMouseUpOnNodes = async () => {
      console.log("Mouse Up")
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener(
        "mouseup",
        handleMouseUpOnNodes as unknown as EventListener
      );
      boundingBox = nodeRefs.current[index]!.getBoundingClientRect();
      const scrollOffset = {
        x: canvasRef.current?.scrollLeft || 0,
        y: canvasRef.current?.scrollTop || 0,
      };
      console.log(scrollOffset)

      if (
        currentPositionX != boundingBox.x ||
        currentPositionY != boundingBox.y
      ) {

        const locationData = [
          {
            position: JSON.stringify({
              x: scrollOffset.x + latestPosition[0],
              y: scrollOffset.y + latestPosition[1],
            }),
            SectionObjectID: id,
          },
        ];

        await UpdateLocations(locationData);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener(
      "mouseup",
      handleMouseUpOnNodes as unknown as EventListener
    );
  };

  const handleMouseUpOnNodes = useCallback(
    async (e: MouseEvent) => {
      if (!isMouseDown) return;

      if (isMouseUp) {

        const newComponentId = nodes.find((comp) => {
          const rect = canvasRef.current!.getBoundingClientRect();
          const componentLeft = comp.position.x * zoom + rect.left;
          const componentTop = comp.position.y * zoom + rect.top;
          const componentRight = componentLeft + comp.size.width * zoom;
          const componentBottom = componentTop + comp.size.height * zoom;
          return (
            e.clientX >= componentLeft &&
            e.clientX <= componentRight &&
            e.clientY >= componentTop &&
            e.clientY <= componentBottom
          );
        })?.id;
        if (draggedEndpoint) {
          if (newComponentId) {
            const updatedConnections = [
              ...connections,
              {
                ...draggedEndpoint.connection,
                [draggedEndpoint.endpoint]: newComponentId,
              },
            ];
            setConnections(updatedConnections);
            debouncedLinkStepNode(updatedConnections);

            // Extract x and y mouse positions
            const mouseX = e.clientX;
            const mouseY = e.clientY;

            // Create locationData object in the required format
            const locationData = {
              x: mouseX.toString(),
              y: mouseY.toString(),
            };
            // Convert locationData to JSON string
            const locationDataJson = JSON.stringify(locationData);
            const formData = new FormData();
            formData.append("locationData[0][position]", locationDataJson);
            formData.append("locationData[0][SectionObjectID]", newComponentId);

            await UpdateLocations(formData);
          } else {
            setConnections((prevConnections) => [
              ...prevConnections,
              draggedEndpoint.connection,
            ]);
          }

          setDraggedEndpoint(null);
        }
      }
    },
    [isMouseDown, draggedEndpoint, connections, zoom]
  );

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUpOnNodes);

    return () => {
      document.removeEventListener("mouseup", handleMouseUpOnNodes);
    };
  }, [handleMouseUpOnNodes]);

  const handleResizeMouseDown = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    const component = nodes.find((comp) => comp.id === id)!;
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = component.size.width;
    const startHeight = component.size.height;

    const handleMouseMove = (e: MouseEvent) => {
      const newWidth = Math.max(50, startWidth + (e.clientX - startX) / zoom);
      const newHeight = Math.max(50, startHeight + (e.clientY - startY) / zoom);
      const maxWidth = size.width - component.position.x;
      const maxHeight = size.height - component.position.y;

      setnodes((prev) =>
        prev?.map((comp) =>
          comp.id === id
            ? {
              ...comp,
              size: {
                width: Math.min(newWidth, maxWidth),
                height: Math.min(newHeight, maxHeight),
              },
            }
            : comp
        )
      );
    };

    const handleMouseUpOnNodes = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener(
        "mouseup",
        handleMouseUpOnNodes as unknown as EventListener
      );
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener(
      "mouseup",
      handleMouseUpOnNodes as unknown as EventListener
    );
  };

  const drawPath = (path: any) => {
    const svgNamespace = "http://www.w3.org/2000/svg";
    const svgElement = document.createElementNS(svgNamespace, "path");
    svgElement.setAttribute("d", path);
    svgElement.setAttribute("stroke", "black");
    svgElement.setAttribute("fill", "black");
    svgElement.setAttribute("stroke-width", "22");

    const container = document.getElementById("path-container");
    if (container) {
      container.appendChild(svgElement);
    } else {
    }

  };

  const handleNodeConnectorClick = async (
    id: string,
    isConnectionClick: boolean,
    index: number,
    e: any
  ) => {

    const div = e.currentTarget;
    const rect = div.getBoundingClientRect();
    const divx = rect.left + 12 + window.scrollX;
    const divy = rect.top + 8 + window.scrollY;




    const handleMouseMoveForEndpoint = (event: any) => {
      if (!(canvasRef && canvasRef.current))
        return;
      const boundingBox = canvasRef.current.getBoundingClientRect();
      const fromPos = {
        x: (divx - boundingBox.left) / zoom,
        y: (divy - boundingBox.top) / zoom,
      };
      const toPosition = {
        x: (event.clientX - boundingBox.left) / zoom,
        y: (event.clientY - boundingBox.top) / zoom,
      };
      setLiveConnection([fromPos.x, fromPos.y, toPosition.x, toPosition.y]);
    }



    document.addEventListener("mousemove", handleMouseMoveForEndpoint);



  };

  const handleComponentRightClick = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      visible: true,
      targetId: id,
      type: "component",
    });
  };

  const handleCurveClick = (from: string, to: string) => {
    setMovingConnection({ from, to });
    setConnections((prevConnections) =>
      prevConnections.filter((conn) => !(conn.from === from && conn.to === to))
    );
  };

  const handleCurveRightClick = (
    e: React.MouseEvent,
    from: string,
    to: string
  ) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      visible: true,
      targetId: `${from}-${to}`,
      type: "connection",
    });
  };

  const handleEndpointMouseDown = (
    e: React.MouseEvent,
    connection: ConnectionType,
    endpoint: "from" | "to"
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const initialPosition =
      endpoint === "from"
        ? connection.fromPosition || { x: 0, y: 0 }
        : connection.toPosition || { x: 0, y: 0 };

    setDraggedEndpoint({ connection, endpoint, position: initialPosition });
    setConnections((prevConnections) =>
      prevConnections.filter(
        (conn) => !(conn.from === connection.from && conn.to === connection.to)
      )
    );

    const handleMouseMoveForEndpoint = (event: any) => {
      setLiveConnection([connection.fromPosition?.x, connection.fromPosition?.y, event.clientX, event.clientY]);
    }


    if (endpoint === "from") {
      document.addEventListener("mousemove", handleMouseMoveForEndpoint);
    }
    else {

    }




  };

  const renderDraggedEndpoint = () => {
    if (draggedEndpoint) {
      const { connection, endpoint, position } = draggedEndpoint;
      const fromPos = nodes.find((c) => c.id === connection.from)!.position;
      const fromSize = nodes.find((c) => c.id === connection.from)!.size;
      const toPos = position;
      const toSize = { width: 0, height: 0 };

      return (
        <svg className="connections w-full h-full absolute top-0 left-0 pointer-events-none">
          <path
            d={generatePath(fromPos, fromSize, toPos, toSize)}
            stroke="red"
            strokeWidth={2}
            fill="none"
          />
        </svg>
      );
    }
    return null;
  };

  useEffect(() => {
    const handleMouseMove = (event: any) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const ConnectionSlotSVGLeft: React.FC<{ connection: ConnectionType }> = ({
    connection,
  }) => (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseDown={(e) => handleEndpointMouseDown(e, connection, "from")}
    >
      <g id="ConnectionSlot">
        <path
          id="ConnectionSlot_2"
          d="M7 1C6.21207 1 5.43185 1.15519 4.7039 1.45672C3.97595 1.75825 3.31451 2.20021 2.75736 2.75736C2.20021 3.31451 1.75825 3.97595 1.45672 4.7039C1.15519 5.43185 1 6.21207 1 7C1 7.78793 1.15519 8.56815 1.45672 9.2961C1.75825 10.0241 2.20021 10.6855 2.75736 11.2426C3.31451 11.7998 3.97595 12.2417 4.7039 12.5433C5.43185 12.8448 6.21207 13 7 13L7 7L7 1Z"
          fill="#1F1F1F"
          stroke="#1F1F1F"
        />
      </g>
    </svg>
  );

  const ConnectionSlotSVGRight: React.FC<{ connection: ConnectionType }> = ({
    connection,
  }) => (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseDown={(e) => handleEndpointMouseDown(e, connection, "to")}
    >
      <g id="ConnectionSlot">
        <path
          id="ConnectionSlot_2"
          d="M6 13C6.78793 13 7.56815 12.8448 8.2961 12.5433C9.02405 12.2417 9.68549 11.7998 10.2426 11.2426C10.7998 10.6855 11.2417 10.0241 11.5433 9.2961C11.8448 8.56815 12 7.78793 12 7C12 6.21207 11.8448 5.43185 11.5433 4.7039C11.2417 3.97595 10.7998 2.31451 10.2426 2.75736C9.68549 2.20021 9.02405 1.75825 8.2961 1.45672C7.56815 1.15519 6.78793 1 6 1L6 7L6 13Z"
          fill="#1F1F1F"
          stroke="#1F1F1F"
        />
      </g>
    </svg>
  );

  const generatePath = (
    fromPos: { x: number; y: number },
    fromSize: { width: number; height: number },
    toPos: { x: number; y: number },
    toSize: { width: number; height: number },
    color: string = "black"
  ) => {
    const fromX = fromPos.x + fromSize.width;
    const fromY = fromPos.y + fromSize.height / 2;
    const toX = toPos.x;
    const toY = toPos.y + toSize.height / 6;
    const dx = toX - fromX;
    const dy = toY - fromY;
    const controlPointOffset = 0.5 * Math.min(Math.abs(dx), Math.abs(dy));
    const path = `M${fromX},${fromY} C${fromX + controlPointOffset},${fromY} ${toX - controlPointOffset
      },${toY} ${toX},${toY}`;
    return path;
  };

  const clearSelected = async () => {
    try {
      const expIDString = experienceID; // Ensure experienceID is handled as a string
      if (expIDString === undefined) {
        console.error("experienceID is undefined");
        return;
      }
      const expID = parseInt(expIDString, 10); // Convert experienceID to number

      if (isNaN(expID)) {
        console.error("Invalid experienceID:", expIDString);
        return;
      }

      if (contextMenu.type === "component") {
        const nodeIDString = contextMenu.targetId; // Ensure contextMenu.targetId is handled as a string
        if (nodeIDString === null) {
          console.error("contextMenu.targetId is null");
          return;
        }
        const nodeID = parseInt(nodeIDString, 10); // Convert node ID to number

        if (isNaN(nodeID)) {
          console.error("Invalid node ID:", nodeIDString);
          return;
        }

        setHistory((prevHistory) => [
          ...prevHistory,
          { nodes: [...nodes], connections: [...connections] },
        ]);
        setnodes(nodes.filter((comp) => comp.id !== nodeIDString));
        setConnections(
          connections.filter(
            (conn) => conn.from !== nodeIDString && conn.to !== nodeIDString
          )
        );

        await DeleteAllNodes(expID, nodeID);
      } else if (contextMenu.type === "connection") {
        const [from, to] = contextMenu.targetId!.split("-");

        setHistory((prevHistory) => [
          ...prevHistory,
          { nodes: [...nodes], connections: [...connections] },
        ]);
        setConnections(
          connections.filter((conn) => !(conn.from === from && conn.to === to))
        );

        await DeleteAllNodes(expID, parseInt(contextMenu.targetId!, 10)); // Pass the composite ID for the connection

      }

      setContextMenu({ ...contextMenu, visible: false });
    } catch (error) {
      console.error("Error in clearSelected:", error);
    }
  };

  const hideContextMenu = () => {
    setContextMenu({ ...contextMenu, visible: false });
  };

  const handleOnSelectNode = (e: any, id: any) => {
    dispatch(setCurrentNodeValue(id))
  }

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (canvasRef.current?.requestFullscreen) {
        canvasRef.current.requestFullscreen();
      } else if (canvasRef.current?.requestFullscreen) {
        canvasRef.current.requestFullscreen();
      } else if (canvasRef.current?.requestFullscreen) {
        canvasRef.current.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };


  const handleResize = (e: any) => {
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = size.width;
    const startHeight = size.height;

    const handleMouseMove = (e: any) => {
      const newWidth = startWidth + (e.clientX - startX);
      const newHeight = 1200;
      // setSize({ width: newWidth, height: newHeight });
    };

    const handleMouseUpOnNodes = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener(
        "mouseup",
        handleMouseUpOnNodes as unknown as EventListener
      );
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener(
      "mouseup",
      handleMouseUpOnNodes as unknown as EventListener
    );
  };

  useEffect(() => {
    const handleUndo = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "z") {
        if (history.length > 0) {
          const lastState = history[history.length - 1];
          setnodes(lastState.nodes);
          setConnections(lastState.connections);
          setHistory(history.slice(0, -1));
        }
      }
    };

    document.addEventListener("keydown", handleUndo);
    return () => {
      document.removeEventListener("keydown", handleUndo);
    };
  }, [history]);

  useEffect(() => {
    document.addEventListener("click", hideContextMenu);
    return () => {
      document.removeEventListener("click", hideContextMenu);
    };
  }, []);

  // useEffect(() => {
  //   setnodes((prevnodes) =>
  //     prevnodes?.map((comp) => {
  //       let newX = comp.position.x;
  //       let newY = comp.position.y;

  //       if (newX + comp.size.width > size.width) {
  //         newX = size.width - comp.size.width;
  //       }
  //       if (newY + comp.size.height > size.height) {
  //         newY = size.height - comp.size.height;
  //       }

  //       return {
  //         ...comp,
  //         position: { x: newX, y: newY },
  //       };
  //     })
  //   );
  // }, [size]);

  useEffect(() => {
    const handleWindowResize = () => {
      // setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleZoomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZoom(parseFloat(e.target.value));
  };
  const handlePathClick = (from: string, to: string) => {
    setSelectedPath({ from, to });

  };

  const AddStepNode = async (expID: string | undefined) => {
    try {
      let body = {
        "NodeType": "StepNode",
        "DisplayText": "Display Text for ",
        "Title": "Title",
        "position": `{\"x\":500,\"y\":300}`
      }



      if (!expID) {
        throw new Error("expID is undefined");
      }

      const expIDNumber = parseInt(expID, 10);
      if (isNaN(expIDNumber)) {
        throw new Error("expID is not a valid number");
      }

      const CreateStepNodeResponse = await CreateStepNode(
        expIDNumber,
        body
      );

      if (CreateStepNodeResponse.code !== 200) {
        throw new Error("Failed to create step node");
      }


      navigate(0)
      return CreateStepNodeResponse.id;
    } catch (error) {
      console.error(error);
    }
  };



  //nex

  const AddChoiceNode = async (expID: string | undefined, content: Content) => {
    try {
      const rolePlaySectionObjects = content.responseData.Experiences.flatMap(
        (experience: ExperienceAPI) => experience.rolePlaySectionObjects
      );

      if (rolePlaySectionObjects.length === 0) {
        return;
      }

      const sectionObject = rolePlaySectionObjects[0]; // Take the first section object to create a single choice node

      const textMediaFile = sectionObject.mediaFiles.find(
        (file: MediaFile) => file.ContentTypeID === 5 && file.TextContent
      );
      const stepByStepMediaFile = sectionObject.mediaFiles.find(
        (file: MediaFile) =>
          file.ContentTypeID === 5 &&
          file.MediaFileName === "RolePlay - stepbystep"
      );
      const panelLocationMediaFile = sectionObject.mediaFiles.find(
        (file: MediaFile) =>
          file.ContentTypeID === 5 &&
          file.MediaFileName === "Node World Location"
      );

      const parseIfString = (content: any) => {
        if (typeof content === "string") {
          return JSON.parse(content);
        }
        return content || {};
      };

      const textContent = textMediaFile
        ? parseIfString(textMediaFile.TextContent)
        : {};
      const stepByStepContent = stepByStepMediaFile
        ? parseIfString(stepByStepMediaFile.TextContent)
        : {};
      const panelLocationContent = panelLocationMediaFile
        ? parseIfString(panelLocationMediaFile.TextContent)
        : {};

      const newChoiceNode = {
        NodeType: "ChoiceNode",
        HeaderText: textContent.HeaderText || "",
        QuestionText: textContent.Title || "",
        Choices: [
          { AnnotationName: "Choice1", Choicetext: "choice1" },
          { AnnotationName: "Choice2", Choicetext: "choice2" },
          { AnnotationName: "Choice3", Choicetext: "choice3" },
        ],
        NodeWorldLocation: {
          OrientationBehavior: sectionObject.WorldLocation
            ? sectionObject.WorldLocation.OrientationBehavior
            : "",
          GameObject: sectionObject.WorldLocation
            ? sectionObject.WorldLocation.GameObject
            : "",
        },
        positionX: 200,
        positionY: 300,
        PanelObject: panelLocationContent.PanelObject || "",
        PanelObjectTransformmatrix: panelLocationContent.transformmatrix || {
          position: { X: 0, Y: 0, Z: 0 },
          rotation: { X: 0, Y: 0, Z: 0 },
          scale: { X: 1, Y: 1, Z: 1 },
        },
      };


      if (!expID) {
        throw new Error("expID is undefined");
      }

      const expIDNumber = parseInt(expID, 10);
      if (isNaN(expIDNumber)) {
        throw new Error("expID is not a valid number");
      }

      const CreateChoiceNodeResponse = await CreateChoiceNode(
        expIDNumber,
        newChoiceNode
      );

      if (CreateChoiceNodeResponse.code !== 200) {
        throw new Error("Failed to create choice node");
      }

      return CreateChoiceNodeResponse.id;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const LinkStepNode = async (newConnections: ConnectionType[]) => {
    try {
      const expIDstring = experienceID;
      if (expIDstring === undefined) {
        throw new Error("experienceID is undefined");
      }
      const expID = parseInt(expIDstring, 10);
      if (isNaN(expID)) {
        throw new Error("expID is not a valid number");
      }

      // Iterate over the new connections and link nodes based on from and to IDs
      for (const connection of newConnections) {
        const fromComponent = nodes.find((comp) => comp.id === connection.from);
        const toComponent = nodes.find((comp) => comp.id === connection.to);

        if (
          !fromComponent ||
          !toComponent ||
          fromComponent.type !== "Step" ||
          toComponent.type !== "Step"
        )
          continue; // Skip if either component is not found or not a step node

        const linkjson = {
          type: "Section",
          from: fromComponent.id,
          to: toComponent.id,
        };

        const linknodes = await LinkStepNodes(expID, linkjson);

        if (linknodes.status < 200 || linknodes.status >= 300) {
          throw new Error(
            "Failed to link step nodes due to network or server issue."
          );
        }

        const LinkNodesdata = linknodes.data;
      }

      // Clear the new connections after processing
      setNewConnections([]);
    } catch (error) {
      console.error("Error in linking step nodes:", error);
    } finally {
    }
  };
  const useDebouncedLinkStepNode = (delay: number) => {
    const [pending, setPending] = useState(false);
    const timeoutRef = useRef<number | undefined>(undefined);

    const debouncedLinkStepNode = async (updatedConnections: any) => {
      if (pending) return;

      setPending(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(async () => {
        try {
          await LinkStepNode(updatedConnections); // Ensure this is the correct call
        } finally {
          setPending(false);
        }
      }, delay);
    };

    return debouncedLinkStepNode;
  };
  const debouncedLinkStepNode = useDebouncedLinkStepNode(300); // Adjust the delay as needed

  const handleMouseMove = (e: MouseEvent) => {
    if (draggedEndpoint) {
      const updatedPosition = {
        x: (e.clientX - canvasRef.current!.getBoundingClientRect().left) / zoom,
        y: (e.clientY - canvasRef.current!.getBoundingClientRect().top) / zoom,
      };

      setDraggedEndpoint(
        (prev) => prev && { ...prev, position: updatedPosition }
      );
    }
  };

  const DeLinkStepNode = async (newConnections: ConnectionType[]) => {
    try {
      const expIDstring = experienceID;
      if (expIDstring === undefined) {
        throw new Error("experienceID is undefined");
      }
      const expID = parseInt(expIDstring, 10);
      if (isNaN(expID)) {
        throw new Error("expID is not a valid number");
      }

      // Iterate over the new connections and link nodes based on from and to IDs
      for (const connection of newConnections) {
        const fromComponent = nodes.find((comp) => comp.id === connection.from);
        const toComponent = nodes.find((comp) => comp.id === connection.to);

        if (
          !fromComponent ||
          !toComponent ||
          fromComponent.type !== "Step" ||
          toComponent.type !== "Step"
        )
          continue; // Skip if either component is not found or not a step node

        const linkjson = {
          type: "Section",
          from: fromComponent.id,
          to: toComponent.id,
        };

        const delinknodes = await DelinkStepNodes(expID, linkjson);

        if (delinknodes.status < 200 || delinknodes.status >= 300) {
          throw new Error(
            "Failed to link step nodes due to network or server issue."
          );
        }

        const LinkNodesdata = delinknodes.data;
      }

      // Clear the new connections after processing
      setNewConnections([]);
    } catch (error) {
    } finally {
    }
  };
  const UpdateLocations = async (locationdata: any) => {
    try {
      const updatelocation = await UpdateLocation(locationdata);

      if (
        !updatelocation ||
        updatelocation.status < 200 ||
        updatelocation.status >= 300
      ) {
        throw new Error(
          "Failed to link step nodes due to network or server issue."
        );
      }
    } catch (error) {
      console.error("Error in UpdateLocations:", error);
    } finally {
    }
  };
  const DeleteAllNodes = async (expID: number, nodeID: number) => {
    try {
      const deletenoderesponse = await DeleteAllNode(expID, nodeID);
      if (deletenoderesponse.status < 200 || deletenoderesponse.status >= 300) {
        throw new Error(
          "Failed to create project and space due to network or server issue."
        );
      }
    } catch (error) {
      throw error;
    }
  };

  const renderConnectionSlots = (comp: ComponentType) => {
    const fromConnections = connections?.filter(
      (conn) => conn.from === comp.id
    );
    const toConnections = connections?.filter((conn) => conn.to === comp.id);

    return (
      <>
        {/* Left-side slots */}
        {toConnections?.map((conn, index) => (
          <div
            key={`${conn.from}-${conn.to}-${index}`}
            className="absolute left-[-6px] top-1/2"
            style={{ transform: "translateY(-50%)" }}
            onMouseDown={(e) => handleEndpointMouseDown(e, conn, "to")}
          >

          </div>
        ))}
        {Array.from({ length: 1 - toConnections.length }).map((_, index) => (
          <div
            key={`${comp.id}-default-left-${index}`}
            className="absolute left-[-6px] top-4 "
            style={{ transform: "translateY(-50%) scaleX(-1)", scale: "1.2" }}
            onClick={(e) => handleNodeConnectorClick(comp.id, true, index, e)}
          >
            <DefaultConnection />
          </div>
        ))}

        {/* Right-side slots */}
        {fromConnections?.map((conn, index) => (
          <div key={`${conn.from}-${conn.to}-${index}`}></div>
        ))}
        {Array.from({
          length: (comp.type === "Choice" ? 2 : 1) - fromConnections.length,
        }).map((_, index) => (
          <div id="path-container">
            <div
              key={`${comp.id}-default-right-${index}`}
              className="absolute right-[-6px] cursor-pointer scale-110"
              style={
                comp.type === "Choice"
                  ? {
                    top: `${60 + index * 25}px`,
                    transform: "translateY(-50%)",
                    scale: "1.2",
                  } // Adjust vertical position for each slot in choice node
                  : { top: "60%", transform: "translateY(-50%)", scale: "1.2" } // Center the slot for step node
              }
              onClick={(e) => handleNodeConnectorClick(comp.id, true, index, e)}
            >
              <DefaultConnection />
            </div>
          </div>
        ))}
      </>
    );
  };

  const svgRef = useRef<SVGSVGElement | null>(null);

  const getConnectorPath = (startX: number, startY: number, endX: number, endY: number) => {
    const deltaX = Math.abs(endX - startX) / 2;
    return `M${startX},${startY} C${startX + deltaX},${startY} ${endX - deltaX},${endY} ${endX},${endY}`;
  };

  const updateConnections = () => {

    // setConnections(connections)
    connectionsNode.forEach((conn) => {
      const startRect = conn.startNotch.getBoundingClientRect();
      const endRect = conn.endNotch.getBoundingClientRect();

      const startX = startRect.left + startRect.width / 2;
      const startY = startRect.top + startRect.height / 2;
      const endX = endRect.left + endRect.width / 2;
      const endY = endRect.top + endRect.height / 2;

      const d = getConnectorPath(startX, startY, endX, endY);
      conn.path.setAttribute('d', d);
    });
  };

  const createConnection = (startNotch: HTMLElement, endNotch: HTMLElement) => {
    console.log(startNotch, endNotch)
    if (svgRef.current) {
      const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      path.setAttribute('stroke', '#007bff');
      path.setAttribute('fill', 'none');
      path.setAttribute('stroke-width', '3');

      const newConnection = { startNotch, endNotch, path };
      setConnectionsNode((prevConnections) => [...prevConnections, newConnection]);
      svgRef.current.appendChild(path);
      updateConnections();

      path.addEventListener('click', () => {
        if (selectedPathNode) selectedPathNode.classList.remove('selected');
        setSelectedPathNode(path);
        path.classList.add('selected');
      });
    }
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging && dragElement) {
        dragElement.style.left = `${e.clientX - (dragElement as HTMLElement).offsetLeft}px`;
        dragElement.style.top = `${e.clientY - (dragElement as HTMLElement).offsetTop}px`;
        updateConnections();
      }
    };

    const handleMouseUp = (e: MouseEvent) => {
      // let data = JSON.parse(e.toString());
      // console.log(typeof e, e.relatedTarget)
      if (isDragging) {
        setIsDragging(false);
        setDragElement(null);
        updateConnections();
      }

      if (isDrawing) {
        setIsDrawing(false);

        const endNotch = document.elementFromPoint(e.clientX, e.clientY) as HTMLElement;
        if (endNotch && endNotch.classList.contains('notch') && endNotch !== startNotch) {
          createConnection(startNotch as HTMLElement, endNotch);
        }
      }
    };

    const handleMouseDownHandle = (e: React.MouseEvent<HTMLElement>, handle: HTMLElement) => {
      e.stopPropagation();
      setIsDragging(true);
      const parentElement = handle.parentElement as HTMLElement;
      setDragElement(parentElement);
    };

    const handleMouseDownNotch = (e: React.MouseEvent<HTMLElement>, notch: HTMLElement) => {
      e.stopPropagation();
      setIsDrawing(true);
      setStartNotch(notch);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragElement, isDrawing, startNotch, connectionsNode, selectedPathNode]);


  const handleMouseDownNotch = (e: React.MouseEvent, id: string, type: 'from' | 'to') => {
    console.log(e)
    setselectedStartNode(id);
    // setConnections([...connections, {from: "76772", to : '76791'}])
    // console.log(connections)
    e.preventDefault();
    // e.stopPropagation();
    setIsDrawing(true);
  };

  const handleConnectingNode = async (e: React.MouseEvent, id: string, type: 'from' | 'to') => {
    setselectedEndNode(id);
    console.log(id)
    setConnections([...connections, { from: selectedStartNode, to: id }])
    // const projectSourceIDVlue = useSelector((state: RootState) => state.counter.dropdown);
    let linkNodeBody = {
      from: selectedStartNode,
      to: id,
      type: 'Section',
      isConnectorDelete: false
    }

    let body = {
      "ConnectorName": `connector ${selectedStartNode}`,
      "ConnectorFromNode": selectedStartNode,
      "ConnectorToNode": id,
      "mediaFiles": [
        {
          "MediaFileName": "JSON Data",
          "MediaFileDesc": "JSON Data",
          "ContentTypeID": 5,
          "MediaFilePath": "",
          "MediaFileSize": "",
          "DollHouseModelScale": 0,
          "MediaFileStatus": 1,
          "DisplayOrder": 1,
          "MediaFilePropertyBag": "",
          "TextContent": "{\"ATID\":11,\"ATInsName\":\"Add JSON Data\",\"JSONData\":\"{}\"}"
        }
      ],
      "validationFlag": true
    }
    let linknodeResponse = await LinkChoiceNodes(7401, linkNodeBody)
    console.log(linknodeResponse)
    let respose = await addConnectionDetails(7401, body)
    console.log(respose)
    console.log(id)
  }


  useEffect(() => {
    const handleScroll = () => {
      updateConnections(); // Trigger the function when scrolling

    };

    const canvasElement = canvasRef.current;

    if (canvasElement) {
      canvasElement.addEventListener('scroll', handleScroll); // Attach listener
    }

    return () => {
      if (canvasElement) {
        canvasElement.removeEventListener('scroll', handleScroll); // Clean up listener
      }
    };
  }, []);
  const handleMouseUpNotch = (e: React.MouseEvent, id: string, type: 'to' | 'from') => {
    e.preventDefault();
    if (type === 'to' && currentConnection.from) {
      setConnections([...connections, { from: currentConnection.from, to: id }]);
      setCurrentConnection({ from: null, to: null });
    }
  };


  function handleMouseDownHandle(e: React.MouseEvent<HTMLDivElement, MouseEvent>, currentTarget: EventTarget & HTMLDivElement): void {
    throw new Error('Function not implemented.');
  }

  const calculatePath = (
    fromNode: Node,
    toNode: Node,
    scrollOffset: { x: number; y: number }
  ) => {
    const fromX = fromNode.position.x + fromNode.size.width / 2 - scrollOffset.x;
    const fromY = fromNode.position.y + fromNode.size.height + 27 - scrollOffset.y;
    const toX = toNode.position.x + toNode.size.width / 2 - scrollOffset.x;
    const toY = toNode.position.y + 6 - scrollOffset.y;

    const controlY1 = fromY + 100;
    const controlY2 = toY - 100;

    return `M${fromX},${fromY} C${fromX},${controlY1} ${toX},${controlY2} ${toX},${toY}`;
  };

  const calculateConnectorPath = (
    fromNode: Node,
    toNode: Node,
    scrollOffset: { x: number; y: number }
  ): string => {
    const fromX = fromNode.position.x + fromNode.size.width / 2 - scrollOffset.x;
    const fromY = fromNode.position.y + fromNode.size.height - scrollOffset.y;
    const toX = toNode.position.x + toNode.size.width / 2 - scrollOffset.x;
    const toY = toNode.position.y - scrollOffset.y;

    const controlPointOffset = 100; // Customize curve depth
    return `M${fromX},${fromY} C${fromX},${fromY + controlPointOffset} ${toX},${toY - controlPointOffset} ${toX},${toY}`;
  };













  const handleMouseUp = (e: MouseEvent) => {
    const scrollOffset = {
      x: canvasRef.current?.scrollLeft || 0,
      y: canvasRef.current?.scrollTop || 0,
    };
    // console.log(scrollOffset)
  };

  document.addEventListener("mouseup", handleMouseUp);

  useEffect(() => {
    const updateConnections = () => {
      const scrollOffset = {
        x: canvasRef.current?.scrollLeft || 0,
        y: canvasRef.current?.scrollTop || 0,
      };

      setConnections((prevConnections) =>
        prevConnections.map((conn) => {
          const fromNode = nodes.find((node) => node.id === conn.from);
          const toNode = nodes.find((node) => node.id === conn.to);

          if (!fromNode || !toNode) return conn;

          return {
            ...conn,
            path: calculatePath(fromNode, toNode, scrollOffset),
          };
        })
      );
    };

    canvasRef.current?.addEventListener("scroll", updateConnections);
    return () => {
      canvasRef.current?.removeEventListener("scroll", updateConnections);
    };
  }, [nodes]);



  return (
    <div
      className="relative bg-gray-100 shadow border-r mt-[5.5vh] "
      style={{ width: size.width, height: '84vh', top: 0, bottom: 0, maxWidth: '36vw' }}

    >
      <div className="relative">
        {/* Fixed Legend */}
        <div
          className="absolute top-10 left-0 p-4 flex flex-col bg-transparent z-10"
          style={{
            width: "600px"
          }}
        >
          <h3 className="text-lg font-bold mb-2 text-white">Node Types</h3>
          <ul className="list-none p-0 text-black" style={{ display: 'inline-flex' }}>
            <li className="flex items-center mb-2 " style={{ padding: '0px 20px 0px 20px' }}>
              <div
                className="w-3 h-3 mr-1"
                style={{ backgroundColor: "#66A182" , borderRadius: "50%" }}
              ></div>
              <span>Start Node</span>
            </li>
            <li className="flex items-center mb-2" style={{ padding: '0px 20px 0px 20px' }}>
              <div
                className="w-3 h-3 mr-1"
                style={{ backgroundColor: "#66a3c8" , borderRadius: "50%" }}
              ></div>
              <span>Step Node</span>
            </li>
            <li className="flex items-center mb-2" style={{ padding: '0px 20px 0px 20px' }}>
              <div
                className="w-3 h-3 mr-1"
                style={{ backgroundColor: "#d39eca" , borderRadius: "50%" }}
              ></div>
              <span>Choice Node</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="w-full px-6 pt-5 pb-4 left-0 top-0 absolute bg-stone-50 shadow border-b justify-start items-end inline-flex">
        <div className="w-full h-full text-2xl pt-[2vh] ">Node Canvas </div>
        <div className="w-full h-full mt-2 gap-4 justify-end items-end flex">
          {searchInputVisible ? (
            <input
              type="text"
              className="border rounded px-2 focus:outline-none"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onBlur={() => setSearchInputVisible(false)}
              autoFocus
            />
          ) : (
            <button
              onClick={() => setSearchInputVisible(true)}
              className="flex  items-center gap-2"
            >
              <img src={search} alt="" />
            </button>
          )}
          <button
            onClick={toggleFullScreen}
            className="flex items-center gap-2"
          >
            {isFullScreen ? <img src={min} alt="" /> : <img src={max} alt="" />}
          </button>
        </div>
        <div className="resize-handle" onMouseDown={handleResize}></div>
      </div>
      <div
        className="w-full px-6 pt-8 pb-4 bottom-0 left-0 right-0 z-10 absolute bg-transparent justify-start items-end inline-flex resize-handle"
        onMouseDown={handleResize}
      >
        <div className="w-full h-full ml-[37vh] mb-[-9vh] rounded-full right-0 bottom-0   gap-4 justify-end items-end flex">
          <button
            className="bg-blue-500 text-white rounded-full  w-44 h-25 flex items-center justify-center"
            onClick={() => setDropdownVisible(!dropdownVisible)}
          >
            <svg
              className="w-6 h-6 top-[20vh]"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              ></path>
            </svg>
          </button>
          {dropdownVisible && (
            <div className="absolute w-[20vh] bottom-0 right-[-10] bg-white border rounded shadow-md p-2">
              <button
                className="block w-full px-4 py-2 text-gray-700 hover:bg-gray-100"
                onClick={() => addComponent("step node")}
              >
                Step Node
              </button>
              <button
                className="block w-full px-4 py-2 text-gray-700 hover:bg-gray-100"
                onClick={() => addComponent("choice node")}
              >
                Choice Node
              </button>
              <button
                className="block w-full px-4 py-2 text-gray-700 hover:bg-gray-100"
                onClick={() => addComponent("Link node")}
              >
                Link Node
              </button>
            </div>
          )}
          <div className="fixed bottom-4 z-10 left-4 w-[260px] h-11 flex-col justify-start items-start inline-flex">
            <div className="justify-start items-center gap-1 inline-flex">
              <div className="text-gray-500 text-sm font-normal leading-tight">
                0%
              </div>
              <div className="w-[184px] flex-col justify-center items-center gap-2.5 inline-flex relative">
                <input
                  type="range"
                  min="0.5"
                  max="2"
                  step="0.1"
                  value={zoom}
                  onChange={handleZoomChange}
                  className="w-full h-1 bg-blue-500 appearance-none"
                />
                <div className="w-full h-1 bg-gray-300 rounded-xl absolute top-1/2 transform -translate-y-1/2">
                  <div
                    className="bg-blue-500 h-full rounded-xl"
                    style={{ width: `${((zoom - 0.5) / 1.5) * 100}%` }}
                  />
                </div>
              </div>
              <div className="text-gray-500 text-sm font-normal leading-tight">
                100%
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute left-0 top-[88px] w-full h-full  overflow-hidden " >
        <div
          ref={canvasRef}
          className="bg-dotted-canvas shadow zoomable-content overflow-auto w-full h-full "
          style={{ transform: `scale(${zoom})`, transformOrigin: "top left" }}
        >
          {/* <CanvasNewComponent /> */}
          {nodes.map((node, index) => {
            const isSource = currentConnection.from === node.id;
            const isSelected = selectedNode === node.id;
            const isConnected = connections.some(
              (conn) =>
                (conn.from === node.id || conn.to === node.id) &&
                currentConnection.from !== node.id
            );

            const isHighlighted = searchText && node.id.includes(searchText);

            return (
              <div>
                {node.type === 'Start' ? (
                  <div
                    data-key={node.id}
                    key={node.id} // Ensure node.id is unique
                    className={`absolute rounded-lg shadow cursor-pointer ${isSource ? "border border-blue-500" : ""
                      } ${isSelected ? "bg-blue-100 " : "bg-white"} ${isHighlighted ? "border border-red-500" : ""
                      }`}
                    style={{
                      left: node.position.x,
                      top: node.position.y,
                      width: "204px",
                      height: "122px",

                      boxShadow: "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
                      border: "solid 1px #66A182"
                    }}
                    ref={(el) => (nodeRefs.current[index] = el)}

                    onContextMenu={(e) => handleComponentRightClick(e, node.id)}
                    onClick={(e) => handleOnSelectNode(e, node.id)}
                  >
                    <div className="px-3 py-1.5 w-full bg-stone-900 rounded-t-lg justify-between items-center inline-flex" style={{ backgroundColor: '#66A182' }}>
                      <div className="text-white text-sm font-normal leading-tight">
                        {node.type}
                      </div>
                      <div className="text-white text-sm font-normal leading-tight">
                        {node.id}
                      </div>
                    </div>
                    <div className="h-[90px] px-3 rounded-b-lg py-2 bg-white flex-col justify-center items-center gap-1 flex">
                      <div className="text-center text-gray-500 text-sm font-normal leading-tight">
                        Node
                      </div>
                    </div>
                    <div className="notch bottom" onMouseDown={(e) => handleMouseDownNotch(e, node.id, 'to')}></div>
                    <div className="nodeLogoImage" >
                      <img src={startNodeImage} ></img>
                    </div>

                    {/* <div onMouseDown={(e) => handleMouseDown(e, node.id, index)} className="drag-handle" ></div> */}
                  </div>
                ) : node.type === "choice node" ? (
                  <div
                    data-key={node.id}
                    key={node.id} // Ensure node.id is unique
                    className={`absolute rounded-lg shadow cursor-pointer ${isSource ? "border border-blue-500" : ""
                      } ${isSelected ? "bg-blue-100 " : "bg-white"} ${isHighlighted ? "border border-red-500" : ""
                      }`}
                    style={{
                      left: node.position.x,
                      top: node.position.y,
                      width: "204px",
                      height: "122px",
                    }}
                    ref={(el) => (nodeRefs.current[index] = el)}

                    onContextMenu={(e) => handleComponentRightClick(e, node.id)}
                    onClick={(e) => handleOnSelectNode(e, node.id)}
                  >
                    <div className="px-3 py-1.5 w-full bg-stone-900 rounded-t-lg justify-between items-center inline-flex">
                      {node.type === "choice node" && (
                        <>
                          {/* <img src={choiceSvg} alt="choice node" /> */}
                          <RxSquare style={{ color: 'white' }} />
                        </>
                      )}
                      <div className="text-white text-sm font-normal leading-tight">
                        {node.type}
                      </div>
                      <div className="text-white text-sm font-normal leading-tight">
                        {node.id}
                      </div>
                    </div>
                    <div className="h-[90px] px-3 rounded-b-lg py-2 bg-white flex-col justify-center items-center gap-1 flex">
                      <div className="text-center text-gray-500 text-sm font-normal leading-tight">
                        Node Name
                      </div>
                    </div>
                    <div className="notch top" onMouseDown={(e) => handleMouseDownNotch(e, node.id, 'from')} onMouseUp={(e) => handleConnectingNode(e, node.id, 'to')} ></div>
                    <div className="notch bottom" onMouseDown={(e) => handleMouseDownNotch(e, node.id, 'to')}></div>

                    <div onMouseDown={(e) => handleMouseDown(e, node.id, index)} className="drag-handle"></div>
                  </div>
                ) : (
                  <div
                    data-key={node.id}
                    key={node.id} // Ensure node.id is unique
                    className={`absolute rounded-lg shadow cursor-pointer ${isSource ? "border border-blue-500" : ""
                      } ${isSelected ? "bg-blue-100 " : "bg-white"} ${isHighlighted ? "border border-red-500" : ""
                      }`}
                    style={{
                      left: node.position.x,
                      top: node.position.y,
                      width: "204px",
                      height: "122px",
                      border: "solid 1px #66a3c8"
                    }}
                    ref={(el) => (nodeRefs.current[index] = el)}

                    onContextMenu={(e) => handleComponentRightClick(e, node.id)}
                    onClick={(e) => handleOnSelectNode(e, node.id)}
                  >
                    <div className="px-3 py-1.5 w-full bg-stone-900 rounded-t-lg justify-between items-center inline-flex" style={{ backgroundColor: '#66a3c8' }}>
                      {/* <>
                        <RxSquare style={{ color: 'white' }} />

                      </> */}
                      <div className="text-white text-sm font-normal leading-tight">
                        {node.type}
                      </div>
                      <div className="text-white text-sm font-normal leading-tight">
                        {node.id}
                      </div>
                    </div>
                    <div className="h-[90px] px-3 rounded-b-lg py-2 bg-white flex-col justify-center items-center gap-1 flex">
                      <div className="text-center text-gray-500 text-sm font-normal leading-tight">
                        Node Name
                      </div>
                    </div>
                    <div className="notch top" onMouseDown={(e) => handleMouseDownNotch(e, node.id, 'from')} onMouseUp={(e) => handleConnectingNode(e, node.id, 'to')} ></div>
                    <div className="notch bottom" onMouseDown={(e) => handleMouseDownNotch(e, node.id, 'to')}>
                    </div>
                    <div className="nodeLogoImage" >
                      <img style={{ height: '30px' }} src={stepNodeImage} ></img>
                    </div>
                    <div onMouseDown={(e) => handleMouseDown(e, node.id, index)} className="drag-handle">
                      <img style={{ height: '30px' }} src={draggingIconImage}>
                      </img>
                    </div>

                  </div>
                )
                }

              </div>

            );
          })}
          <div ref={dottedSvgRef}>
            <svg className="connections"

              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "3000px",
                height: "3000px",
                pointerEvents: "none", // Prevent interaction with the SVG
              }}>
              {connections.map((conn, index) => {
                const fromNode = nodes.find((node) => node.id === conn.from);
                const toNode = nodes.find((node) => node.id === conn.to);
                if (!fromNode || !toNode) return null;

                // Calculate positions for top and bottom connectors
                const fromX = fromNode.position.x + fromNode.size.width / 1.96;
                const fromY = fromNode.position.y + fromNode.size.height + 27; // Adjusted for exact bottom alignment
                const toX = toNode.position.x + toNode.size.width / 1.96;
                const toY = toNode.position.y - 1.9; // Adjusted for exact top alignment

                // Create attractive curved path starting slightly downward
                const controlY1 = fromY + 100; // Increased for a smoother downward curve
                const controlY2 = toY - 100;  // Increased for a smoother upward curve
                const path = `M${fromX},${fromY} C${fromX},${controlY1} ${toX},${controlY2} ${toX},${toY}`;

                return (
                  <path
                    className="customPath"
                    key={index}
                    d={path}
                    stroke="blue"
                    fill="none"
                    strokeWidth="6"
                    // markerEnd="url(#arrowhead)"
                    cursor='pointer'
                  />
                );
              })}

              {/* Arrowhead marker */}
              <defs>
                <marker
                  id="arrowhead"
                  markerWidth="10"
                  markerHeight="7"
                  refX="10"
                  refY="3.5"
                  orient="auto"
                  markerUnits="strokeWidth"
                >
                  <polygon points="0 0, 10 3.5, 0 7" fill="blue" />
                </marker>
              </defs>
            </svg>
          </div>



          {/* <svg className="cursor-pointer" id="connections"></svg> */}


          {contextMenu.visible && (
            <div
              className="absolute mt-[-25vh]  flex flex-col top-0 rounded p-2"
              style={{ top: contextMenu.y, left: contextMenu.x }}
            >
              <div className="w-[124px] h-[133px] py-2 bg-white flex-col justify-start items-start inline-flex">
                <div className="self-stretch px-4 py-2 justify-start items-center gap-2.5 inline-flex">
                  <div
                    className="grow shrink basis-0 text-stone-900 text-base font-normal font-['Selawk'] leading-normal"
                    onClick={() => console.log("Clone")}
                  >
                    Clone
                  </div>
                </div>
                <div className="self-stretch px-4 py-2  justify-start items-center gap-2.5 inline-flex">
                  <div
                    className="grow shrink basis-0 text-stone-900 text-base font-normal font-['Selawk'] leading-normal"
                    onClick={() => console.log("Disable")}
                  >
                    Disable
                  </div>
                </div>
                <div
                  className="self-stretch px-4 py-2  justify-start items-center gap-2.5 inline-flex"
                  onClick={clearSelected}
                >
                  <div className="grow shrink basis-0 text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                    Delete
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="resize-handle"
        onMouseDown={handleResize}
        style={{
          position: "absolute",
          width: "10px",
          height: "100%",
          right: 0,
          top: 0,
          cursor: "ew-resize",
          backgroundColor: "rgba(0,0,0,0.1)",
        }}
      />
    </div>
  );
};

export default Canvas;
