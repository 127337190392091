// TextPanel.tsx
import { PositionalAudio } from '@react-three/drei';
import React, { useEffect } from 'react';
import { Vector3, AudioLoader } from 'three';
import { BigPlayButton, Player } from 'video-react';
import { NodeRenderDetails } from '../../../../types/nodeDataTypes';
import AudioPlayer from '../Audio/AudioPlayer';
import DraggableHtml from './DraggableHtml';
import './TextPanel.css';

interface TextPanelProps {
  nodeRenderDetails: NodeRenderDetails | undefined;
  onDragPanel: (position: Vector3) => void;
}

const TextPanel: React.FC<TextPanelProps> = ({ nodeRenderDetails, onDragPanel }) => {

  useEffect(() => {
    console.log(nodeRenderDetails)
  },[nodeRenderDetails])

  return (
    <>
      <DraggableHtml onDragPanel={onDragPanel}>
        <div className="panel-container">
          {(nodeRenderDetails?.image || nodeRenderDetails?.video) ? (
            nodeRenderDetails?.image ? (
              <div className="image-panel">
                <img src={nodeRenderDetails?.image} alt="" />
              </div>
            ) : (
              <div className="image-panel">
                <Player playsInline src={nodeRenderDetails?.video}>
                  <BigPlayButton position="center" />
                </Player>
              </div>
            )
          ) : ""}
          <div className="text-panel">
            <div className="header">
              {!nodeRenderDetails?.isFirstStep && !nodeRenderDetails?.isStartNode &&
                <button className="arrow-button button button-left" onClick={nodeRenderDetails?.onBack}>←</button>
              }
              <div className="step-title">{nodeRenderDetails?.title}</div>
              {!nodeRenderDetails?.isLastStep && !nodeRenderDetails?.isStartNode && !nodeRenderDetails?.isCompleted &&
                <button className="arrow-button button button-right" onClick={nodeRenderDetails?.onNext}>→</button>
              }
            </div>
            <div className="content">
              <p>{nodeRenderDetails?.text}</p>
            </div>
            <div className="options-panel">
              <div className="button-container">
                {nodeRenderDetails?.isStartNode &&
                  <button
                    key={"startTrainingButton"}
                    onClick={nodeRenderDetails?.onNext}
                  >Start Training</button>
                }

                {nodeRenderDetails?.isCompleted &&
                  <>
                    <button
                      key={"Restart_Training"}
                      onClick={nodeRenderDetails?.onRetry}
                    >Restart Training</button>

                    <button
                      key={"Go_To_Home"}
                      onClick={nodeRenderDetails?.onGoToHome}
                    >Go To Home</button>
                  </>
                }

                {nodeRenderDetails?.options?.map((option, optionIndex) => (
                  <button
                    key={"optionbutton_" + optionIndex}
                    onClick={() => { nodeRenderDetails?.onAnswerSelect(option.SectionObjectLinkID) }}
                  >
                    {option.TextContent}
                  </button>
                ))}
              </div>
            </div>
            <div className="NodeID">
              <a>{nodeRenderDetails?.sectionObjectName}</a>
            </div>
          </div>
        </div>
      </DraggableHtml>


      <AudioPlayer url={nodeRenderDetails?.audio || ""} />

    </>
  );
};

export default TextPanel;