import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { HashLoader } from 'react-spinners';
import { SceneNodeEdit3D } from '../../3DViewer/Scene/SceneNodeEdit3D';
import { getExperienceData } from '../../../services/experienceService';
import { getSpaceBuilderData } from '../../../services/projectService';
import { DisplayTemplate, Experience, ExperienceResponseData, findExperienceByExperienceID, findMediaFileByDisplayTemplateID, findMediaFileByDisplayTemplateIDFor3D, getAudioTextTextData, getMediaFilePath, getRolePlayStepByStepTextData, getStartNodeForExperience, RolePlaySectionObject } from '../../../types/experienceTypes';
import { AssetData, SpaceJsonResponse, getAssetDataBySectionObjectID } from '../../../types/spaceTypes';
import { defalutNodeRenderDetails, NodeRenderDetails } from '../../../types/nodeDataTypes';

const GLBLink = '/models/3DModels/20250120070538828porsche_924_carrera_gtr.glb';





interface Props {
    experienceID?: Number | string | null;
    projectID?: Number | string | null;
    projectSectionID?: Number | string | null;
    spaceID?: Number | string | null;
    roleplay?: {}
    currentNodeDetails?: RolePlaySectionObject
}

export const Edit3dNodes: React.FC<Props> = ({
    experienceID: propExperienceID,
    projectID: propProjectID,
    projectSectionID: propProjectSectionID,
    spaceID: propSpaceID,
    roleplay: roleplay,
    currentNodeDetails: currentNodeDetails
}) => {
    const [nodeRenderDetails, setNodeRenderDetails] = useState<NodeRenderDetails>(defalutNodeRenderDetails);


    const projectSourceIDVlue = useSelector((state: RootState) => state.counter.dropdown);

    // console.log(projectSourceIDVlue.id, projectSourceIDVlue.option, projectSourceIDVlue.projectID, projectSourceIDVlue.projectSectionId)

    const { experienceID: paramExperienceID, projectID: paramProjectID, projectSectionID: paramProjectSectionID, spaceID: paramSpaceID } = useParams();

    // Use props if provided, fallback to URL params


    const experienceIDNumber = Number(propExperienceID || paramExperienceID) || -1;
    const projectIDNumber = Number(propProjectID || paramProjectID) || -1;
    const projectSectionIDNumber = Number(propProjectSectionID || paramProjectSectionID) || -1;
    const spaceIDNumber = Number(propSpaceID || paramSpaceID) || -1;


    //   console.log('', experienceIDNumber, projectIDNumber, projectSectionIDNumber, spaceIDNumber)


    const [loading, setLoading] = useState(true);
    const [Experiences, setExperiences] = useState<Experience[]>([]);
    const [mainExperience, setMainExperience] = useState<Experience | undefined>(undefined);
    const [currentNode, setCurrentNode] = useState<RolePlaySectionObject | undefined>(undefined);
    const [spaceobjects, setspaceobjects] = useState<AssetData | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);


    useEffect(() => {
        let isMounted = true; // Prevent state updates if component is unmounted

        const fetchExperiences = async () => {
            setLoading(true);
            try {
                if (experienceIDNumber != -1 && experienceIDNumber) {
                    console.log('Cleared')
                    const response = await getExperienceData(experienceIDNumber);

                    if (isMounted && response.code === 200) {
                        const responseData: ExperienceResponseData = response.data;
                        const mainTsid = responseData.responseData.MAIN_TSID;
                        const mainExperienceData = findExperienceByExperienceID(responseData.responseData.Experiences, Number(mainTsid));

                        setExperiences(responseData.responseData.Experiences);
                        setMainExperience(mainExperienceData);

                        if (mainExperienceData) {
                            const currentNode = getStartNodeForExperience(mainExperienceData);
                            setCurrentNode(currentNode);
                        }
                    } else {
                        console.error('Failed to fetch experiences:', response?.message);
                        setErrorMessage('Failed to fetch experiences.');
                    }
                } else {
                    console.warn('Error occured')
                }

            } catch (error) {
                console.error('Error fetching experiences:', error);
                setErrorMessage('An error occurred while fetching experiences.');
            } finally {
                if (isMounted) setLoading(false);
            }
        };

        fetchExperiences();
        console.log(roleplay)
        return () => {
            isMounted = false; // Cleanup
        };
    }, [experienceIDNumber]);

    const fetchSpaceObjectDetails = async () => {
        console.log('Try')
        try {
            const spaceBuilderDataResponse: SpaceJsonResponse = await getSpaceBuilderData(projectIDNumber, projectSectionIDNumber);
            const spaceBuilderData = await getAssetDataBySectionObjectID(spaceBuilderDataResponse, spaceIDNumber);
            setspaceobjects(spaceBuilderData);
        } catch (error) {
            console.error('Error fetching space object details:', error);
            setErrorMessage('Failed to load space object details.');
        }
    };

    const settingNodeDetails = async () => {
        console.log(currentNodeDetails)
        if (currentNodeDetails) {
            if (roleplay != undefined) {
                setCurrentNode(currentNode)
            }
            let nodeDataToSet: NodeRenderDetails = Object.assign({}, defalutNodeRenderDetails);;

            let rolePlayStepByStepMediaFile = findMediaFileByDisplayTemplateID(currentNodeDetails.mediaFiles, DisplayTemplate.RolePlayStepByStep);
            if (rolePlayStepByStepMediaFile) {
                let rolePlayStepByStepTextData = getRolePlayStepByStepTextData(rolePlayStepByStepMediaFile);
                nodeDataToSet.title = rolePlayStepByStepTextData.Title;
            }

            let audioTextMediaFile = findMediaFileByDisplayTemplateID(currentNodeDetails.mediaFiles, DisplayTemplate.AudioText);

            if (audioTextMediaFile) {
                let audioTextTextData = getAudioTextTextData(audioTextMediaFile);
                nodeDataToSet.text = audioTextTextData.DisplayText;

                if (audioTextMediaFile.MediaFilePath && audioTextMediaFile.MediaFilePath != "")
                    nodeDataToSet.audio = "https://altouraonyx.azureedge.net" + audioTextMediaFile.MediaFilePath;

            }
            let rolePlayVideoMediaFile = findMediaFileByDisplayTemplateID(currentNodeDetails.mediaFiles, DisplayTemplate.RolePlayVideo);
            if (rolePlayVideoMediaFile) {
                let MediaFilePath = getMediaFilePath(rolePlayVideoMediaFile);
                if (MediaFilePath)
                    nodeDataToSet.video = "https://altouraonyx.azureedge.net" + MediaFilePath;
                else
                    nodeDataToSet.video = MediaFilePath;
            }
            let rolePlayDisplayImageMediaFile = findMediaFileByDisplayTemplateID(currentNodeDetails.mediaFiles, DisplayTemplate.RolePlayDisplayImage);
            if (nodeDataToSet.video == "" && rolePlayDisplayImageMediaFile) {
                let MediaFilePath = getMediaFilePath(rolePlayDisplayImageMediaFile);
                if (MediaFilePath)
                    nodeDataToSet.image = "https://altouraonyx.azureedge.net" + MediaFilePath;
                else
                    nodeDataToSet.image = MediaFilePath;
            }
            let rolePlay3DMediaFile = findMediaFileByDisplayTemplateIDFor3D(currentNodeDetails.mediaFiles, DisplayTemplate.SBYS3DModel);
            console.log(rolePlay3DMediaFile)
            if (rolePlay3DMediaFile) {
                for (let data in rolePlay3DMediaFile) {
                    let MediaFilePath = getMediaFilePath(rolePlay3DMediaFile[data]);
                    if (nodeDataToSet.threeDfile && nodeDataToSet) {
                        let GLBUrl = `https://altouraonyx.azureedge.net${MediaFilePath}`
                        if (MediaFilePath && !nodeDataToSet.threeDfile.includes(GLBUrl)) {
                            nodeDataToSet.threeDfile.push(`https://altouraonyx.azureedge.net${MediaFilePath}`);
                        } else if (!nodeDataToSet.threeDfile.includes(GLBUrl)) {
                            nodeDataToSet.threeDfile.push(MediaFilePath);
                        }
                    }

                }
            }
            // if(nodeDataToSet.threeDfile != undefined && !nodeDataToSet.threeDfile.includes(GLBLink)){
            //     nodeDataToSet.threeDfile.push(GLBLink)
            // }
            nodeDataToSet.sectionObjectName = currentNodeDetails.SectionObjectName
            console.log(nodeDataToSet)
            setNodeRenderDetails(nodeDataToSet);
            console.log("nodeRenderDetails", nodeRenderDetails)
        }
    }

    useEffect(() => {
        setCurrentNode(currentNodeDetails)
        settingNodeDetails()
    }, [])

    useEffect(() => {
        console.log(currentNodeDetails)
        setCurrentNode(currentNodeDetails)
        settingNodeDetails()
        fetchSpaceObjectDetails();
    }, [projectIDNumber, projectSectionIDNumber, spaceIDNumber]);

    if (errorMessage) {
        return <div className="error-message">{errorMessage}</div>;
    }

    if (loading) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-r from-white">
                <HashLoader color="#4295e7" loading={true} />
            </div>
        );
    }
    return (
        <div>
            <div className="row">
                <div className="col">
                    <SceneNodeEdit3D spaceobjects={spaceobjects} roleplay={roleplay} nodeRenderDetails={nodeRenderDetails} />
                </div>
            </div>
        </div>
    );
};
