import axios, { AxiosInstance } from "axios";

// Extend the AxiosInstance type to include setBaseURL
interface CustomAxiosInstance extends AxiosInstance {
  setBaseURL: (baseURL: string) => void;
}

// Create the Axios instance
const apiClient = axios.create({
  baseURL: "https://altourafastapi.azurewebsites.net",
  // baseURL : 'http://localhost:8080',
  // // Default base URL
  withCredentials: false, // Needed to include cookies
}) as CustomAxiosInstance;

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Retrieve token from local storage
    const token = localStorage.getItem("token");

    // If token is found, add it to the request's Authorization header
    if (token) {
      config.headers["x-access-token"] = token;
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

/**
 * Function to set the base URL dynamically.
 * @param {string} baseURL - The base URL to be used for API requests.
 */
apiClient.setBaseURL = (baseURL: string) => {
  apiClient.defaults.baseURL = baseURL;
};

export default apiClient;


export const getSketchFab = async (data: any) => {
  try {
    const response = await apiClient.get(
      `/search?query=${data}&type=models`
    );
    // You can handle the response further, e.g., storing the user token
    console.log("hello", response);

    return response.data;
  } catch (error) {
    console.log(
      "Error",
      error
    );
    throw error;
  }
};
export const getSketchFabDownloadLink = async (data: any) => {
  try {
    const response = await apiClient.get(
      `/downloadSketchFabObject?model=${data}&type=models&downloadable=1&licenses=5b54cf13b1a4422ca439696eb152070d&licenses=72eb2b1960364637901eacce1928362`
    );
    // You can handle the response further, e.g., storing the user token
    console.log("hello", response);

    return response.data;
  } catch (error) {
    console.log(
      "Error",
      error
    );
    return error;
  }
}; 