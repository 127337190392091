import React, { useState, useEffect } from 'react';
import { HashLoader } from 'react-spinners';
import altourablue from "../../../logos/altourablue.svg";
import { useSelect } from '@react-three/drei';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
// import './ProjectList.css'; // Assuming your styles are in this file

export const Loader = () => {
  const [loading, setLoading] = useState(true);
  const loadState = useSelector((state: RootState) => state.counter.loadingState);

  useEffect(() => {
    // Simulating a loading process (e.g., fetching data)
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds (or when your data is ready)
    }, 2000); // Adjust this delay to match your actual loading time

    return () => clearTimeout(timer); // Cleanup the timeout when the component unmounts
  }, []);

  if (loadState) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-r from-white overflow-hidden">
        <div className="flex flex-col items-center">
          <HashLoader color="#4295e7" loading={true} />
          <div className="text-2xl mt-4 text-gray-800 font-bold">
            <img src={altourablue} alt="Logo" />
          </div>
        </div>
      </div>
    );
  }

  return null; // Optionally return null if not loading
};

export default Loader;
