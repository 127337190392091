import { faL } from "@fortawesome/free-solid-svg-icons";
import { AnnotationDetails, RolePlaySectionObject } from "./experienceTypes";

export interface NodeRenderDetails {
    title: string;
    text: string;
    image?: string;
    audio?: string;
    video?: string;
    threeDfile?: any[];
    PageNo: number;
    options?: AnnotationDetails[];
    sectionObjectName:string;
    isStartNode:boolean;
    isFirstStep:boolean;
    isLastStep:boolean;
    isCompleted:boolean;
    onNext:()=>void;
    onBack:()=>void;
    onRetry:()=>void;
    onGoToHome:()=>void;
    onAnswerSelect:(number:number)=>void;
  }
  
  export interface Step {
    title: string;
    text: string;
    image?: string;
    PageNo: number;
    options?: string;
    CorrectAnswer?: string;
  }
  
  
  export interface Quiz {
    question: string;
    options: string[];
  }
  
  export interface ContentState {
    text: string;
    images: { imgSrc: string; altText: string }[];
    quizzes: Quiz[];
  }
  
  export interface pathFragment {
    node: RolePlaySectionObject;
    ExperienceID: number;
  }


  export const defalutNodeRenderDetails = { title: "", text: "", image: "", audio: "https://altouraonyx.azureedge.net/altouradevcontainer/blank.mp3", video: "",threeDfile:[], PageNo: 0, options: [],sectionObjectName:"",onNext:() => {},onBack:() => {},onAnswerSelect:(number:number)=>{},onRetry:() => {},onGoToHome:() => {},isStartNode:false,isFirstStep:false,isLastStep:false ,isCompleted:false};
