import axios, { AxiosInstance } from "axios";

// Extend the AxiosInstance type to include setBaseURL
interface CustomAxiosInstance extends AxiosInstance {
  setBaseURL: (baseURL: string) => void;
}

// Create the Axios instance
const apiClient = axios.create({
  baseURL: "https://portaldev.altoura.com/", // Default base URL
  withCredentials: false, // Needed to include cookies
}) as CustomAxiosInstance;

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Retrieve token from local storage
    const token = localStorage.getItem("token");

    // If token is found, add it to the request's Authorization header
    if (token) {
      config.headers["x-access-token"] = token;
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

/**
 * Function to set the base URL dynamically.
 * @param {string} baseURL - The base URL to be used for API requests.
 */
apiClient.setBaseURL = (baseURL: string) => {
  apiClient.defaults.baseURL = baseURL;
};

export default apiClient;
