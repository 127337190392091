import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setSnackBarStatus } from "../../../redux/slices/counterSlice"; // Assuming an action to reset the SnackBar status
import './snackBar.css'; // Import the CSS file

export const SnackBar: React.FC = () => {
  const dispatch = useDispatch();

  const [errormessage, setErrorMessage] = useState('');
  
  // Accessing the SnackBar status and message from Redux state
  const status = useSelector((state: RootState) => state.counter.SnackBarStatus);
  const message = useSelector((state: RootState) => state.counter.SnackBarMessage);
  
  // Using useEffect to trigger snackBar hiding after a certain timeout
  useEffect(() => {
    if (status) {
      const timer = setTimeout(() => {
        dispatch(setSnackBarStatus(false)); // Reset the SnackBarStatus after 3 seconds (or your custom duration)
      }, 3000); // Duration to show the snackBar (3 seconds here)
      
      return () => clearTimeout(timer); // Cleanup the timer on unmount or status change
    }
  }, [status, dispatch]);

  // Return null if the SnackBar is not active
  if (!status) return null;

  return (
    <div className={`snackbar ${status ? 'show' : 'hide'}`}>
      {message || errormessage}
      <button onClick={() => dispatch(setSnackBarStatus(false))}>×</button>
    </div>
  );
};
