import React, { useRef, useState, ReactNode, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { DragControls, Html } from '@react-three/drei';
import * as THREE from 'three';
import DraggableSphere from '../Spline/DraggableSphere';
import { Group, Mesh, Vector3 } from 'three';
import { useSceneControl } from '../../Providers/SceneContextProvider';

interface DraggableHtmlProps {
  children: ReactNode;
  onDragPanel:(position:Vector3)=>void;
}

const DraggableHtml: React.FC<DraggableHtmlProps> = ({ children,onDragPanel }) => {
const meshRef=useRef<Mesh>(null);
const { isInteracting } = useSceneControl();

useEffect(()=>{
  const worldPosition = new THREE.Vector3();
  meshRef.current?.getWorldPosition(worldPosition);
  onDragPanel(worldPosition);
})

  return (

  <DragControls   onDrag={()=>{const worldPosition = new THREE.Vector3();
            meshRef.current?.getWorldPosition(worldPosition);
            onDragPanel(worldPosition);
            }} onDragStart={()=>{ isInteracting.current =true}} onDragEnd={()=>{ isInteracting.current =false}}>
    <mesh ref={meshRef}  position={[0, 1.6, 0]}  >
      <sphereGeometry  args={[.04,60,60]}></sphereGeometry>
      <Html
        position={[0, -0.5, 0]}
        transform
        distanceFactor={1}
      >
          {children}
      </Html>
      </mesh>
      </DragControls>
   
  );
};

export default DraggableHtml;