import React, { useState, useEffect, useRef } from 'react';

interface Connection {
    startNotch: HTMLElement;
    endNotch: HTMLElement;
    d: string;
}

interface Props {
    Nodedata?: any | [] | null;
}

export const SVGConnector: React.FC<Props> = ({
    Nodedata
}) => {
    const [isDrawing, setIsDrawing] = useState<boolean>(false);
    const [connections, setConnections] = useState<Connection[]>([]);
    const [dragElement, setDragElement] = useState<HTMLElement | null>(null);
    const [startNotch, setStartNotch] = useState<HTMLElement | null>(null);
    const [selectedPath, setSelectedPath] = useState<Connection | null>(null);
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    const svgRef = useRef<SVGSVGElement>(null);

    const getConnectorPath = (startX: number, startY: number, endX: number, endY: number): string => {
        const deltaX = Math.abs(endX - startX) / 2;
        return `M${startX},${startY} C${startX + deltaX},${startY} ${endX - deltaX},${endY} ${endX},${endY}`;
    };

    const createConnection = (startNotch: HTMLElement, endNotch: HTMLElement) => {
        const path = {
            startNotch,
            endNotch,
            d: getConnectorPath(...getCoordinates(startNotch, endNotch)),
        };
        setConnections((prev) => [...prev, path]);
    };

    const getCoordinates = (startNotch: HTMLElement, endNotch: HTMLElement): [number, number, number, number] => {
        const startRect = startNotch.getBoundingClientRect();
        const endRect = endNotch.getBoundingClientRect();

        const startX = startRect.left + startRect.width / 2;
        const startY = startRect.top + startRect.height / 2;
        const endX = endRect.left + endRect.width / 2;
        const endY = endRect.top + endRect.height / 2;

        return [startX, startY, endX, endY];
    };

    const handleMouseDown = (e: React.MouseEvent, notch: HTMLElement) => {
        e.stopPropagation();
        setIsDrawing(true);
        setStartNotch(notch);
    };

    const handleDragStart = (e: React.MouseEvent, element: HTMLElement) => {
        e.preventDefault();
        setDragElement(element);
        setOffset({
            x: e.clientX - element.getBoundingClientRect().left,
            y: e.clientY - element.getBoundingClientRect().top,
        });
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (dragElement) {
            dragElement.style.left = `${e.clientX - offset.x}px`;
            dragElement.style.top = `${e.clientY - offset.y}px`;
            updateConnections();
        }
    };

    const handleMouseUp = (e: MouseEvent) => {
        if (isDrawing) {
            setIsDrawing(false);
            const endNotch = document.elementFromPoint(e.clientX, e.clientY) as HTMLElement;
            if (endNotch && endNotch.classList.contains('notch') && endNotch !== startNotch) {
                createConnection(startNotch!, endNotch);
            }
        }
        setDragElement(null);
    };

    const updateConnections = () => {
        setConnections((prev) =>
            prev.map((conn) => ({
                ...conn,
                d: getConnectorPath(...getCoordinates(conn.startNotch, conn.endNotch)),
            }))
        );
    };


    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragElement, isDrawing]);

    return (
        <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
            <div
                id="box1"
                className="box"
                style={{ top: '100px', left: '100px', position: 'absolute' }}
                onMouseDown={(e) => handleDragStart(e, e.currentTarget)}
            >
                <div className="header">Start 76748</div>
                <div className="content">Node Name</div>
                <div
                    className="notch top"
                    onMouseDown={(e) => handleMouseDown(e, e.target as HTMLElement)}
                ></div>
                <div
                    className="notch bottom"
                    onMouseDown={(e) => handleMouseDown(e, e.target as HTMLElement)}
                ></div>
            </div>
            {/* <svg ref={svgRef} className="cursor-pointer" style={{ position: 'absolute', width: '100%', height: '100%' }}>
                {connections.map((conn, index) => (
                    <path
                        key={index}
                        d={conn.d}
                        stroke={selectedPath === conn ? '#ff5722' : '#007bff'}
                        strokeWidth="3"
                        fill="none"
                        onClick={() => setSelectedPath(conn)}
                    />
                ))}
            </svg> */}
        </div>
    );
};

export default SVGConnector;
